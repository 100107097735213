import styled from 'styled-components'

export const CardBox = styled.div`
  background-color: #fff;
  border-radius: 14px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem 1rem;

  .img_box {
    width: 100%;
    padding-bottom: 100%;
    position: relative;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  p {
    font-size: 14px;
    padding: 20px 0;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 16px;
  }

  button {
    border: none;
    background-color: #2f4858;
    color: #fff;
    min-width: 120px;
    padding: 8px 30px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;
  }
  @media screen and (max-width: 620px) {
    p {
      font-size: 14px;
    }

    button {
      font-size: 15px;
    }
  }
  @media screen and (max-width: 390px) {
    button {
      font-size: 13px;
    }
  }
`
