import React from 'react'
import { Container } from './styles'

const PrivacyPage = () => {
  return (
    <>
      <Container>
        <div className="container">
          <h1>개인정보 취급 방침</h1>
          <div className="top_box">
            <h2>
              수집하는 개인정보 항목 회사는 상담, 서비스, CS 신청 등을 위해 <br /> 아래와 같은 개인정보를 수집하고
              있습니다.
            </h2>
            <ul>
              <li>수집항목 : 이름 , 휴대전화번호 , 회사전화번호 , 이메일 , 회사명 , 직책</li>
              <li>개인정보 수집방법 : 홈페이지(간편상담, 상담센터, CS문의)</li>
            </ul>
          </div>
          <div className="text_box1">
            <h3>1. 개인정보의 처리 목적</h3>
            <ul>
              (주)엠에스벤터(‘https://www.benter.co.kr’이하 ‘(주)엠에스벤터’) 은(는) 다음의 목적을 위하여 개인정보를
              처리하고 있으며, 다음의 목적 이외의 용도로는 이용하지 않습니다.
              <li>
                고객 가입의사 확인, 고객에 대한 서비스 제공에 따른 본인 식별.인증, 회원자격 유지.관리, 물품 또는 서비스
                공급에 따른 금액 결제, 물품 또는 서비스의 공급.배송 등
              </li>
            </ul>
            <h3>2. 개인정보의 처리 및 보유 기간</h3>
            <ul>
              <li>
                ① (주)엠에스벤터 은(는) 정보주체로부터 개인정보를 수집할 때 동의 받은 개인정보 보유․이용기간 또는 법령에
                따른 개인정보 보유․이용기간 내에서 개인정보를 처리․보유합니다.
              </li>
              <li>
                ② 구체적인 개인정보 처리 및 보유 기간은 다음과 같습니다.
                <p>
                  ☞ 아래 예시를 참고하여 개인정보 처리업무와 개인정보 처리업무에 대한 보유기간 및 관련 법령, 근거 등을
                  기재합니다.
                </p>
                <ul>
                  예시
                  {/*<li>*/}
                  {/*  고객 가입 및 관리 : 서비스 이용계약 또는 회원가입 해지시까지, 다만 채권․채무관계 잔존시에는 해당*/}
                  {/*  채권․채무관계 정산시까지*/}
                  {/*</li>*/}
                  {/*<li>전자상거래에서의 계약․청약철회, 대금결제, 재화 등 공급기록 : 5년</li>*/}
                  <li>- 계약 또는 청약철회 등에 관한 기록</li>
                  <li>· 보존 사유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                  <li>· 보존 기간 : 5년</li>
                  <li>- 대금결제 및 재화 등의 공급에 관한 기록</li>
                  <li>· 보존 사유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                  <li>· 보존 기간 : 5년</li>
                  <li>- 전자금융 거래에 관한 기록</li>
                  <li>· 보존 사유 : 전자금융거래법</li>
                  <li>· 보존 기간 : 5년</li>
                  <li>- 소비자의 불만 또는 분쟁처리에 관한 기록</li>
                  <li>· 보존 사유 : 전자상거래 등에서의 소비자보호에 관한 법률</li>
                  <li>· 보존 기간 : 3년</li>
                  <li>- 본인확인에 관한 기록</li>
                  <li>· 보존 사유 : 정보통신망 이용촉진 및 정보보호 등에 관한 법률</li>
                  <li>· 보존 기간 : 6개월</li>
                  <li>- 홈페이지 방문기록</li>
                  <li>· 보존 사유 : 통신비밀보호법</li>
                  <li>· 보존 기간 : 3개월</li>
                </ul>
              </li>
            </ul>
            <h3>3. 개인정보의 제3자 제공에 관한 사항</h3>
            <ul>
              <li>
                ① (주)엠에스벤터 은(는) 정보주체의 동의, 법률의 특별한 규정 등 개인정보 보호법 제17조 및 제18조에
                해당하는 경우에만 개인정보를 제3자에게 제공합니다.
              </li>
              <li>
                (주)엠에스벤터('https://www.benter.co.kr')은(는) 다음과 같이 개인정보를 제3자에게 제공하고 있습니다.
                <ul>
                  <li>개인정보를 제공받는 자 : (주)엠에스벤터</li>
                  <li>
                    제공받는 자의 개인정보 이용목적 : 이메일, 휴대전화번호, 비밀번호, 로그인ID, 이름, 회사전화번호,
                    회사명, 신용카드정보, 은행계좌정보, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록
                  </li>
                  <li>제공받는 자의 보유.이용기간: 5년</li>
                </ul>
              </li>
            </ul>
            <h3>4. 개인정보처리 위탁</h3>
            <ul>
              <li>
                ① (주)엠에스벤터('(주)엠에스벤터')은(는) 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보
                처리업무를 위탁하고 있습니다.
                <ul>
                  <li>위탁받는 자 (수탁자) : 고객</li>
                  <li>
                    위탁하는 업무의 내용 : 구매 및 요금 결제, 물품배송 또는 청구서 등 발송, 본인인증(금융거래,
                    금융서비스), 회원제 서비스 이용에 따른 본인확인, 불만처리 등 민원처리
                  </li>
                  <li>위탁기간 : 5년</li>
                </ul>
              </li>
              <li>
                ② (주)엠에스벤터 은(는) 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보
                처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을
                계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
              </li>
              <li>
                ③ 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록
                하겠습니다.
              </li>
            </ul>
            <h3>
              5. 정보주체와 법정대리인의 권리·의무 및 그 행사방법 이용자는 개인정보주체로써 다음과 같은 권리를 행사할 수
              있습니다.
            </h3>
            <ul>
              ① 정보주체는 (주)엠에스벤터(‘https://www.benter.co.kr’이하 ‘(주)엠에스벤터) 에 대해 언제든지 다음 각 호의
              개인정보 보호 관련 권리를 행사할 수 있습니다
              <li>1. 개인정보 열람요구</li>
              <li>2. 오류 등이 있을 경우 정정 요구</li>
              <li>3. 삭제요구</li>
              <li>4. 처리정지 요구</li>
            </ul>
            <h3>6. 처리하는 개인정보의 항목 작성</h3>
            <ul>
              서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산 : 콘텐츠 제공, 물품배송 또는 청구지 등 발송-
              회원관리 : 연령확인, 만14세 미만 아동 개인정보 수집 시 법정 대리인 동의여부 확인, 불만처리 등 민원처리,
              고지사항 전달
              <li>필수항목 : 비밀번호, 로그인ID, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록</li>
              <li>
                선택항목 : 이메일, 휴대전화번호, 이름, 회사전화번호, 직책, 부서, 회사명, 신용카드정보, 은행계좌정보
              </li>
            </ul>
            <h3>7. 개인정보의 파기</h3>
            <ul>
              (주)엠에스벤터('(주)엠에스벤터')은(는) 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당
              개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다.
              <li>
                파기절차 : 이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및
                기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한
                경우가 아니고서는 다른 목적으로 이용되지 않습니다.
              </li>
              <li>
                파기기한 : 이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에,
                개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는
                개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다.
              </li>
            </ul>
            <h3>8. 개인정보 자동 수집 장치의 설치•운영 및 거부에 관한 사항</h3>
            <ul>
              <li>
                ① (주)엠에스벤터 은 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는
                ‘쿠기(cookie)’를 사용합니다
              </li>
              <li>
                ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며
                이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다
                <ul>
                  <li>
                    가. 쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어,
                    보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다.
                  </li>
                  <li>
                    나. 쿠키의 설치•운영 및 거부 : 웹브라우저 상단의 도구&gt;인터넷 옵션&gt;개인정보 메뉴의 옵션 설정을
                    통해 통해 쿠키 저장을 거부 할 수 있습니다.
                  </li>
                  <li>다. 쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다.</li>
                </ul>
              </li>
            </ul>
            <h3>9. 개인정보 보호책임자 작성</h3>
            <ul>
              <li>
                ① (주)엠에스벤터(‘https://www.benter.co.kr’이하 ‘(주)엠에스벤터) 은(는) 개인정보 처리에 관한 업무를
                총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이
                개인정보 보호책임자를 지정하고 있습니다.
                <ul>
                  개인정보 보호책임자
                  <li>성명 :오만석</li>
                  <li>직책 : 대표</li>
                  <li>직급 : 대표이사</li>
                  <li>연락처 :1544-7120, boram03@benter.co.kr</li>
                  <li>※ 개인정보 보호 담당부서로 연결됩니다.</li>
                </ul>
                <ul>
                  개인정보 보호 담당부서
                  <li>부서명 :개발팀</li>
                  <li>담당자 :이성재</li>
                  <li>연락처 :adffewr@benter.co.kr</li>
                </ul>
              </li>
              <li>
                ② 정보주체께서는 (주)엠에스벤터(‘https://www.benter.co.kr’이하 ‘(주)엠에스벤터) 의 서비스(또는 사업)을
                이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보
                보호책임자 및 담당부서로 문의하실 수 있습니다. (주)엠에스벤터(‘http://www.benter.co.kr’이하
                ‘(주)엠에스벤터) 은(는) 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다.
              </li>
            </ul>
            <h3>10. 개인정보 처리방침 변경</h3>
            <p>
              ①이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는
              경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.
            </p>
            <h3>11. 개인정보의 안전성 확보 조치</h3>
            <ul>
              (주)엠에스벤터('(주)엠에스벤터')은(는) 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한
              기술적/관리적 및 물리적 조치를 하고 있습니다.
              <li>
                1. 개인정보 취급 직원의 최소화 및 교육
                <p>
                  개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고
                  있습니다.
                </p>
              </li>
              <li>
                2. 해킹 등에 대비한 기술적 대책 <br />
                (주)엠에스벤터('(주)엠에스벤터')은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기
                위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을
                설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
              </li>
              <li>
                2. 해킹 등에 대비한 기술적 대책 <br />
                (주)엠에스벤터('(주)엠에스벤터')은 해킹이나 컴퓨터 바이러스 등에 의한 개인정보 유출 및 훼손을 막기
                위하여 보안프로그램을 설치하고 주기적인 갱신·점검을 하며 외부로부터 접근이 통제된 구역에 시스템을
                설치하고 기술적/물리적으로 감시 및 차단하고 있습니다.
              </li>
              <li>
                3. 접속기록의 보관 및 위변조 방지 <br />
                개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난,
                분실되지 않도록 보안기능 사용하고 있습니다.
              </li>
              <li>
                4. 개인정보에 대한 접근 제한 <br />
                개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한
                접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고
                있습니다.
              </li>
              <li>
                5. 비인가자에 대한 출입 통제 <br />
                개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고
                있습니다.
              </li>
            </ul>
          </div>
          <div className="text_box2">
            <h2>
              'MSB'은 (이하 '회사'는) 고객님의 개인정보를 중요시하며, <br />
              "정보통신망 이용촉진 및 정보보호"에 관한 법률을 준수하고 있습니다. <br />
              회사는 개인정보취급방침을 통하여 고객님께서 제공하시는 개인정보가 <br />
              어떠한 용도와 방식으로 이용되고 있으며, <br />
              개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다. <br />
              회사는 개인정보취급방침을 개정하는 경우 <br />
              웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다.
            </h2>
            <ul>
              <li>
                <h3>1. 수집하는 개인정보 항목</h3>
                <p>회사는 회원가입, 상담, 서비스 신청 등등을 위해 아래와 같은 개인정보를 수집하고 있습니다.</p>
                <p>
                  - 수집항목 : 이름, 생년월일, 성별, 로그인ID, 비밀번호, 자택 전화번호, 자택 주소, 휴대전화번호, 이메일,
                  직업, 회사명, 부서, 직책, 회사전화번호, 접속 로그
                </p>
                <p>- 개인정보 수집방법 : 홈페이지(회원가입), 전화/팩스를 통한 회원가입</p>
              </li>
              <li>
                <h3>2. 개인정보의 수집 및 이용목적</h3>
                <p>회사는 수집한 개인정보를 다음의 목적을 위해 활용합니다.</p>
                <p>
                  - 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산 : 콘텐츠 제공, 물품배송 또는 청구지 등
                  발송
                </p>
                <p>
                  - 회원관리 : 연령확인, 만14세 미만 아동 개인정보 수집 시 법정 대리인 동의여부 확인, 불만처리 등
                  민원처리, 고지사항 전달
                </p>
              </li>
              <li>
                <h3>3. 개인정보의 보유 및 이용기간</h3>
                <p>
                  원칙적으로, 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 단, 다음의
                  정보에 대해서는 아래의 이유로 명시한 기간 동안 보존합니다.
                </p>
                <p>
                  - 보존 항목 : 이름, 생년월일, 성별, 로그인ID, 비밀번호, 자택 전화번호, 자택 주소, 휴대전화번호,
                  이메일, 직업, 회사명, 부서, 직책, 회사전화번호
                </p>
                <p>- 보존 근거 : 대한제강 사규- 보존 기간 : 회원탈퇴시까지</p>
              </li>
              <li>
                <h3>4. 개인정보의 파기절차 및 방법</h3>
                <p>
                  회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체없이 파기합니다. 파기절차
                  및 방법은 다음과 같습니다.
                </p>
                <p>
                  - 파기절차 : 회원님이 회원가입 등을 위해 입력하신 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의
                  경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조)
                  일정 기간 저장된 후 파기되어집니다. 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는
                  보유되어지는 이외의 다른 목적으로 이용되지 않습니다.
                </p>
                <p>
                  - 파기방법 : 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여
                  삭제합니다.
                </p>
              </li>
              <li>
                <h3>5. 개인정보 제공</h3>
                <p>
                  회사는 이용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.
                </p>
                <p>- 이용자들이 사전에 동의한 경우</p>
                <p>
                  - 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우
                </p>
              </li>
              <li>
                <h3>6. 수집한 개인정보의 위탁</h3>
                <p>
                  회사는 고객님의 동의없이 고객님의 정보를 외부 업체에 위탁하지 않습니다. 향후 그러한 필요가 생길 경우,
                  위탁 대상자와 위탁 업무 내용에 대해 고객님에게 통지하고 필요한 경우 사전 동의를 받도록 하겠습니다.
                </p>
              </li>
              <li>
                <h3>7. 이용자 및 법정대리인의 권리와 그 행사방법</h3>
                <p>
                  이용자 및 법정 대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를
                  조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다. 이용자 혹은 만 14세 미만 아동의 개인정보
                  조회,수정을 위해서는 ‘개인정보변경’(또는 ‘회원정보수정’ 등)을 가입해지(동의철회)를 위해서는
                  ‘회원탈퇴’를 클릭 하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은
                  개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체없이 조치하겠습니다.귀하가 개인정보의
                  오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전 까지 당해 개인정보를 이용 또는 제공하지
                  않습니다. 또한 잘못된 개인정보를 제3자 에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이
                  통지하여 정정이 이루어지도록 하겠습니다. 대한제강은 이용자 혹은 법정 대리인의 요청에 의해 해지 또는
                  삭제된 개인정보는 “대한제강이 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에 따라 처리하고 그
                  외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.
                </p>
              </li>
              <li>
                <h3>8. 개인정보 자동수집 장치의 설치, 운영 및 그 거부에 관한 사항</h3>
                <p>쿠키 등 인터넷 서비스 이용 시 자동 생성되는 개인정보를 수집하는 장치를 운영하지 않습니다.</p>
              </li>
              <li>
                <h3>9. 개인정보에 관한 민원서비스</h3>
                <p>
                  회사는 고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 아래와 같이 관련 부서 및
                  개인정보관리책임자를 지정하고 있습니다. 귀하께서는 회사의 서비스를 이용하시며 발생하는 모든
                  개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당부서로 신고하실 수 있습니다. 회사는 이용자들의
                  신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.
                </p>
              </li>
              <li>
                <h3>기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.</h3>
                <p>1. 개인분쟁조정위원회 (www.1336.or.kr / 1336)</p>
                <p>2. 정보보호마크인증위원회 (www.eprivacy.or.kr / 02-580-0533~4)</p>
                <p>3. 대검찰청 사이버범죄수사단 (https://cybercid.spo.go.kr / 02-3480-2509 / 02-3480-3600)</p>
                <p>4. 경찰청 사이버테러대응센터 (www.ctrc.go.kr / 02-392-0330)</p>
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </>
  )
}

export default PrivacyPage
