import styled from 'styled-components'
// import remote from '../../assets/images/q_remote.svg'
import remote from '../../assets/images/remote.png'
import balju from '../../assets/images/q_balju.png'
import fms from '../../assets/images/fms_order_icon.png'
import consulting from '../../assets/images/q_consulting.svg'
import kakao from '../../assets/images/q_kakao.svg'

export const QuickSideMenuContainer = styled.div`
  position: fixed;
  right: -150px;
  top: 250px;

  z-index: 200;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;

  width: 170px;
  height: 365px;
  background-color: #fff;
  box-shadow: -2px 4px 10px rgba(0, 0, 0, 0.08);
  border-radius: 10px 0 0 10px;
  border: 1px solid #ebebeb;
  border-right: none;
  padding: 2rem;
  box-sizing: border-box;
  letter-spacing: -0.5px;

  transition: 0.6s cubic-bezier(0.39, 0.575, 0.565, 1);

  ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    a {
      display: block;
      position: relative;
      color: #222;
      padding: 15px 0 15px 40px;
      span {
        font-size: 16px;
        font-family: 'Pretendard-semiBold';
      }
    }
    a:before {
      content: '';
      position: absolute;
      top: 13px;
      left: 0;
      width: 26px;
      height: 26px;
    }
    li:nth-child(1) a:before {
      background: url(${remote});
      background-size: 26px;
    }
    li:nth-child(2) a:before {
      background: url(${consulting});
      background-size: 26px;
    }
    li:nth-child(3) a:before {
      background: url(${balju});
      background-size: 26px;
    }
    li:nth-child(4) a:before {
      background: url(${kakao});
      background-size: 26px 26px;
    }
  }
  .customer_center {
    width: 100%;
    span {
      display: block;
      width: 100%;
      height: 1.5px;
      background-color: #ccc;
      margin-bottom: 25px;
    }
    p {
      text-align: center;
      font-family: 'Pretendard-bold';
      font-size: 18px;
      color: #333;
    }
    p.color {
      font-size: 21px;
      color: #febe00;
      margin-top: 15px;
    }
  }
  .btn {
    position: absolute;
    top: 160px;
    left: -18px;
    width: 32px;
    height: 32px;
    background-color: #febe00;
    border-radius: 100%;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.active {
    right: 0px;
    .btn {
      img {
        transform: rotate(180deg);
      }
    }
  }
  @media screen and (max-width: 1000px) {
    display: none;
  }
`

export const TopScroll = styled.button`
  position: absolute;
  /* background-color: #ddd; */
  right: 0;
  bottom: -0rem;
  width: 100%;
  height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  opacity: 0;
  transition: 0.4s cubic-bezier(0.79, 0.01, 0, 1.01);

  &.active {
    opacity: 1;
    bottom: -3.5rem;
    color: #222;
  }

  span {
    font-family: 'Pretendard-semiBold';
    font-size: 16px;
    &.material-icons-outlined {
      font-size: 30px;
      font-family: 'Material Icons Outlined';
    }
  }
`
