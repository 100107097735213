import styled from 'styled-components'

export const CardHeader = styled.ul`
  height: 40px;
  display: grid;
  grid-template-columns: repeat(9, 150px);
  align-items: center;
`

export const HeaderList = styled.li`
  span {
    font-size: 1.1rem;
    font-weight: 500;
  }
  &:nth-child(6),
  :nth-child(8) {
    text-align: center;
  }
  &:last-child {
    margin-left: 2.5rem;
    text-align: center;
  }
`
