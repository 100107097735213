import styled from 'styled-components'

export const List = styled.li`
  display: grid;
  /* align-items: center;
  justify-content: space-between; */
  grid-template-columns: repeat(9, 150px);
  align-items: center;

  .title_btn {
    font-size: 16px;
    font-weight: 600;
    /* flex: 1 auto; */
    overflow: hidden;
    background: teal;
    width: 4rem;
    padding: 0.7rem;
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem;
    color: white;
    margin-left: 3.5rem;
  }
  & + li {
    margin-top: 1rem;
  }
  .option_box {
    display: flex;
    min-width: 170px;
    align-items: center;
    justify-content: flex-end;
    button + button {
      margin-left: 1rem;
    }

    .state {
      background-color: #ef4444;
      color: #fff;
      margin-right: 1rem;
      font-size: 0.875rem;
      padding: 0.75rem 0.8rem;
      box-sizing: border-box;
      border-radius: 0.25rem;
    }
    .state.active {
      background-color: #febe00;
      font-size: 0.875rem;
      padding: 0.75rem 1.2rem;
    }
  }
  .manager {
    text-align: center;
  }
`
