import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import AdminHeader from '../../components/AdminHeader'
import AdminContainer from '../../containers/AdminContainer'

import { faImage } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import '@toast-ui/editor/dist/toastui-editor.css'
import { Editor } from '@toast-ui/react-editor'
import { ContentBox, ModalBox } from './styles'
import Button from '../../components/Button'
import Input from '../../components/Input'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'

import { db } from '../../firebase'
import { doc, setDoc, getDoc } from 'firebase/firestore'

const AdminNoticeWritePage = () => {
  let navigate = useNavigate()
  let params = useParams()
  const [isActive, setIsActive] = useState(false)
  const [closed, setClosed] = useState(true)
  const [title, setTitle] = useState('')
  const [thumbnail, setThumbnail] = useState('')
  const [description, setDescription] = useState('')
  const [initialValue, setInitialValue] = useState('')
  const [postCreatedAt, setCreatedAt] = useState('')
  const [loading, setLoading] = useState(false)
  const editorRef = useRef(null)
  const inputRef = useRef(null)
  const [pageLoading, setPageLoading] = useState(false)

  useEffect(() => {
    const getNotice = async () => {
      const docRef = doc(db, 'notice', params.id)
      const docSnap = await getDoc(docRef)
      setTitle(docSnap.data().title)
      setThumbnail(docSnap.data().thumbnail)
      setDescription(docSnap.data().description)
      setInitialValue(docSnap.data().content)
      setCreatedAt(docSnap.data().createdAt)
      setPageLoading(true)
    }
    if (params.id) {
      getNotice()
    } else {
      setPageLoading(true)
    }
  }, [])

  useEffect(() => {
    document.body.style.overflowY = isActive ? 'hidden' : 'initial'

    let timeoutId

    if (isActive) {
      timeoutId = setTimeout(() => {
        setClosed(false)
      }, 200)
    } else {
      timeoutId = setTimeout(() => {
        setClosed(true)
      }, 200)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [isActive])

  const onClickModalOpen = () => {
    if (title.trim().length === 0) {
      alert('제목을 입럭해 주세요.')
      return
    }
    setIsActive(true)
  }

  const onClickModalClose = () => {
    setIsActive(false)
  }

  const onClickThumbnailAdd = () => {
    inputRef.current.click()
  }

  const onClickThumbnailRemove = () => {
    setThumbnail('')
  }

  const onChangeFileImage = e => {
    // console.log(e.target.files)
    const reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    reader.onload = function () {
      // console.log(reader.result)
      setThumbnail(reader.result)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const onChangeDescription = e => {
    setDescription(e.target.value)
  }

  const onChangeTitle = e => {
    setTitle(e.target.value)
  }

  const onClickSubmit = async () => {
    if (thumbnail.trim().length === 0) {
      alert('썸네일을 등록해주세요.')
      return
    }

    if (description.trim().length === 0) {
      alert('포스트 소개를 작성해주세요.')
      return
    }

    setLoading(true)
    // console.log(editorRef.current.getInstance().getHTML())

    const today = new Date()
    let dd = today.getDate()
    let mm = today.getMonth() + 1 //January is 0!
    const yyyy = today.getFullYear()
    if (dd < 10) {
      dd = '0' + dd
    }
    if (mm < 10) {
      mm = '0' + mm
    }

    let _name
    let createdAt
    let id

    if (params.id) {
      createdAt = postCreatedAt
      id = params.id
    } else {
      createdAt = today
      _name = yyyy + mm + dd
      const RandomNumber = Math.random().toString(36).substr(2, 11)
      id = `notice${RandomNumber}${_name}`
    }

    try {
      await setDoc(doc(db, 'notice', id), {
        title,
        thumbnail,
        content: editorRef.current.getInstance().getHTML(),
        description,
        createdAt,
        id,
      })
      alert('개시글 작성 완료')
      navigate('/admin/notice')
    } catch (e) {
      console.error('Error adding document: ', e)
    }
  }

  return (
    <>
      <AdminHeader title="공지사항 작성" />
      <AdminContainer>
        <ContentBox>
          <div className="input_box">
            <label htmlFor="title">제목</label>
            <Input id="title" size="big" value={title} onChange={onChangeTitle} />
          </div>
          {pageLoading && (
            <Editor
              initialValue={initialValue}
              previewStyle="vertical"
              height="600px"
              initialEditType="wysiwyg"
              useCommandShortcut={true}
              ref={editorRef}
            />
          )}

          <div className="button_box">
            <Button onClick={() => navigate('/admin/notice')} theme="tertiary">
              취소
            </Button>
            <Button onClick={onClickModalOpen}>작성하기</Button>
          </div>
        </ContentBox>
        {!closed && (
          <>
            <ModalBox isActive={isActive}>
              <div className="content">
                <h2>포스트 설정</h2>
                <h3>썸네일</h3>
                <div className="thumbnail_box">
                  <div className="thumbnail_content">
                    {thumbnail.length < 1 ? (
                      <>
                        <FontAwesomeIcon size="5x" icon={faImage} />
                        <Button onClick={onClickThumbnailAdd}>썸네일 업로드</Button>
                      </>
                    ) : (
                      <>
                        <img src={thumbnail} alt="thumbnail" />
                        <FontAwesomeIcon
                          size="5x"
                          icon={faTrashAlt}
                          className="remove_icon"
                          onClick={onClickThumbnailRemove}
                        />
                        {/*<TrashIcon className="trash w-10 h-10 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 cursor-pointer hidden" onClick={onClickThumbnailRemove} />*/}
                      </>
                    )}
                    <input ref={inputRef} type="file" accept="image/*" hidden onChange={onChangeFileImage} />
                  </div>
                </div>
                <h3>포스트 소개</h3>
                <div className="description_box">
                  <textarea
                    value={description}
                    onChange={onChangeDescription}
                    placeholder="포스터를 짧게 소개해보세요(150자)"
                    maxLength={150}
                  />
                </div>
                <div className="button_box">
                  <Button onClick={onClickModalClose} theme="tertiary">
                    취소
                  </Button>
                  <Button onClick={onClickSubmit} loading={loading}>
                    작성하기
                  </Button>
                </div>
              </div>
            </ModalBox>
          </>
        )}
      </AdminContainer>
    </>
  )
}

export default AdminNoticeWritePage
