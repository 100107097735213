import styled from 'styled-components'
// import sec4Circle from '../../assets/images/section4_circle.png'

export const EventContainer = styled.div`
  padding-top: 70px;
  box-sizing: border-box;
  background-color: #fefefe;
  letter-spacing: -0.5px;
  line-height: 1.5;
  color: #222;

  p {
    font-family: 'Pretendard-Medium';
  }

  .titleWrap {
    margin-bottom: 45px;

    .title {
      font-size: 34px;
      margin-bottom: 40px;
      text-align: center;
      font-family: 'Pretendard-ExtraBold';
    }
    .subTitle {
      font-size: 18px;
      text-align: center;
      color: #333;
      font-family: 'Pretendard-Medium';
    }
  }
  @media screen and (max-width: 495px) {
    .titleWrap {
      margin-bottom: 20px;

      .title {
        font-size: 24px;
        margin-bottom: 20px;
      }
      .subTitle {
        font-size: 14px;
      }
    }
  }
`

export const Section1 = styled.div`
  padding: 50px 0;
  /* box-sizing: border-box; */
  width: 100%;
  background-color: #fef9ec;
  position: relative;

  .topWrap {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 30px;
    box-sizing: border-box;
    display: flex;

    .topText {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      p {
        font-size: 18px;
      }

      .topTitle {
        padding: 20px 0;
        box-sizing: border-box;
        line-height: 1.2;

        p {
          font-size: 36px;
          font-family: 'Pretendard-Bold';
          span {
            font-size: 46px;
            font-family: 'Pretendard-ExtraBold';
            color: #ffb800;
          }
        }
      }

      .topButton {
        padding-top: 20px;
        box-sizing: border-box;
        display: flex;
        /* justify-content: space-between; */
        flex-direction: column;
        gap: 20px;
        a {
          display: flex;
          width: 100%;
        }
        button {
          padding: 10px 15px;
          box-sizing: border-box;
          background-color: #febe00;
          border-radius: 10px;
          /* display: inline-block; */
          font-size: 20px;
          font-family: 'Pretendard-Bold';
          color: #fff;
          display: flex;
          justify-content: center;
          width: 70%;
          align-items: center;
          outline: none;
          border: 2px solid #febe00;
          transition: 0.3s ease-out;
        }
        button:hover {
          background-color: #fff;
          color: #febe00;
        }
      }
    }
  }
  .topImg {
    width: 50%;
    /* position: absolute; */
    /* top: 0; */
    /* right: 0; */
    height: 100%;
    img {
      width: 100%;
      /* height: 100%; */
    }
  }

  @media screen and (max-width: 865px) {
    .topWrap {
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      .topText {
        width: 100%;
        align-items: center;
        padding-top: 20px;
        p {
          text-align: center;
        }
        .topTitle {
          text-align: center;
        }
        .topButton {
          width: 100%;
          align-items: center;
          a {
            justify-content: center;
          }
        }
      }
      .topImg {
        width: 70%;
      }
    }
  }
  @media screen and (max-width: 495px) {
    .topWrap {
      .topText {
        p {
          font-size: 14px;
        }
        .topTitle {
          padding: 10px 0;
          p {
            font-size: 24px;
            span {
              font-size: 30px;
            }
          }
        }
        .topButton {
          button {
            width: 100%;
            font-size: 18px;
          }
        }
      }
      .topImg {
        width: 100%;
      }
    }
  }
`

export const Section2 = styled.div`
  padding: 120px 0;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  .sec2Img {
    width: 100%;

    img {
      width: 100%;
    }
  }
`

export const Section3 = styled.div`
  padding: 120px 0;
  width: 100%;
  margin: 0 auto;
  background-color: #fdf6e0;

  .programBox {
    max-width: 1200px;
    width: 100%;
    height: 560px;
    margin: 0 auto;
    padding: 50px 0;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 20px;

    .tapTitle {
      display: flex;
      justify-content: space-around;
      padding-bottom: 40px;

      li {
        font-size: 22px;
        font-family: 'Pretendard-Bold';
        cursor: pointer;
        position: relative;
      }
      li.active {
        color: #febe00;
        transition: all 0.3s ease 0s;
      }
      li:before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: calc(100% - 1px);
        transform: translateX(-50%);
        width: 0px;
        height: 2px;
        background-color: #333;
        transition: all 0.3s ease 0s;
      }
      li:hover:before {
        width: 100%;
      }
      li.active:before {
        width: 100%;
        background-color: #febe00;
      }
    }
  }
  @media screen and (max-width: 1040px) {
    .programBox {
      height: 630px;
    }
  }
  @media screen and (max-width: 865px) {
    .programBox {
      max-width: 700px;
      padding: 30px 0;
    }
  }
  @media screen and (max-width: 495px) {
    .programBox {
      max-width: 340px;
      height: 600;
      .tapTitle li {
        font-size: 18px;
      }
    }
  }
`

export const ProgramFms = styled.div`
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  display: flex;

  .fmsImg {
    width: 50%;
    /* height: 420px; */

    img {
      width: 100%;
      /* height: 100%; */
      /* object-fit: contain;
       */
    }
  }
  .fmsText {
    width: 50%;
    padding: 0 40px;
    box-sizing: border-box;

    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      /* padding-left: 30px; */
      box-sizing: border-box;

      li {
        display: flex;
        align-items: center;
        padding: 20px 0;
        font-size: 18px;
        font-family: 'Pretendard-Regular';

        img {
          width: 20px;
          margin-right: 10px;
        }
      }
    }
  }
  @media screen and (max-width: 865px) {
    flex-direction: column;
    align-items: center;
    padding: 0;
    .fmsImg {
      width: 60%;
    }
    .fmsText {
      width: 100%;
      padding: 20px 0 0 0;
      ul {
        align-items: center;
        padding-left: 0;

        li {
          padding: 10px 0;
          font-size: 16px;
        }
      }
    }
  }
  @media screen and (max-width: 495px) {
    padding: 0 20px;
    .fmsImg {
      width: 100%;
    }
    .fmsText {
      padding-top: 0;
      ul {
        height: 320px;
        li {
          font-size: 14px;
          align-items: flex-start;
          width: 300px;
        }
      }
    }
  }
`

export const ProgramBalju = styled.div`
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  display: flex;

  .baljuImg {
    width: 50%;
    /* height: 420px; */

    img {
      width: 100%;
      /* height: 100%; */
      /* object-fit: contain; */
      /* margin-left: 50px; */
    }
  }
  .baljuText {
    width: 50%;
    padding: 0 40px;
    box-sizing: border-box;
    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      /* padding-left: 30px; */
      box-sizing: border-box;

      li {
        display: flex;
        align-items: center;
        padding: 20px 0;
        font-size: 18px;
        font-family: 'Pretendard-Regular';

        img {
          width: 20px;
          margin-right: 10px;
        }
      }
    }
  }
  @media screen and (max-width: 865px) {
    flex-direction: column;
    align-items: center;
    padding: 0;
    .baljuImg {
      width: 60%;
    }
    .baljuText {
      width: 100%;
      padding: 20px 0 0 0;
      ul {
        align-items: center;
        padding-left: 0;

        li {
          padding: 10px 0;
          font-size: 16px;
        }
      }
    }
  }
  @media screen and (max-width: 495px) {
    padding: 0 20px;
    .baljuImg {
      width: 100%;
    }
    .baljuText {
      padding-top: 0;
      ul {
        height: 320px;

        li {
          font-size: 14px;
          align-items: flex-start;
          width: 100%;
        }
      }
    }
  }
`

export const ProgramPda = styled.div`
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  display: flex;

  .pdaImg {
    width: 50%;
    padding-top: 30px;
    /* height: 420px; */

    img {
      width: 100%;
      /* height: 100%; */
      /* margin-left: 50px; */
      /* object-fit: contain; */
    }
  }
  .pdaText {
    width: 50%;
    padding: 0 40px;
    box-sizing: border-box;
    ul {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      /* padding-left: 30px; */
      box-sizing: border-box;

      li {
        display: flex;
        align-items: center;
        padding: 20px 0;
        font-size: 18px;
        font-family: 'Pretendard-Regular';

        img {
          width: 20px;
          margin-right: 10px;
        }
      }
    }
  }
  @media screen and (max-width: 865px) {
    flex-direction: column;
    align-items: center;
    padding: 0;
    .pdaImg {
      width: 60%;
    }
    .pdaText {
      width: 100%;
      padding: 20px 0 0 0;
      ul {
        align-items: center;
        padding-left: 0;
        li {
          padding: 10px 0;
          font-size: 16px;
        }
      }
    }
  }
  @media screen and (max-width: 495px) {
    padding: 0 20px;
    .pdaImg {
      width: 100%;
    }
    .pdaText {
      padding-top: 0;
      ul {
        height: 320px;

        li {
          font-size: 14px;
          align-items: flex-start;
          width: 100%;
        }
      }
    }
  }
`

export const Section4 = styled.div`
  padding: 120px 0;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  .contentsWrap {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 20px;
    box-sizing: border-box;

    .cont {
      width: 25%;
      display: flex;
      align-items: center;
      flex-direction: column;
      .circle {
        position: relative;
        width: 100%;
        &:before {
          content: '';
          display: block;
          width: 100%;
          padding-bottom: 100%;
        }

        .circle_item {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 120%;
          height: 120%;

          transform: translate(-50%, -50%);
          border-radius: 50%;
          border: 2px solid #fff;
          box-sizing: border-box;
        }

        .text_item {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 10;
          text-align: center;
          color: #444;
          width: 100%;

          .number {
            font-size: 34px;
            font-weight: 900;
          }

          .text {
            font-size: 22px;
          }
        }
      }

      .decoration {
        margin: 2rem 0;
      }
      .desc {
        height: 65px;
        text-align: center;
        p {
          font-size: 18px;
        }
      }
    }
    .cont1 {
      .circle_item {
        background-color: rgba(254, 190, 0, 0.2);
      }
    }
    .cont2 {
      .circle_item {
        background-color: rgba(254, 190, 0, 0.4);
      }
    }
    .cont3 {
      .circle_item {
        background-color: rgba(254, 190, 0, 0.6);
      }
    }
    .cont4 {
      .circle_item {
        background-color: rgba(254, 190, 0, 0.8);
      }
    }
    .cont5 {
      .circle_item {
        background-color: rgba(254, 190, 0, 1);
      }
    }
  }

  @media screen and (max-width: 1220px) {
    max-width: 1000px;
  }
  @media screen and (max-width: 1000px) {
    max-width: 850px;
    .contentsWrap {
      .cont {
        .circle {
          .text_item {
            .number {
              font-size: 25px;
            }
            .text {
              font-size: 16px;
            }
          }
        }
        .desc {
          p {
            /* font-size: 14px; */
            letter-spacing: -1.5px;
            padding: 0 15px;
            box-sizing: border-box;
          }
        }
      }
    }
  }
  @media screen and (max-width: 850px) {
    .contentsWrap {
      flex-direction: column;
      padding: 0 20px;
      .cont {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        .circle {
          width: 50%;
          padding-bottom: 110px;
          .circle_item {
            width: 100%;
            height: 100px;
            border-radius: 50px;
            border: none;
          }
        }
        .circle:before {
          /* padding-bottom: 112px; */
          display: none;
        }
        .decoration {
          margin: 0;
          width: 15%;
          display: flex;
          justify-content: center;
          img {
            transform: rotate(90deg);
          }
        }
        .desc {
          width: 25%;
          height: 100%;
          p {
            padding: 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .contentsWrap .cont {
      .decoration {
        width: 5%;
        img {
          height: 35px;
        }
      }
      .desc {
        width: 35%;
      }
    }
  }
  @media screen and (max-width: 495px) {
    .contentsWrap {
      gap: 20px;
      .cont {
        display: flex;
        flex-direction: column;
        .circle {
          width: 100%;
        }
        .decoration {
          width: 100%;
          padding: 5px 0;
          img {
            transform: rotate(0);
            height: 30px;
          }
        }
        .desc {
          width: 100%;
          p {
            font-size: 14px;
          }
        }
      }
    }
  }
`

export const Section5 = styled.div`
  display: none;
  padding: 120px 0;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  .filsu {
    width: 100%;
    background-color: #fefbf1;
    padding: 35px;
    box-sizing: border-box;
    border-radius: 20px;

    p.filsuTitle {
      font-size: 24px;
      font-family: 'Pretendard-Bold';
      text-align: center;
      margin-bottom: 35px;
    }

    .filsuDocument {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 120px;
      /* padding-bottom: 20px; */

      .condition {
        width: 255px;
        height: 75px;
        background-color: #feeaae;
        border-radius: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p {
          text-align: center;
          font-size: 16px;
          font-family: 'Pretendard-SemiBold';
        }
      }
      ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        li {
          display: flex;
          gap: 60px;

          .documentName {
            width: 165px;
            height: 55px;
            box-sizing: border-box;
            background-color: #febe00;
            border-radius: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            p {
              font-size: 16px;
              font-family: 'Pretendard-SemiBold';
              color: #fff;
              text-align: center;
              line-height: 1;
            }
          }
          .deco {
            img {
              transform: rotate(90deg);
            }
          }
          .site {
            width: 180px;
            p {
              font-size: 14px;
              text-align: center;
            }
            a {
              width: 100%;
              background-color: #feeaae;
              color: #333;
              border-radius: 20px;
              font-size: 16px;
              font-family: 'Pretendard-Medium';
              display: inline-block;
              text-align: center;
              padding: 5px 0;
              box-sizing: border-box;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
    .line {
      width: 100%;
      border: 1px solid #ddd;
      display: block;
      margin-bottom: 20px;
    }
  }
  .plus {
    width: 100%;
    background-color: #fef5d9;
    padding: 35px;
    box-sizing: border-box;
    border-radius: 20px;

    .plusTitle {
      font-size: 24px;
      font-family: 'Pretendard-Bold';
      text-align: center;
      margin-bottom: 20px;
    }
    p {
      text-align: center;
      font-size: 16px;
    }
    .plusList {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 20px;

      ul {
        display: flex;
        gap: 20px;
        justify-content: center;
        padding-bottom: 10px;
        li {
          font-size: 16px;
          font-family: 'Pretendard-Bold';
          background-color: #fefefe;
          /* color: #fff; */
          text-align: center;
          padding: 10px 30px;
          border-radius: 30px;
        }
      }
      p.staff {
        font-size: 16px;
        font-family: 'Pretendard-Bold';
        background-color: #fefefe;
        /* color: #fff; */
        text-align: center;
        padding: 10px 30px;
        border-radius: 30px;
        display: inline-block;
        margin-top: 20px;
      }
      p.notice {
        font-size: 14px;
      }
      .listDown {
        padding-top: 35px;
        a {
          padding: 10px 20px;
          background-color: #febe00;
          color: #fff;
          font-size: 16px;
          border-radius: 20px;
          font-family: 'Pretendard-Bold';
          display: flex;
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    max-width: 1000px;
    .plus .plusList ul li {
      padding: 10px 20px;
    }
  }
  @media screen and (max-width: 1000px) {
    max-width: 800px;
    .filsu {
      .filsuDocument {
        gap: 30px;
        justify-content: space-between;
      }
    }
    .plus {
      .plusList {
        ul {
          gap: 5px;
          padding-bottom: 5px;
          li {
            padding: 10px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 800px) {
    /* .filsu {
      .filsuDocument {
        flex-direction: column;
        .condition {
          width: 472px;
        }
      }
    } */
    .plus .plusList ul {
      flex-wrap: wrap;
    }
    .filsu {
      .filsuDocument {
        flex-direction: column;
        .condition {
          width: 100%;
        }
        ul {
          width: 100%;
          li {
            width: 100%;
            justify-content: space-between;
            gap: 0;
            .documentName {
              width: 40%;
            }
            .deco {
              width: 20%;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                height: 40px;
              }
            }
            .site {
              width: 40%;
            }
          }
        }
      }
      .line {
        margin: 20px 0;
      }
    }
  }
  @media screen and (max-width: 495px) {
    .filsu .filsuDocument ul li {
      align-items: center;
      .documentName {
        width: 45%;
        p {
          font-size: 14px;
          font-family: 'Pretendard-Medium';
        }
      }
      .deco {
        width: 10%;
      }
      .site {
        width: 45%;
        z-index: 10;
        p {
          display: none;
        }
        a {
          font-size: 14px;
        }
      }
    }
    .plus {
      .plusList {
        ul {
          li {
            font-size: 14px;
            padding: 12px;
          }
        }
        p.notice {
          padding-top: 20px;
          letter-spacing: -1.2px;
        }
        p.staff {
          font-size: 14px;
        }
        .listDown {
          padding-top: 20px;
          a {
            font-size: 14px;
            span {
              font-size: 18px;
            }
          }
        }
      }
    }
  }
`

export const OnlyMs = styled.div`
  padding: 120px 0;
  width: 100%;
  margin: 0 auto;
  background-color: #fdf6e0;

  ul {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 30px;

    li {
      max-width: 380px;
      height: auto;
      background-color: #fff;
      padding: 25px;
      box-sizing: border-box;
      border-radius: 20px;
      display: flex;
      align-items: center;

      .OnlyImg {
        /* width: 30%; */
        flex: 1;
        /* padding-right: 25px; */
        box-sizing: border-box;
        img {
          width: 100%;
        }
      }

      .OnlyText {
        /* width: 70%; */
        flex: 3;
        padding-left: 25px;

        .title {
          font-family: 'Pretendard-Bold';
          font-size: 22px;
          padding-bottom: 15px;
          line-height: 22px;
        }
        .desc {
          font-size: 18px;
          font-family: 'Pretendard-Medium';
          letter-spacing: -1.2px;
          line-height: 24px;
        }
      }
    }
  }
  @media screen and (max-width: 495px) {
    ul li {
      width: 315px;
      .OnlyText {
        .title {
          font-size: 16px;
          padding-bottom: 15px;
          line-height: 16px;
        }
        .desc {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
`

export const Section6 = styled.div`
  padding: 120px 0;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fefefe;

  form {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 45px;
    background-color: #f2f2f2;
    border-radius: 20px;
    box-sizing: border-box;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.1), 0 15px 12px rgba(0, 0, 0, 0.1);

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      li {
        display: flex;
        width: 100%;
        justify-content: space-between;

        label {
          width: 49%;
          input {
            width: 100%;
            border: none;
            padding: 15px 30px;
            box-sizing: border-box;
            border-radius: 10px;
            font-family: 'Pretendard-Regular';
            font-size: 16px;
          }
          input:focus {
            outline: 2px solid #febe00;
            transition: 0.25s;
          }
        }
      }
      .privacy {
        width: 100%;
        text-align: center;

        a {
          font-size: 16px;
          color: #333;
          padding-right: 10px;
        }
        input[type='checkbox'] {
          display: none;
        }
        input[type='checkbox'] + label {
          display: inline-block;
          width: 20px;
          height: 20px;
          border: 1px solid #333;
          /* border: none; */
          background-color: #fff;
          position: relative;
          cursor: pointer;
          vertical-align: text-bottom;
          border-radius: 50%;
        }
        input[id='check1']:checked + label::after {
          content: '✔';
          font-size: 16px;
          width: 100%;
          height: 100%;
          color: #333;
          text-align: center;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      .textArea {
        width: 100%;
        padding-bottom: 30px;
        textarea {
          display: block;
          width: 100%;
          height: 220px;
          border: none;
          font-family: 'Pretendard-Regular';
          font-size: 16px;
          border-radius: 15px;
          padding: 30px;
          box-sizing: border-box;
          resize: none;
        }
        textarea:focus {
          outline: 2px solid #febe00;
          transition: 0.25s;
        }
      }
    }
    .submit {
      width: 100%;
      text-align: center;
      input {
        width: 100%;
        background-color: #febe00;
        color: #fff;
        padding: 15px;
        box-sizing: border-box;
        border-radius: 15px;
        font-size: 18px;
        font-family: 'Pretendard-Bold';
        border: 2px solid #febe00;
        cursor: pointer;
      }
      input:hover {
        color: #222;
        background-color: #fefbf1;
        /* border: 2px solid #febe00; */
        transition: 0.3s;
      }
    }
  }
  @media screen and (max-width: 850px) {
    form {
      max-width: 600px;
      padding: 30px;
    }
  }
  @media screen and (max-width: 495px) {
    form {
      max-width: 350px;
      ul {
        gap: 10px;
        li {
          flex-direction: column;
          gap: 10px;
          label {
            width: 100%;
            input {
              font-size: 14px;
            }
          }
        }
        .privacy {
          padding: 10px 0;
          a {
            font-size: 14px;
          }
        }
        .textArea {
          padding-bottom: 20px;
          textarea {
            font-size: 14px;
          }
        }
      }
      .submit {
        input {
          font-size: 16px;
        }
      }
    }
  }
`
