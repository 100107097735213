import styled, { css } from 'styled-components'
import transitions from '../../assets/css/transitions'

export const ModalBox = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  box-sizing: border-box;

  .modal_back {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(249, 249, 249, 0.85);
  }

  .content {
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 600px;
    background-color: #fefefe;
    padding: 30px;
    box-sizing: border-box;
    max-height: 100%;
    overflow: auto;

    .title {
      text-align: center;
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 30px;
    }

    .list {
      display: flex;
      align-items: center;
      border: 1px solid #000;
      div {
        padding: 1rem;
      }
      .sub1 {
        min-width: 120px;
        //border-right: 1px solid #000;
      }
      .txt {
        border-left: 1px solid #000;
        white-space: pre-wrap;
        overflow: hidden;
      }
    }
    .list + .list {
      border-top: none;
    }

    ${props =>
      props.isActive
        ? css`
            animation: ${transitions.popInFromBottom} 0.4s forwards ease-in-out;
          `
        : css`
            animation: ${transitions.popOutToBottom} 0.2s forwards ease-in-out;
          `}

    button {
      margin-top: 30px;
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      border: 1px solid #333;
      border-radius: 3px;
      transition: 0.3s;
      font-weight: 400;
      font-size: 14px;
    }
    button:hover {
      background-color: #000;
      color: #fff;
    }

    .button_box {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button + button {
        margin-left: 1rem;
      }
    }
    select {
      padding: 0.5rem 0.5rem;
      outline: none;
      border: 1px solid rgb(209, 213, 219);
      border-radius: 0.5rem;
      &:focus {
        border: 1px solid #f4b81c;
      }
    }
  }
`
