import styled, { css } from 'styled-components'
import transitions from '../../assets/css/transitions'

export const Container = styled.div`
  padding-top: 70px;
  box-sizing: border-box;
  background-color: #fefefe;
`

export const TopControllerBox = styled.div`
  .content_box {
    padding: 120px 20px 0;
  }
  .max_content {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    letter-spacing: -1px;
  }
  h2 {
    font-size: 30px;
    font-width: 500;
    text-align: center;
    line-height: 1.5;
    strong {
      font-weight: bold;
      color: #ffbe00;
    }
    br {
      display: none;
    }
  }
  .box_content {
    margin-top: 50px;
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: -5px -5px 15px rgba(0, 0, 0, 0.07), 5px 5px 15px rgba(0, 0, 0, 0.07);

    display: flex;
    align-items: center;
    justify-content: center;

    .text_content {
      width: 50%;
      min-height: 250px;
      display: flex;
      flex-direction: column;
    }

    .icon {
      width: auto;
      margin-top: -40px;
    }

    .text_box {
    }

    .button_box {
      margin-top: auto;
      display: flex;
      align-items: center;

      button + button {
        margin-left: 40px;
      }
    }

    button {
      padding: 0.5rem;
      box-sizing: border-box;
      width: 165px;
      height: 40px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: #fff;
      margin: 0;
      border: none;
      font-size: 18px;
      cursor: pointer;
      span {
        margin-left: 30px;
      }
    }

    button.pink {
      background-color: #ff7d85;
    }

    button.blue {
      background-color: #3576ff;
    }
  }

  @media screen and (max-width: 1000px) {
    .content_box {
      padding: 50px 20px 0;
    }
    h2 {
      br {
        display: block;
      }
    }
    .box_content {
      flex-direction: column;
      .text_content {
        margin-top: 20px;
        width: 100%;
        order: 2;
        min-height: 150px;
      }
      .icon {
        order: 1;
        max-width: 472px;
        display: block;
        width: 100%;
        height: auto;
        //display: none;
      }

      .button_box {
        display: none;
      }
    }
  }
`

export const TextBox = styled.div`
  h3 {
    font-size: 50px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  p {
    font-size: 22px;
    line-height: 1.5;
  }

  ${props =>
    props.isActive
      ? css`
          animation: ${transitions.leftSlideShow} 0.2s forwards ease-in-out;
        `
      : css`
          animation: ${transitions.rightSlideHide} 0.2s forwards ease-in-out;
        `};

  @media screen and (max-width: 1000px) {
    h3 {
      font-size: 30px;
    }

    p {
      font-size: 18px;
    }
  }
`

export const TimeLineBox = styled.div`
  margin-top: 50px;
  position: sticky;
  left: 0;
  top: 70px;
  overflow: auto;
  background-color: #fefefe;
  z-index: 100;
  &.active {
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  }

  .line_content {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    z-index: 10;
  }

  ul {
    display: flex;
    align-items: center;
    height: 120px;
  }

  li {
    position: relative;
    width: 120px;
  }

  .boll {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #222222;
    cursor: pointer;
  }

  .txt {
    width: 100%;
    position: absolute;
    top: calc(100% + 25px);
    left: 50%;
    //transform: translateX(calc(-50% + 10px));
    transform: translateX(-50%);
    font-weight: 500;
    text-align: center;
    transition: all 0.3s;
    font-size: 15px;
    cursor: pointer;
  }

  .txt.top {
    top: calc(0% - 40px);
  }

  .active .boll {
    width: 30px;
    height: 30px;
    background-color: #f63440;
    transition: all 0.3s;
  }

  .active .txt {
    font-size: 16px;
    color: #f63440;
    //transform: translateX(calc(-50% + 15px));
  }

  .line {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #222222;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    min-width: 1200px;
  }
`

export const ContentContainerBox = styled.div`
  margin-top: 80px;
  .img_content_box {
    display: none;
  }
  .img_content_box.active {
    display: block;
  }
  .bg_content {
    width: 100%;
    height: 680px;
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;

    .text_box {
      max-width: 1044px;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      color: #fff;
      position: relative;
      z-index: 10;
      display: flex;
      justify-content: center;
      flex-direction: column;
      letter-spacing: -1px;

      h2 {
        font-size: 60px;
        font-weight: bold;
      }

      h3 {
        font-size: 30px;
      }

      p {
        margin-top: 50px;
        line-height: 1.6;
        font-size: 18px;
        letter-spacing: 1px;
      }
    }

    .bg_box {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .content_box {
    margin: 80px auto 200px;
    padding: 0 20px;
    width: 100%;
    box-sizing: border-box;
    .max_content {
      max-width: 1044px;
      width: 100%;
      margin: 0 auto;
    }
    .title {
      font-size: 34px;
      font-weight: bold;
      box-sizing: border-box;
      margin-bottom: 80px;
      letter-spacing: -1px;
      span {
        color: #ffbe00;
      }
    }

    .title.right {
      text-align: right;
    }

    .icon_text_box {
      display: flex;
      align-items: center;
      margin-bottom: 100px;

      .img_box {
        width: 30%;
        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }

      .text_box {
        margin-left: 100px;
        width: 50%;
        p {
          color: #333;
          line-height: 1.8;
          font-size: 22px;
          font-weight: 500;
          letter-spacing: -1px;
          .color {
            color: #ffbe00;
          }
          .neon {
            background-color: #ffbe00;
            color: #fff;
          }
          .neon_center {
            background: linear-gradient(to top, #ffbe00 50%, transparent 50%);
          }
        }
      }

      .text_box.right {
        text-align: right;
        margin-left: 0;
        margin-right: 100px;
      }
    }
  }

  .icon_text_box.right {
    justify-content: flex-end;
  }

  .coming {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px 100px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    img {
      display: block;
      width: 100%;
      height: auto;
      margin: 0 auto 40px;
      //max-width: 100%;
      max-width: 700px;
    }
    p {
      font-size: 90px;
      font-weight: 600;
      //line-height: 1.6;
      text-align: center;
    }
  }

  @media screen and (max-width: 1000px) {
    .content_box {
      .title {
        padding-left: 0;
      }
      .title.right {
        padding-right: 0;
      }

      .icon_text_box {
        flex-direction: column;

        .img_box {
          width: 100%;
          max-width: 400px;
          order: 1;
        }

        .text_box {
          width: 100%;
          order: 2;
          margin-top: 40px;
          margin-left: 0;
          margin-right: 0;
          text-align: center;
        }

        .text_box.right {
          text-align: center;
          margin-right: 0;
        }
      }
    }
    .coming {
      p {
        font-size: 30px;
      }
    }
  }
`
