import styled from 'styled-components'
// import backimg from '../../assets/images/center_bg.png'
import backimg from '../../assets/images/center_bg.jpg'

export const Container = styled.div`
  padding-top: 70px;
  box-sizing: border-box;

  width: 100%;
  height: 100%;

  .container {
    width: 100%;
    height: auto;
    background-image: url(${backimg});
    background-size: cover;
    background-attachment: fixed;
  }

  .contact {
    width: 100%;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    padding: 180px 78px 0;
    box-sizing: border-box;

    h2 {
      font-size: 48px;
      font-weight: 500;
      color: #ffffff;
    }

    .tap_menu {
      padding-top: 40px;

      ul {
        display: flex;
        justify-content: flex-start;

        li {
          padding: 10px 30px;
          box-sizing: border-box;

          button {
            color: #ffffff;
            font-size: 20px;
            font-weight: 400;
          }
          button.active {
            color: #febe00;
            font-weight: 500;
            font-size: 20px;
            position: relative;
          }
          button.active:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -3px;
            width: 100%;
            height: 2px;
            background-color: #febe00;
          }
        }
        li:nth-child(1) {
          padding-left: 0;
        }
      }
    }
    form {
      padding-top: 40px;

      ul {
        display: flex;
        justify-content: space-between;

        li {
          padding: 20px;

          label {
            font-size: 20px;
            font-weight: 400;
            color: #ffffff;

            span {
              color: #febe00;
            }
          }
          input {
            margin-left: 22px;
            border: none;
            background-color: transparent;
            height: 20px;
            font-size: 16px;
            letter-spacing: -1px;
            color: #bbb;
          }
          input:focus {
            outline: none;
          }
          input::placeholder {
            color: #bbb;
          }
        }
        li:nth-child(1) {
          padding-left: 0;
        }
        li:nth-child(3n) {
          padding-right: 0;
        }
      }

      .check_box {
        padding-top: 40px;

        a {
          font-size: 20px;
          color: #fff;
          padding-right: 10px;
        }

        input[type='checkbox'] {
          display: none;
        }
        input[type='checkbox'] + label {
          display: inline-block;
          width: 20px;
          height: 20px;
          border: 1px solid #bbb;
          position: relative;
          cursor: pointer;
          vertical-align: text-bottom;
          border-radius: 50%;
        }
        input[id='check1']:checked + label::after {
          content: '✔';
          font-size: 16px;
          width: 100%;
          height: 100%;
          color: #bbb;
          text-align: center;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      .text_area {
        padding-top: 50px;

        label {
          color: #fff;
          font-size: 20px;

          span {
            color: #febe00;
          }
        }
        textarea {
          display: block;
          padding-top: 30px;
          width: 100%;
          height: 150px;
          border: none;
          background-color: transparent;
          font-size: 16px;
          color: #bbb;
          resize: none;
          font-family: 'Spoqa Han Sans Neo', 'sans-serif';
        }
        textarea::placeholder {
          color: #bbb;
          font-weight: 300;
        }
        textarea:focus {
          outline: none;
        }
      }

      .submit {
        padding: 30px 0 180px 0;
        display: flex;
        justify-content: center;

        input {
          border: none;
          background-color: #febe00;
          color: #fff;
          width: 115px;
          height: 50px;
          font-size: 20px;
          font-weight: 500;
          box-sizing: border-box;
          cursor: pointer;
          font-family: 'Spoqa Han Sans Neo', 'sans-serif';
        }
      }
    }
  }
  @media screen and (max-width: 1110px) {
    .contact {
      max-width: 100%;

      .tap_menu {
        ul {
          li {
            padding: 10px 20px;
          }
        }
      }
      form {
        ul {
          width: 100%;
          li {
            padding: 15px;

            label {
              font-size: 16px;
            }

            input {
              margin-left: 12px;
              width: 150px;
            }
            input::placeholder {
              font-size: 14px;
            }
          }
        }
        .check_box {
          a {
            font-size: 16px;
          }
          input[type='checkbox'] + label {
            width: 16px;
            height: 16px;
          }
          input[type='checkbox']:checked + label::after {
            font-size: 12px;
          }
        }
        .text_area {
          label {
            font-size: 16px;
          }
          textarea {
            font-size: 14px;
            height: 100px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 880px) {
    .contact {
      padding: 150px 50px 0;

      h2 {
        font-size: 35px;
      }

      .tap_menu ul li {
        padding: 0 10px;

        button {
          font-size: 18px;
        }
        button.active {
          font-size: 18px;
        }
      }
      form ul li {
        padding: 20px;

        label {
          display: block;
        }
      }
    }
  }
  @media screen and (max-width: 640px) {
    .contact {
      padding: 100px 35px 0;
      form {
        padding-top: 35px;
        ul {
          flex-direction: column;
          li {
            padding-left: 0;
            input {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 440px) {
    .contact {
      padding: 65px 20px 0;

      h2 {
        font-size: 28px;
        text-align: center;
      }
      .tap_menu {
        padding-top: 30px;
        ul li {
          padding: 0 5px;

          button {
            font-size: 16px;
          }
          button.active {
            font-size: 16px;
          }
        }
      }
      form {
        padding-top: 20px;

        .check_box {
          padding-top: 20px;
        }
        .text_area {
          padding-top: 30px;
          textarea {
            height: 80px;
          }
        }
        .submit {
          padding: 20px 0 65px 0;
        }
      }
    }
  }
`
