import styled from 'styled-components'

export const AuthBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  box-sizing: border-box;
  div {
    max-width: 400px;
  }
`
