import { collection, deleteDoc, doc, getDocs, limit, onSnapshot, orderBy, query, setDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import AdminHeader from '../../components/AdminHeader'
import Button from '../../components/Button'
import Card from '../../components/Card'
import AdminContainer from '../../containers/AdminContainer'
import { db } from '../../firebase'
import { SuccessMessageOpen } from '../../hooks/toast'
import { calcDate } from '../../utils/calcDate'
import { Form, Input, AdminList, AdminUser } from './styles'
const AdminSettingPage = () => {
  const [manager, setManager] = useState('')
  const [managerList, setManagerList] = useState([])

  useEffect(() => {
    let isComponentMounted = true
    const getData = () => {
      try {
        const q = query(collection(db, 'settings'), orderBy('createdAt', 'desc'), limit(50))
        onSnapshot(q, querySnapshot => {
          const list = querySnapshot.docs.map(doc => ({
            ...doc.data(),
          }))
          if (isComponentMounted) {
            setManagerList(list)
          }
        })
      } catch (error) {
        console.log(error)
      }
    }
    getData()
    return () => {
      isComponentMounted = false
    }
  }, [])
  const onChange = e => {
    const {
      target: { value },
    } = e
    setManager(value)
  }
  const onSubmit = async e => {
    const blankPattern = /[\s]/g
    if (manager === '' || manager.match(blankPattern)) return
    e.preventDefault()
    try {
      const RandomNumber = Math.random().toString(36).substr(2, 11)
      const id = `manager${RandomNumber}`
      const managerObj = {
        id,
        name: manager,
        createdAt: Date.now(),
      }
      await setDoc(doc(db, 'settings', id), managerObj)
      SuccessMessageOpen('담당자 추가가 완료되었습니다.')
    } catch (error) {
      console.log(error)
    }
    setManager('')
  }
  const onDelete = async id => {
    const ok = window.confirm('정말 삭제하시겠습니까?')
    if (ok) {
      await deleteDoc(doc(db, 'settings', id))
    }
  }

  return (
    <>
      <AdminHeader title="설정" />
      <AdminContainer>
        <Form onSubmit={onSubmit}>
          <Input type="text" value={manager} onChange={onChange} required />
          <Button type="submit">추가</Button>
        </Form>
        <Card>
          {managerList.map(user => (
            <AdminList key={user.id}>
              <AdminUser>
                <span>담당자</span>
                <span>{user.name}</span>
                <span>{calcDate(user.createdAt)}</span>
              </AdminUser>
              <Button type="button" onClick={() => onDelete(user.id)} theme="tertiary">
                삭제
              </Button>
            </AdminList>
          ))}
        </Card>
      </AdminContainer>
    </>
  )
}

export default AdminSettingPage
