import styled from 'styled-components'

export const QuickMenuContent = styled.div`
  position: fixed;
  right: 98px;
  bottom: 50px;

  z-index: 200;
  display: none;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  .quick_btn {
    outline: none;
    border: none;
    width: 58px;
    height: 58px;
    border-radius: 29px;
    padding: 0;
    margin: 0;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-out;
    .icon_btn {
      width: 58px;
      height: 58px;
      background-color: #ffbe00;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 10;
      right: 0;
      top: 0;

      .icon1 {
        width: 35px;
        height: auto;
      }

      img.top {
        width: 30px;
        height: auto;
      }

      .text {
        color: #fff;
        font-size: 12px;
      }
      .text2 {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
      }
    }
    .icon_btn.blue {
      background-color: #3389c0;
    }

    .side_text {
      position: absolute;
      background-color: rgba(155, 155, 155, 0.45);
      height: 58px;
      right: 0;
      top: 0;
      border-radius: 30px;
      padding-right: 68px;
      padding-left: 20px;
      box-sizing: border-box;
      width: 165px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #ffbe00;
      font-size: 20px;
      font-weight: bold;
      overflow: hidden;
      transition: width 0.3s;

      img {
        width: 35px;
        height: auto;
      }
    }
    &:hover {
      width: 165px;
    }
  }

  @media screen and (max-width: 1520px) {
    right: 10px;
  }
  @media screen and (max-width: 1000px) {
    display: none;
  }
`
