import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AdminHeader from '../../components/AdminHeader'
import authState from '../../store/authState'
import { useRecoilState, useRecoilValue } from 'recoil'
import AdminContainer from '../../containers/AdminContainer'
import { ContainerBox, TitleBox } from './styles'
import Card from '../../components/Card'
import CardList from '../../components/CardList'
import noticeState from '../../store/noticeState'
import { collection, deleteDoc, doc, getDoc, getDocs, limit, orderBy, query } from 'firebase/firestore'
import { db } from '../../firebase'
import { ModalBox } from '../AdminNoticePage/styles'
import ModalCard from '../../components/ModalCard'
import NoticeModal from '../../components/NoticeModal'

const AdminPage = () => {
  let navigate = useNavigate()
  const userData = useRecoilValue(authState)
  const [noticeLists, setNoticeLists] = useRecoilState(noticeState)
  const [isActive, setIsActive] = useState(false)
  const [closed, setClosed] = useState(true)
  const [postId, setPostId] = useState(null)
  const [loading, setLoading] = useState(false)

  const getNotices = async () => {
    if (noticeLists.length < 1) {
      const q = query(collection(db, 'notice'), orderBy('createdAt', 'desc'), limit(50))
      const querySnapshot = await getDocs(q)
      const list = querySnapshot.docs.map(e => e.data())
      setNoticeLists(list)
    }
  }

  useEffect(() => {
    getNotices()
  }, [])

  const updateNotice = id => {
    const list = noticeLists.filter(v => v.id !== id)
    setNoticeLists(list)
  }

  useEffect(() => {
    document.body.style.overflowY = isActive ? 'hidden' : 'initial'

    let timeoutId

    if (isActive) {
      timeoutId = setTimeout(() => {
        setClosed(false)
      }, 200)
    } else {
      timeoutId = setTimeout(() => {
        setClosed(true)
      }, 200)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [isActive])

  const onClickModalOn = id => {
    setPostId(id)
    setIsActive(true)
  }

  const onClickModalClose = () => {
    setPostId(null)
    setIsActive(false)
  }

  const actionSubmit = async () => {
    setLoading(true)
    try {
      const docRef = doc(db, 'notice', postId)
      await deleteDoc(docRef)
      setLoading(false)
      updateNotice(postId)
      onClickModalClose()
    } catch (e) {
      console.log(e)
    }
  }

  // 카드 모달 상태값 및 데이터 값
  const [noticeActive, setNoticeActive] = useState(false)
  const [noticeTitle, setNoticeTitle] = useState('')
  const [noticeContent, setNoticeContent] = useState('')

  // 소식 카드 눌렀을때 모달 오픈
  const onClickNoticeModalOpen = async id => {
    const docRef = doc(db, 'notice', id)
    const docSnap = await getDoc(docRef)
    setNoticeTitle(docSnap.data().title)
    setNoticeContent(docSnap.data().content)
    setNoticeActive(true)
  }

  // 카드 모달 닫기 이벤트
  const noticeClose = () => {
    setNoticeActive(false)
  }

  useEffect(() => {
    if (userData && userData.userEmail === '') {
      navigate('/')
    }
  }, [userData])

  return (
    <>
      <AdminHeader title="Admin" />
      <AdminContainer>
        <ContainerBox>
          <TitleBox>공지사항</TitleBox>
          <Card>
            <ul className="list_box">
              {noticeLists.length > 0 && (
                <>
                  {noticeLists.map(v => (
                    <CardList
                      onClickNoticeModalOpen={onClickNoticeModalOpen}
                      key={v.id}
                      title={v.title}
                      id={v.id}
                      onClick={() => onClickModalOn(v.id)}
                    />
                  ))}
                </>
              )}
            </ul>
            <NoticeModal isActive={noticeActive} closeEvent={noticeClose} title={noticeTitle} content={noticeContent} />
          </Card>
          {!closed && (
            <>
              <ModalBox isActive={isActive}>
                <div className="back" onClick={onClickModalClose} />
                <div className="modal_content">
                  <ModalCard
                    title="공지사항 삭제"
                    loading={loading}
                    actionEvent={actionSubmit}
                    closeEvent={onClickModalClose}
                    actionMsg="삭제"
                  >
                    해당 포스트를 삭제 하시겠습니까?
                    <br />
                    삭제한 포스트는 복구 할 수 없습니다.
                  </ModalCard>
                </div>
              </ModalBox>
            </>
          )}
        </ContainerBox>
      </AdminContainer>
    </>
  )
}

export default AdminPage
