import React, { useRef } from 'react'
import { CardBox } from './styles'
import { animated, useSpring, to } from 'react-spring'
import { useGesture } from 'react-use-gesture'

const calcX = (y, ly) => -(y - ly - window.innerHeight / 2) / 20
const calcY = (x, lx) => (x - lx - window.innerWidth / 2) / 20

const MainCard = ({ src, text, btnText, onClick }) => {
  const domTarget = useRef(null)

  const [{ x, y, rotateX, rotateY, rotateZ, zoom, scale }, api] = useSpring(() => ({
    rotateX: 0,
    rotateY: 0,
    rotateZ: 0,
    scale: 1,
    zoom: 0,
    x: 0,
    y: 0,
    config: { mass: 5, tension: 350, friction: 40 },
  }))

  useGesture(
    {
      // onDrag: ({ active, offset: [x, y] }) => api({ x, y, rotateX: 0, rotateY: 0, scale: active ? 1 : 1.1 }),
      // onPinch: ({ offset: [d, a] }) => api({ zoom: d / 200, rotateZ: a }),
      onMove: ({ dragging }) =>
        !dragging &&
        api({
          scale: 1.05,
        }),
      onHover: ({ hovering }) => !hovering && api({ rotateX: 0, rotateY: 0, scale: 1 }),
    },
    { domTarget, eventOptions: { passive: false } },
  )

  return (
    <>
      <animated.div
        ref={domTarget}
        style={{
          transform: 'perspective(600px)',

          scale: to([scale, zoom], (s, z) => s + z),
        }}
      >
        <CardBox>
          <div className="img_box">
            <img src={src} alt="card" />
          </div>
          <p>{text}</p>
          <button type="button" onClick={onClick}>
            {btnText}
          </button>
        </CardBox>
      </animated.div>
    </>
  )
}

export default MainCard
