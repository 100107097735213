import React, { useEffect, useState } from 'react'
import { Viewer } from '@toast-ui/react-editor'
import { ModalBox } from './styles'
import Input from '../Input'
import { ErrorMessageOpen } from '../../hooks/toast'
import { collection, doc, limit, onSnapshot, orderBy, query, setDoc } from 'firebase/firestore'
import { db } from '../../firebase'
import { calcDate } from '../../utils/calcDate'

const ContactModal = ({ isActive, closeEvent, data }) => {
  const [closed, setClosed] = useState(true)
  const [person, setPerson] = useState('')
  const [manager, setManager] = useState([])
  const onChangePerson = e => {
    const {
      target: { value },
    } = e
    if (value.length === 0) {
      ErrorMessageOpen('담당자를 선택해 주세요.')
      return
    }
    setPerson(value)
  }

  const onClickOk = async () => {
    if (person.length === 0) {
      ErrorMessageOpen('담당자를 선택해 주세요.')
      return
    }
    // console.log(data)
    // return
    try {
      await setDoc(doc(db, 'contact_us', data.id), { ...data, state: true, person, completedAt: Date.now() })
      closeEvent()
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    let isComponentMounted = true
    const getData = () => {
      const q = query(collection(db, 'settings'), orderBy('createdAt', 'desc'), limit(50))
      onSnapshot(q, querySnapshot => {
        const list = querySnapshot.docs.map(doc => ({
          ...doc.data(),
        }))
        if (isComponentMounted) {
          setManager(list)
        }
      })
    }

    getData()
    return () => {
      isComponentMounted = false
    }
  }, [])
  useEffect(() => {
    document.body.style.overflowY = isActive ? 'hidden' : 'initial'

    let timeoutId

    if (isActive) {
      timeoutId = setTimeout(() => {
        setClosed(false)
      }, 200)
    } else {
      timeoutId = setTimeout(() => {
        setClosed(true)
      }, 200)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [isActive])

  return (
    <>
      {!closed && (
        <>
          <ModalBox isActive={isActive}>
            <div className="content">
              <div className="title">문의사항</div>
              {data.selectCheck && (
                <div className="list">
                  <div className="sub1">구분</div>
                  <div className="txt">
                    {data.selectCheck === 1
                      ? '설치문의'
                      : data.selectCheck === 2
                      ? '교육문의'
                      : data.selectCheck === 3
                      ? '도입문의'
                      : data.selectCheck === 4
                      ? '가격문의'
                      : '비대면 바우처'}
                  </div>
                </div>
              )}
              {data.company && (
                <div className="list">
                  <div className="sub1">회사명</div>
                  <div className="txt">{data.company}</div>
                </div>
              )}
              {data.name && (
                <div className="list">
                  <div className="sub1">이름</div>
                  <div className="txt">{data.name}</div>
                </div>
              )}
              {data.grade && (
                <div className="list">
                  <div className="sub1">직급</div>
                  <div className="txt">{data.grade}</div>
                </div>
              )}
              {data.phone && (
                <div className="list">
                  <div className="sub1">전화번호</div>
                  <div className="txt">{data.phone}</div>
                </div>
              )}
              {data.email && (
                <div className="list">
                  <div className="sub1">이메일</div>
                  <div className="txt">{data.email}</div>
                </div>
              )}
              {data.channel && (
                <div className="list">
                  <div className="sub1">유입경로</div>
                  <div className="txt">{data.channel}</div>
                </div>
              )}
              {data.content && (
                <div className="list">
                  <div className="sub1">내용</div>
                  <div className="txt">{data.content}</div>
                </div>
              )}
              {data.createdAt && (
                <div className="list">
                  <div className="sub1">문의 시간</div>
                  <div className="txt">{data.createdAt}</div>
                </div>
              )}
              <div className="list">
                <div className="sub1">해결여부</div>
                <div className="txt">{data.state ? '해결' : '미해결'}</div>
              </div>

              {data.completedAt && (
                <div className="list">
                  <div className="sub1">해결 완료시간</div>
                  <div className="txt">{calcDate(data.completedAt)}</div>
                </div>
              )}
              <div className="list">
                <div className="sub1">담당자</div>
                <div className="txt">
                  {/* {data.state ? <>{data.person}</> : <Input value={person} onChange={onChangePerson} />} */}
                  {data.state ? (
                    <>{data.person}</>
                  ) : (
                    <select value={person} onChange={onChangePerson}>
                      <option value="">담당자를 선택하세요</option>
                      {manager &&
                        manager.map(user => (
                          <option key={user.id} value={user.name}>
                            {user.name}
                          </option>
                        ))}
                    </select>
                  )}
                </div>
              </div>

              <div className="button_box">
                {!data.state && <button onClick={onClickOk}>사건해결</button>}
                <button onClick={closeEvent}>홈페이지로 나가기</button>
              </div>
            </div>
            <div className="modal_back" onClick={closeEvent} />
          </ModalBox>
        </>
      )}
    </>
  )
}

export default ContactModal
