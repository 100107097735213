import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyDQGyK0VUfJqNBM6e09MP3zSW63KUAZUqQ',
  authDomain: 'benter-4b964.firebaseapp.com',
  projectId: 'benter-4b964',
  storageBucket: 'benter-4b964.appspot.com',
  messagingSenderId: '470168355883',
  appId: '1:470168355883:web:25d07ef659d580acf1166e',
}

const app = initializeApp(firebaseConfig)

const auth = getAuth()
const db = getFirestore()

export { app, auth, db }
