import styled from 'styled-components'

export const InputTag = styled.input`
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: ${props => (props.size === 'normal' ? ' 0.875rem' : '1rem')};
  line-height: 1;
  //padding: 0.5rem;
  padding: ${props => (props.size === 'normal' ? '0.5rem' : '0.75rem 1rem')};
  box-sizing: border-box;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border: 1px solid rgb(209, 213, 219);
  outline: none;
  border-radius: 5px;
  &:focus {
    border: 1px solid #f4b81c;
    //outline: 1.5px solid #f4b81c;
  }
`
