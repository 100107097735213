import React from 'react'
import { List } from './styles'
import Button from '../Button'
import { calcDate } from '../../utils/calcDate'

const ContactList = ({
  selectCheck,
  company,
  grade,
  createdAt,
  title,
  onClick,
  id,
  onClickModalOn,
  state,
  person,
  completedAt,
}) => {
  return (
    <>
      <List>
        <span>
          {selectCheck === 1
            ? '설치문의'
            : selectCheck === 2
            ? '교육문의'
            : selectCheck === 3
            ? '도입문의'
            : selectCheck === 4
            ? '가격문의'
            : '비대면 바우처'}
        </span>
        <span>{title}</span>
        <span>{grade}</span>
        <span>{company}</span>
        <span className="createdAt">{createdAt.seconds ? calcDate(createdAt.seconds * 1000) : createdAt}</span>
        <span className="manager">{person}</span>
        <span className="completedAt">{completedAt ? calcDate(completedAt) : null}</span>
        <button className="title_btn" type="button" onClick={() => onClickModalOn(id)}>
          보기
        </button>
        <div className="option_box">
          <div className={`${state ? 'active' : ''} state`}>{state ? '해결' : '미해결'}</div>
          <Button theme="tertiary" onClick={onClick}>
            삭제
          </Button>
        </div>
      </List>
    </>
  )
}

export default ContactList
