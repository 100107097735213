import styled, { css } from 'styled-components'
import transitions from '../../assets/css/transitions'

export const ModalBox = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  box-sizing: border-box;

  .modal_back {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(249, 249, 249, 0.85);
  }

  .content {
    position: relative;
    z-index: 2;
    width: 100%;
    max-height: 100%;
    max-width: 1000px;
    overflow: auto;
    background-color: #fefefe;
    padding: 30px;
    box-sizing: border-box;

    ${props =>
      props.isActive
        ? css`
            animation: ${transitions.popInFromBottom} 0.4s forwards ease-in-out;
          `
        : css`
            animation: ${transitions.popOutToBottom} 0.2s forwards ease-in-out;
          `}

    button {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      border: 1px solid #333;
      border-radius: 3px;
      transition: 0.3s;
      font-weight: 400;
      font-size: 14px;
    }
    button:hover {
      background-color: #000;
      color: #fff;
    }

    .thumbnail_box {
      width: 100%;
      position: relative;
      background-color: #d1d5db;
      margin-bottom: 1rem;
      &:before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 56.25%;
      }
      .thumbnail_content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #fff;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .remove_icon {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: #f4b81c;
          cursor: pointer;
        }
      }
    }

    .button_box {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button + button {
        margin-left: 1rem;
      }
    }
  }
`
