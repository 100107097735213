import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  .container {
    letter-spacing: -1px;
    h1 {
      font-size: 24px;
      margin-bottom: 25px;
      text-align: center;
    }
    h2 {
      background-color: #ccc;
      border-radius: 15px;
      padding: 10px;
      margin: 10px 0;
      font-size: 18px;
      text-align: center;
      line-height: 28px;
    }
    h3 {
      padding: 10px 0;
    }
    p {
      padding: 10px 0;
      font-size: 14px;
      font-weight: 300;
      margin-left: 10px;
    }
    ul {
      padding: 10px 0;
      font-size: 16px;
      font-weight: 400;

      li {
        padding: 10px 0;
        margin-left: 10px;
        font-size: 14px;
        font-weight: 300;
      }
    }
  }
`
