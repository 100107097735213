import React, { useEffect, useState } from 'react'
import AdminHeader from '../../components/AdminHeader'
import AdminContainer from '../../containers/AdminContainer'
import { ContainerBox } from './styles'
import Card from '../../components/Card'
import { collection, deleteDoc, doc, getDoc, getDocs, limit, orderBy, query } from 'firebase/firestore'
import { db } from '../../firebase'

import ContactList from '../../components/ContactList'
import { useRecoilValue } from 'recoil'
import authState from '../../store/authState'
import { useNavigate } from 'react-router-dom'
import ContactModal from '../../components/ContactModal'
import { ModalBox } from '../AdminNoticePage/styles'
import ModalCard from '../../components/ModalCard'
import ContactListHeader from '../../components/ContactListHeader'
import { calcDate } from '../../utils/calcDate'

const AdminContentPage = () => {
  let navigate = useNavigate()
  const userData = useRecoilValue(authState)
  const [contactList, setContactList] = useState([])
  const [isActive, setIsActive] = useState(false)
  const [closed, setClosed] = useState(true)
  const [postId, setPostId] = useState(null)
  const [postData, setPostData] = useState(null)
  const [loading, setLoading] = useState(false)

  const getContacts = async () => {
    if (contactList.length < 1) {
      const q = query(collection(db, 'contact_us'), orderBy('createdAt', 'desc'), limit(50))
      const querySnapshot = await getDocs(q)
      const list = querySnapshot.docs.map(e => e.data())
      setContactList(list)
    }
  }
  useEffect(() => {
    getContacts()
  }, [])

  // const onClickModalOn = id => {
  //   setPostId(id)
  //   setIsActive(true)
  // }

  // 카드 모달 상태값 및 데이터 값
  const [contactActive, setContactActive] = useState(false)
  const [contactData, setContactData] = useState('')

  // 소식 카드 눌렀을때 모달 오픈
  const onClickModalOn = async id => {
    const docRef = doc(db, 'contact_us', id)
    const docSnap = await getDoc(docRef)
    if (docSnap.data().createdAt.seconds) {
      const createDate = new Date(docSnap.data().createdAt.seconds * 1000)
      const year = createDate.getFullYear()
      const month = leftPad(createDate.getMonth() + 1)
      const day = leftPad(createDate.getDate())
      const hour = leftPad(createDate.getHours())
      const minute = leftPad(createDate.getMinutes())

      setContactData({
        ...docSnap.data(),
        createdAt: `${year}-${month}-${day} ${hour}:${minute}`,
      })
    } else {
      setContactData(docSnap.data())
    }

    setContactActive(true)
  }

  function leftPad(value) {
    if (value >= 10) {
      return value
    }
    return `0${value}`
  }

  // 카드 모달 닫기 이벤트
  const contactClose = () => {
    setContactActive(false)
  }

  //  상담 삭제 모달
  const onClickDelModalOn = id => {
    setPostId(id)
    setIsActive(true)
  }

  const onClickDelModalClose = () => {
    setPostId(null)
    setIsActive(false)
  }

  const actionSubmit = async () => {
    setLoading(true)
    try {
      const docRef = doc(db, 'contact_us', postId)
      await deleteDoc(docRef)
      const newList = contactList.filter(v => {
        return v.id !== postId
      })
      setContactList(newList)
      setLoading(false)
      onClickDelModalClose()
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    document.body.style.overflowY = isActive ? 'hidden' : 'initial'

    let timeoutId

    if (isActive) {
      timeoutId = setTimeout(() => {
        setClosed(false)
      }, 200)
    } else {
      timeoutId = setTimeout(() => {
        setClosed(true)
      }, 200)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [isActive])

  useEffect(() => {
    if (userData && userData.userEmail === '') {
      navigate('/')
    }
  }, [userData])

  return (
    <>
      <AdminHeader title="문의사항" />
      <AdminContainer>
        <ContainerBox>
          <Card>
            <ContactListHeader />
            <ul className="list_box">
              {contactList.length > 0 && (
                <>
                  {contactList
                    .sort((a, b) => {
                      //내림차순 정렬
                      const firstDate = a.createdAt.seconds ? calcDate(a.createdAt.seconds * 1000) : a.createdAt
                      const secondDate = b.createdAt.seconds ? calcDate(b.createdAt.seconds * 1000) : b.createdAt
                      if (firstDate < secondDate) {
                        return 1
                      } else if (firstDate > secondDate) {
                        return -1
                      } else {
                        return 0
                      }
                    })
                    .map(v => (
                      <ContactList
                        key={v.id}
                        title={v.name}
                        selectCheck={v.selectCheck}
                        company={v.company}
                        grade={v.grade}
                        createdAt={v.createdAt}
                        person={v.person}
                        id={v.id}
                        completedAt={v.completedAt}
                        state={v.state}
                        onClickModalOn={() => onClickModalOn(v.id)}
                        onClick={() => onClickDelModalOn(v.id)}
                      />
                    ))}
                </>
              )}
            </ul>
            <ContactModal isActive={contactActive} closeEvent={contactClose} data={contactData} />
            {!closed && (
              <ModalBox isActive={isActive}>
                <div className="back" onClick={onClickDelModalClose} />
                <div className="modal_content">
                  <ModalCard
                    title="상담 삭제"
                    loading={loading}
                    actionEvent={actionSubmit}
                    closeEvent={onClickDelModalClose}
                    actionMsg="삭제"
                  >
                    해당 상담을 삭제 하시겠습니까?
                    <br />
                    삭제한 상담은 복구 할 수 없습니다.
                  </ModalCard>
                </div>
              </ModalBox>
            )}
          </Card>
        </ContainerBox>
      </AdminContainer>
    </>
  )
}

export default AdminContentPage
