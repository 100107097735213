import React from 'react'
import { List } from './styles'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../Button'

const CardList = ({ title, onClick, id, onClickNoticeModalOpen }) => {
  let navigate = useNavigate()

  return (
    <>
      <List>
        <button className="title_btn" type="button" onClick={() => onClickNoticeModalOpen(id)}>
          {title}
        </button>
        <div className="option_box">
          <Button onClick={() => navigate(`/admin/write/${id}`)}>수정</Button>
          <Button theme="tertiary" onClick={onClick}>
            삭제
          </Button>
        </div>
      </List>
    </>
  )
}

export default CardList
