import styled from 'styled-components'

export const VoucherEventContainer = styled.div`
  width: 100%;
  height: 70px;
  background-color: #1d1d28;
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 102;
  overflow: hidden;
  /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); */
  box-shadow: 0px 3px 10px rgb(0 0 0 / 15%);

  cursor: pointer;
  .link_box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .img {
    display: flex;
    margin-right: 20px;
  }

  .text {
    font-size: 20px;
    color: #ddd;
    text-align: center;
    font-family: 'Pretendard-semiBold';
    span {
      font-size: 24px;
      font-family: 'Pretendard-ExtraBold';
      color: #fff;
    }
  }
  span {
    font-size: 35px;
    color: #fff;
  }
  .lineUp {
    animation: 3s anim-lineUp ease-out infinite;
  }
  @keyframes anim-lineUp {
    0% {
      opacity: 0;
      transform: translateY(80%);
    }
    20% {
      /* opacity: 0; */
    }
    40% {
      opacity: 1;
      transform: translateY(0%);
    }
    60% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  @media screen and (max-width: 1000px) {
    display: none;
    /* .link_box {
      padding: 0 30px;
      box-sizing: border-box;
      justify-content: space-between;
      .img {
        margin-right: 0;
      }
      .text {
        font-size: 18px;
        span {
          font-size: 20px;
        }
      }
    } */
  }
`

export const VoucherEventContainerM = styled.div`
  display: none;

  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 103;
  /* height: 350px; */
  background-color: #1d1d28c2;
  padding: 20px;
  box-sizing: border-box;

  .Mobile {
    /* .effect {
      width: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      img {
        width: 25%;
      }
      img.lowImg {
        transform: translateY(-15px);
      }
      img.highImg {
        transform: translateY(-90px);
      }
    } */
    .link_box {
      /* width: 100%; */
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      padding: 30px 20px;
      box-sizing: border-box;
      /* margin: 20px; */
      background-color: #fff;
      border-radius: 20px;
      p {
        font-family: 'Pretendard-ExtraBold';
        color: #222;
      }
      .title {
        text-align: center;
        font-size: 26px;
        padding: 20px 0;
      }
      .btn {
        padding: 5px 20px;
        box-sizing: border-box;
        border-radius: 20px;
        background-color: #febe00;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        span {
          animation: loop 1s infinite;
          color: #222;
        }

        @keyframes loop {
          0% {
            transform: translateX(-0px);
          }
          50% {
            transform: translateX(10px);
          }
          100% {
            transform: translateX(-0px);
          }
        }
      }
    }
  }
  .closeBtn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* padding: 20px; */
    /* box-sizing: border-box; */
    margin-top: 20px;
    /* background-color: #fff; */
    /* border-radius: 20px; */
    button {
      width: 49%;
      font-size: 14px;
      font-family: 'Pretendard-ExtraBold';
      color: #222;
      padding: 10px 20px;
      box-sizing: border-box;
      background-color: #fff;
      border-radius: 15px;
      /* letter-spacing: -1px; */
    }
  }

  @media screen and (max-width: 1000px) {
    display: block;
  }
`
