import { atom } from 'recoil'

export const effectActiveSate = atom({
  key: 'effectActiveSate',
  default: null,
})

export const effectScrollState = atom({
  key: 'effectScrollState',
  default: false,
})
