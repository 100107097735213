import styled from 'styled-components'

export const ContainerBox = styled.div`
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  padding: 120px 20px;
  box-sizing: border-box;
  .title {
    letter-spacing: -1px;
  }

  .content {
    max-width: 1200px;
    margin: 50px auto 0;
    box-sizing: border-box;
    ul {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      gap: 10px;
      li {
        transform-style: preserve-3d;
        perspective: 300px;
      }
      li.active {
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0;
        transition: 1s;
        transform: translateY(10px) rotateX(10deg);
      }
      li.active img {
        opacity: 1;
        transform: translateY(0) rotateX(0);
      }
      .img1 {
        transition-delay: 0s;
      }
      .img2 {
        transition-delay: 0.3s;
      }
      .img3 {
        transition-delay: 0.6s;
      }
      .img4 {
        transition-delay: 0.9s;
      }
      .img5 {
        transition-delay: 1.2s;
      }
    }
  }

  .sub_title2 {
    font-size: 40px;
    color: #333;
    text-align: center;
  }

  @media screen and (max-width: 1000px) {
    .content ul {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media screen and (max-width: 800px) {
    .content ul {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media screen and (max-width: 500px) {
    .content ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`
