import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding-top: 70px;
  box-sizing: border-box;
`

export const ContentBox = styled.div`
  width: 100%;
  position: relative;
  min-height: 100vh;
  padding: 0 20px 100px;
  box-sizing: border-box;
  .black_box {
    position: absolute;
    left: 0;
    top: 0;
    width: 35%;
    height: 100%;
    background-color: #323232;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5);
    z-index: 20;
  }

  .content {
    //position: relative;
    //z-index: 10;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 100px;
    box-sizing: border-box;

    .content_mini {
      width: 100%;
      max-width: 1200px;
    }

    .card_box.yellow {
      background-color: #ffbe00;
    }

    .card_box {
      width: 100%;
      box-sizing: border-box;
      box-shadow: -5px -5px 15px rgba(0, 0, 0, 0.07), 5px 5px 15px rgba(0, 0, 0, 0.07);
      display: flex;
      align-items: center;
      background-color: #f8f8f8;
      padding: 45px;
      position: relative;
      overflow: hidden;
      &:before {
        content: '';
        display: block;
        width: 120px;
        height: 120px;
        position: absolute;
        right: -60px;
        bottom: -60px;
        background-color: #ffbe00;
        transform: rotate(45deg);
      }

      & + div {
        margin-top: 150px;
      }

      .logo {
        position: absolute;
        right: 20px;
        top: 20px;
        width: 100px;
        height: auto;
      }

      .img_container {
        position: relative;
        z-index: 20;
        width: 50%;
        max-width: 450px;
        background-color: #ccc;
        &:before {
          content: '';
          display: block;
          width: 100%;
          padding-bottom: 100%;
        }
        img {
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        img.small {
          width: 180px;
          height: 50px;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }

      .text_container {
        width: 50%;
        margin-left: 50px;
        box-sizing: border-box;
        position: relative;
        letter-spacing: -1px;

        h2 {
          line-height: 1.5;
          font-size: 30px;
          font-weight: bold;
          text-align: center;
          padding-right: 20px;
          box-sizing: border-box;
        }

        .text_box {
          margin-top: 30px;
          color: #333333;
          line-height: 1.8;
          font-size: 16px;
          p + p {
            margin-top: 20px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1520px) {
    .content .content_mini {
      max-width: 1085px;
    }
    .content .card_box .img_container {
      width: 40%;
    }
    .content .card_box .text_container h2 {
      padding-right: 70px;
    }
  }

  @media screen and (max-width: 1000px) {
    .black_box {
      display: none;
    }
    .content {
      .card_box {
        padding: 100px 0 20px;
        flex-direction: column;
        z-index: 30;

        & + div {
          margin-top: 90px;
        }

        .logo {
          left: 50%;
          transform: translateX(-50%);
          width: 130px;
          top: 30px;
        }
        .logo.small {
          width: 90px;
          top: 8px;
        }

        .img_container {
          width: 100%;
        }

        .text_container {
          width: 100%;
          margin-left: 0;

          h2 {
            margin-top: 20px;
            font-size: 22px;
            padding: 0 125px;
          }

          .text_box {
            font-size: 16px;
            padding: 0 50px 50px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .content {
      .card_box {
        & + div {
          margin-top: 70px;
        }

        .logo {
          left: 50%;
          transform: translateX(-50%);
          top: 25px;
        }
        .logo.small {
          width: 80px;
          top: 20px;
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    .content {
      padding-top: 50px;
      .card_box {
        .img_container {
          width: 70%;
        }

        .text_container {
          h2 {
            padding: 0 55px;
          }

          .text_box {
            font-size: 16px;
            padding: 0 50px 50px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 440px) {
    .content {
      .card_box {
        padding: 70px 0 0;

        .logo {
          width: 80px;
          top: 20px;
        }
        .logo.small {
          width: 50px;
          top: 10px;
        }
        .text_container {
          h2 {
            padding: 0 35px;
            font-size: 18px;
          }

          .text_box {
            font-size: 14px;
            padding: 0 30px 50px;
          }
        }
      }
    }
  }
`
