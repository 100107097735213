import React from 'react'
import { ButtonBox } from './styles'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Button = ({ children, onClick, width, type, theme, loading }) => {
  return (
    <>
      <ButtonBox onClick={onClick} width={width} type={type} theme={theme} loading={loading.toString()}>
        {loading && <FontAwesomeIcon icon={faSpinner} spin style={{ marginRight: '10px' }} />}
        {children}
      </ButtonBox>
    </>
  )
}

Button.defaultProps = {
  width: 'auto',
  type: 'button',
  theme: 'primary',
  loading: false,
}

export default Button
