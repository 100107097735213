import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AdminHeader from '../../components/AdminHeader'
import AdminContainer from '../../containers/AdminContainer'
import authState from '../../store/authState'
import noticeState from '../../store/noticeState'

import { useRecoilState, useRecoilValue } from 'recoil'
import { ContainerBox, ModalBox } from './styles'
import Card from '../../components/Card'
import Button from '../../components/Button'

import { db } from '../../firebase'
import { doc, getDocs, collection, query, where, limit, orderBy, deleteDoc } from 'firebase/firestore'
import CardList from '../../components/CardList'
import ModalCard from '../../components/ModalCard'

const AdminNoticePage = () => {
  let navigate = useNavigate()
  const userData = useRecoilValue(authState)
  const [noticeLists, setNoticeLists] = useRecoilState(noticeState)
  const [isActive, setIsActive] = useState(false)
  const [closed, setClosed] = useState(true)
  const [postId, setPostId] = useState(null)
  const [loading, setLoading] = useState(false)

  const getNotices = async () => {
    if (noticeLists.length < 1) {
      const q = query(collection(db, 'notice'), orderBy('createdAt', 'desc'), limit(50))
      const querySnapshot = await getDocs(q)
      const list = querySnapshot.docs.map(e => e.data())
      setNoticeLists(list)
    }
  }

  useEffect(() => {
    getNotices()
  }, [])

  const updateNotice = id => {
    const list = noticeLists.filter(v => v.id !== id)
    setNoticeLists(list)
  }

  useEffect(() => {
    document.body.style.overflowY = isActive ? 'hidden' : 'initial'

    let timeoutId

    if (isActive) {
      timeoutId = setTimeout(() => {
        setClosed(false)
      }, 200)
    } else {
      timeoutId = setTimeout(() => {
        setClosed(true)
      }, 200)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [isActive])

  const onClickModalOn = id => {
    setPostId(id)
    setIsActive(true)
  }

  const onClickModalClose = () => {
    setPostId(null)
    setIsActive(false)
  }

  const actionSubmit = async () => {
    setLoading(true)
    try {
      const docRef = doc(db, 'notice', postId)
      await deleteDoc(docRef)
      setLoading(false)
      updateNotice(postId)
      onClickModalClose()
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (userData && userData.userEmail === '') {
      navigate('/')
    }
  }, [userData])

  return (
    <>
      <AdminHeader title="공지사항" />
      <AdminContainer>
        <ContainerBox>
          <div className="button_box">
            <Button type="button" onClick={() => navigate('/admin/write')}>
              공지사항 작성
            </Button>
          </div>
          <Card>
            <ul className="list_box">
              {noticeLists.length > 0 && (
                <>
                  {noticeLists.map(v => (
                    <CardList key={v.id} title={v.title} id={v.id} onClick={() => onClickModalOn(v.id)} />
                  ))}
                </>
              )}
            </ul>
          </Card>
        </ContainerBox>
        {!closed && (
          <>
            <ModalBox isActive={isActive}>
              <div className="back" onClick={onClickModalClose} />
              <div className="modal_content">
                <ModalCard
                  title="공지사항 삭제"
                  loading={loading}
                  actionEvent={actionSubmit}
                  closeEvent={onClickModalClose}
                  actionMsg="삭제"
                >
                  해당 포스트를 삭제 하시겠습니까?
                  <br />
                  삭제한 포스트는 복구 할 수 없습니다.
                </ModalCard>
              </div>
            </ModalBox>
          </>
        )}
      </AdminContainer>
    </>
  )
}

export default AdminNoticePage
