import { keyframes } from 'styled-components'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

const slideLeftOpen = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
`

const slideRightClose = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
`

const popInFromBottom = keyframes`
  0% {
    opacity: 0;
    transform: translateY(400px) scale(0.75);
  }
  75% {
    opacity: 1;
    transform: translateY(-16px) scale(1.0);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }`

const popOutToBottom = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0px) scale(1.0);
  }
  100% {
    opacity: 0;
    transform: translateY(400px) scale(0.75);
  }`

const leftSlideShow = keyframes`
  0%{
    opacity: 0;
    transform: translateX(-50px) scale(0.75);
  }
  
  100%{
    opacity: 1;
    transform: translateX(0);
  }
`

const rightSlideHide = keyframes`
  0%{
    opacity: 1;
    transform: translateX(0);
  }
  
  100%{
    opacity: 0;
    transform: translateX(50px) scale(0.75);
  }
`

const moveOpacity = keyframes`
  0%{
    opacity: 1;
    transform: translateX(0);
  }
  50%{
    opacity: 0;
    transform: translateX(5px);
  }
  100%{
    opacity: 1;
    transform: translateX(0);
  }
`

const moveOpacity2 = keyframes`
  0%{
    opacity: 1;
    transform: translateY(0);
  }
  //25%{
  //  opacity: 0;
  //  transform: translateY(5px);
  //}
  50%{
    opacity: 0;
    transform: translateY(3px);
  }
  //75%{
  //  opacity: 1;
  //  transform: translateY(0);
  //}
  100%{
    opacity: 1;
    transform: translateY(0);
  }
`

const InfiniteLineMove = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-33.33%, 0, 0);
  }
`

const infiniteLeftRotate = keyframes`
  0%{
    transform: translate(-50%, -50%) rotate(0);
  }
  
  100%{
    transform: translate(-50%, -50%) rotate(365deg);
  }
`

const infiniteRightRotate = keyframes`
  0%{
    transform: translate(50%, 50%) rotate(0);
  }
  
  100%{
    transform: translate(50%, 50%) rotate(365deg);
  }
`

const mouseScroll = keyframes`
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
`

const heartbeat = keyframes`
  from {
    
    transform:  translate(-50%, -50%) ;
    animation-timing-function: ease-out;
  }
  //10% {
  //  transform: translate(-50%, -50%) ;
  //  animation-timing-function: ease-in;
  //}
  //17% {
  //  transform: translate(-50%, -50%) ;
  //  animation-timing-function: ease-out;
  //}
  //33% {
  //  transform: translate(-50%, -50%) ;
  //  animation-timing-function: ease-in;
  //}
  //45% {
  //  transform: translate(-50%, -50%) ;
  //  animation-timing-function: ease-out;
  //}
  to{
    transform: translate(-50%, -50%) ;
  }
`

const transitions = {
  fadeIn,
  fadeOut,
  slideLeftOpen,
  slideRightClose,
  popInFromBottom,
  popOutToBottom,
  leftSlideShow,
  rightSlideHide,
  moveOpacity,
  InfiniteLineMove,
  infiniteLeftRotate,
  infiniteRightRotate,
  mouseScroll,
  heartbeat,
  moveOpacity2,
}

export default transitions
