import styled from 'styled-components'

export const FormBox = styled.div`
  padding: 2rem;
  box-sizing: border-box;
  background-color: #f4b81c;
  border-radius: 20px;
  box-shadow: 30px 30px 20px rgba(0, 0, 0, 0.24);

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
  }

  .center {
    margin-top: 2rem;
  }

  .bottom {
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .input_box {
    flex: 1;
  }

  label {
    color: #fff;
    font-size: 16px;
    display: block;
    margin-bottom: 10px;
  }
  input {
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #fff;
    border: none;
    outline: none;
    display: block;
    width: 100%;
  }
  textarea {
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #fff;
    border: none;
    outline: none;
    display: block;
    width: 100%;
    height: 350px;
  }

  button {
    display: block;
    height: 60px;
    box-sizing: border-box;
    color: #fff;
    border: 4px solid #fff;
    border-radius: 30px;
    background-color: transparent;
    width: 200px;
    cursor: pointer;
    font-size: 18px;
  }
`
