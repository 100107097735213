import styled from 'styled-components'

export const Container = styled.div`
  background-color: #9fb5b1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100%;
  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;

    .img {
      width: 50%;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: auto;
      }
    }

    .text {
      width: 50%;
      text-align: center;
      color: #192a32;
      line-height: 1.5;
      padding-bottom: 1rem;

      h2 {
        font-size: 72px;
        font-weight: 900;
        font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI',
          'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
      }
      p {
        font-size: 55px;
        font-weight: 400;
        font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI',
          'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
      }
      p.small {
        font-size: 24px;
        font-weight: 300;
        font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI',
          'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
        line-height: 45px;
        margin: 10px 0;
      }

      .button {
        margin-top: 40px;
        a {
          padding: 10px 30px;
          border: 2px solid #192a32;
          border-radius: 25px;
          width: 250px;
          /* height: 70px; */
          font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue', 'Segoe UI',
            'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', sans-serif;
          font-size: 20px;
          color: #192a32;
          transition: 0.3s;
          box-sizing: border-box;
          display: inline-block;
        }
        a:hover {
          background: #ffffff59;
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .inner {
      .text {
        h2 {
          font-size: 60px;
        }
        p {
          font-size: 42px;
        }
        p.small {
          font-size: 20px;
          line-height: 35px;
        }
      }
    }
  }
  @media screen and (max-width: 900px) {
    .inner {
      flex-direction: column;

      .img {
        width: 100%;
        padding: 0;
        max-width: 500px;

        img {
        }
      }
      .text {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 500px) {
    .inner {
      .img {
        img {
          width: 100%;
        }
      }
      .text {
        padding: 10px;
        box-sizing: border-box;
        h2 {
          font-size: 40px;
        }
        p {
          font-size: 27px;
        }
        p.small {
          font-size: 15px;
          line-height: 25px;
        }
      }
    }
  }
`
