import React from 'react'
import { CardModalContainer } from './styles'
import Button from '../Button'

const ModalCard = ({ closeEvent, title, children, actionMsg, actionEvent, loading }) => {
  return (
    <>
      <CardModalContainer>
        <h3>{title}</h3>
        <div className="msg">{children}</div>
        <div className="action_box">
          <Button onClick={closeEvent} theme="tertiary">
            닫기
          </Button>
          <Button theme="secondary" onClick={actionEvent} loading={loading}>
            {actionMsg}
          </Button>
        </div>
      </CardModalContainer>
    </>
  )
}

export default ModalCard
