import React from 'react'
import { Container } from './styles'
import { Link } from 'react-router-dom'

import img from '../../assets/images/404.png'

const NotPage = () => {
  return (
    <>
      <Container>
        <div className="inner">
          <div className="img">
            <img src={img} alt="" />
          </div>
          <div className="text">
            <h2>404 ERROR</h2>
            <p>페이지를 찾을 수 없습니다.</p>
            <p className="small">
              페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다. <br />
              입력하신 주소가 정확한지 다시 한 번 확인해주세요.
            </p>
            <div className="button">
              <Link to="/">홈페이지 메인</Link>
            </div>
          </div>
        </div>
        <a href="https://storyset.com/online" style={{ display: 'none' }}>
          Online illustrations by Storyset
        </a>
      </Container>
    </>
  )
}

export default NotPage
