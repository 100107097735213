import React from 'react'
import Header from '../../components/Header'
import { TestBox } from './styles'

const TestPage = () => {
  return (
    <>
      <Header />
      <div>TestPage</div>
      <TestBox>깃연동확인2</TestBox>
    </>
  )
}

export default TestPage
