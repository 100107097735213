import styled from 'styled-components'

export const List = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title_btn {
    font-size: 16px;
    font-weight: 600;
  }
  & + li {
    margin-top: 1rem;
  }
  .option_box {
    button + button {
      margin-left: 1rem;
    }
  }
`
