import React, { useEffect, useRef, useState } from 'react'
import {
  Container,
  FooterBox,
  ImageParallaxBox,
  ObjectBox,
  ParallaxBox,
  ParticlesBox,
  TextParallaxBox,
  TypewriterBox,
  VideoBox,
} from './styles'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Particles from 'react-tsparticles'

import { useSpring, animated } from '@react-spring/web'
import configParticles from '../../configParticles'
import Typewriter from 'typewriter-effect'
import { CircularProgressbar, buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

import ob_video from '../../assets/images/ob_video.mp4'
import img1 from '../../assets/images/msbenter.jpg'
import img2 from '../../assets/images/database.jpg'
import img3 from '../../assets/images/earth.jpg'

import object_img1 from '../../assets/images/object_img1.gif'
import object_img2 from '../../assets/images/object_img2.gif'
import object_video1 from '../../assets/images/object_video1.mp4'
import object_video2 from '../../assets/images/object_video2.mp4'
import object_video3 from '../../assets/images/object_video3.mp4'

import object_circle from '../../assets/images/object_circle.svg'
import object_circle2 from '../../assets/images/object_circle2.svg'
import { ErrorMessageOpen, SuccessMessageOpen } from '../../hooks/toast'
import { doc, setDoc } from 'firebase/firestore'
import { db } from '../../firebase'
import emailjs from 'emailjs-com'

const ObjectSolutionPage = () => {
  let navigate = useNavigate()
  const [scrollStart, setScrollStart] = useState(true)
  const [parallaxState, setParallaxState] = useState(0)
  const [imageParallaxState, setImageParallaxState] = useState(100)
  const [imageParallaxState2, setImageParallaxState2] = useState(100)
  const [imageParallaxState3, setImageParallaxState3] = useState(100)
  const [content2ParallaxState, setContent2ParallaxState] = useState(0)
  const [windowMode, setWindowMode] = useState(null)

  const footerBox = useRef(null)
  const [footerBoxH, setFooterBoxH] = useState(0)
  const [lastSection, setLastSection] = useState(false)

  const [touchstartY, setTouchstartY] = useState(0)
  const [touchendY, setTouchendY] = useState(0)

  const videoRef = useRef(null)
  const objectRef = useRef(null)
  const [objectActive, setObjectActive] = useState(false)

  const objectContent1Ref = useRef(null)
  const [objectContent1Active, setObjectContent1Active] = useState(false)

  const objectContent2Ref = useRef(null)
  const [objectContent2Active, setObjectContent2Active] = useState(false)

  const objectContent3Ref = useRef(null)
  const [objectContent3Active, setObjectContent3Active] = useState(false)

  const progressRef = useRef(null)
  const [progressNumbers, setProgressNumbers] = useState({
    one: 0,
    two: 0,
    three: 0,
  })
  const [progressActive, setProgressActive] = useState(false)
  const progressNumber1 = useSpring({ width: progressActive ? 98 : 0 })
  const progressNumber2 = useSpring({ width: progressActive ? 94 : 0 })
  const progressNumber3 = useSpring({ width: progressActive ? 92 : 0 })

  const [beforePosition, setBeforePosition] = useState(document.documentElement.scrollTop)

  const particlesRef = useRef(null)
  const [particlesStart, setParticlesStart] = useState(false)
  const [winW, setWinW] = useState(document.body.clientWidth)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    resizeEvent()
  }, [])

  useEffect(() => {
    function onResize() {
      resizeEvent()
    }
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const resizeEvent = () => {
    setWinW(document.body.clientWidth)
    if (document.body.clientWidth > 1000) {
      setParticlesStart(false)
    } else {
      setParticlesStart(true)
    }
  }

  const onClickVideoScroll = () => {
    setScrollStart(false)
    window.scrollTo({
      behavior: 'smooth',
      top: particlesRef.current.offsetTop,
      left: 0,
    })
    setParticlesStart(true)
  }

  useEffect(() => {
    function onScroll() {
      const afterPosition = document.documentElement.scrollTop
      if (afterPosition > 50) {
        if (beforePosition < afterPosition) {
          // 스크롤 아래로

          if (scrollStart && winW > 1000) {
            setScrollStart(false)
            window.scrollTo({
              behavior: 'smooth',
              top: particlesRef.current.offsetTop,
              left: 0,
            })
            setParticlesStart(true)
          }

          if (
            objectRef &&
            objectRef.current &&
            objectRef.current.getBoundingClientRect().top < document.body.clientHeight * 0.7
          ) {
            setObjectActive(true)
          }

          if (
            objectContent1Ref &&
            objectContent1Ref.current &&
            objectContent1Ref.current.getBoundingClientRect().top < document.body.clientHeight * 0.5
          ) {
            setObjectContent1Active(true)
          }

          if (
            objectContent2Ref &&
            objectContent2Ref.current &&
            objectContent2Ref.current.getBoundingClientRect().top < document.body.clientHeight * 0.5
          ) {
            setObjectContent2Active(true)
          }

          if (
            objectContent3Ref &&
            objectContent3Ref.current &&
            objectContent3Ref.current.getBoundingClientRect().top < document.body.clientHeight * 0.5
          ) {
            setObjectContent3Active(true)
          }

          if (
            progressRef &&
            progressRef.current &&
            progressRef.current.getBoundingClientRect().top < document.body.clientHeight * 0.7
          ) {
            setProgressActive(true)
            setProgressNumbers({
              one: 98,
              two: 94,
              three: 92,
            })
          }
        } else {
          // 스크롤 위로
          // console.log('스크롤 위로')
        }
      } else {
        // 평상 시
      }
      setBeforePosition(afterPosition)
    }
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [scrollStart, winW])

  // 폼 관련 코드
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    content: '',
  })

  const onChangeName = e => {
    setFormData({
      ...formData,
      name: e.target.value,
    })
  }

  const onChangePhone = e => {
    setFormData({
      ...formData,
      phone: e.target.value,
    })
  }

  const onChangeContent = e => {
    setFormData({
      ...formData,
      content: e.target.value,
    })
  }

  const onSubmit = async e => {
    e.preventDefault()

    if (formData.name.trim().length === 0) {
      ErrorMessageOpen('이름을 입력해주세요.')
      return
    }

    if (formData.phone.trim().length === 0) {
      ErrorMessageOpen('연락처를 입력해주세요.')
      return
    }

    if (formData.content.trim().length === 0) {
      ErrorMessageOpen('내용을 입력해주세요.')

      return
    }

    try {
      const today = new Date()

      let dd = today.getDate()
      let mm = today.getMonth() + 1 //January is 0!
      const yyyy = today.getFullYear()

      const createdAt = today
      const _name = yyyy + mm + dd
      const RandomNumber = Math.random().toString(36).substr(2, 11)
      const id = `notice${RandomNumber}${_name}`
      await setDoc(doc(db, 'contact_us', id), { ...formData, createdAt, id, state: false, person: '' })
      SuccessMessageOpen('문의사항 접수가 완료 되었습니다.')
      await mainSend(e)
      setFormData({
        name: '',
        phone: '',
        content: '',
      })
    } catch (e) {
      console.error('Error adding document: ', e)
    }
  }

  const mainSend = async e => {
    // eslint-disable-next-line import/no-named-as-default-member
    emailjs.sendForm('service_uulzi0j', 'template_7oed12t', e.target, 'user_gYRAcKdcebSp8Ik0wfTM2').then(
      result => {
        console.log(result.text)
      },
      error => {
        console.log(error.text)
      },
    )
  }

  const particlesInit = main => {
    console.log(main)

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  }
  const particlesLoaded = container => {
    console.log(container)
  }

  // useEffect(() => {
  //   resizeEvent()
  // }, [])
  //
  // useEffect(() => {
  //   function onResize() {
  //     resizeEvent()
  //   }
  //   window.addEventListener('resize', onResize)
  //   return () => {
  //     window.removeEventListener('resize', onResize)
  //   }
  // }, [])

  // const resizeEvent = () => {
  //   const winW = document.body.clientWidth
  //   setTimeout(() => {
  //     const footerH = footerBox.current.offsetHeight
  //     setFooterBoxH(footerH)
  //     if (videoRef && videoRef.current) {
  //       videoRef.current.play()
  //     }
  //   }, 300)
  //
  //   if (winW > 1000) {
  //     setWindowMode('pc')
  //   } else {
  //     setWindowMode('mobile')
  //   }
  // }

  useEffect(() => {
    // sectionTopCheck()
  }, [])

  // useEffect(() => {
  //   function onScroll(e) {
  //     if (e.deltaY > 0) {
  //       scrollEvent(1)
  //     } else {
  //       scrollEvent(-1)
  //     }
  //   }
  //   window.addEventListener('mousewheel', onScroll)
  //   return () => {
  //     window.removeEventListener('mousewheel', onScroll)
  //   }
  // }, [scrollStart])

  // useEffect(() => {
  //   function onScroll(e) {
  //     setTouchstartY(e.changedTouches[0].pageY)
  //   }
  //   window.addEventListener('touchstart', onScroll)
  //   return () => {
  //     window.removeEventListener('touchstart', onScroll)
  //   }
  // }, [])

  // useEffect(() => {
  //   function onScroll(e) {
  //     const pageY = e.changedTouches[0].pageY
  //     setTouchendY(touchstartY - pageY)
  //   }
  //   window.addEventListener('touchend', onScroll)
  //   return () => {
  //     window.removeEventListener('touchend', onScroll)
  //   }
  // }, [touchstartY])

  // useEffect(() => {
  //   if (touchendY > 0) {
  //     scrollEvent(1)
  //   } else {
  //     scrollEvent(-1)
  //   }
  // }, [touchendY])

  const scrollEvent = num => {
    if (scrollStart) {
      setScrollStart(false)
      if (parallaxState === 0 && num === -1) {
        setTimeout(() => {
          setScrollStart(true)
        }, 1000)
        return
      }
      if (parallaxState < 2 || (num === -1 && imageParallaxState === 100)) {
        setParallaxState(parallaxState + num)
      }

      if (parallaxState === 2 && num === 1 && imageParallaxState2 === 100) {
        setImageParallaxState(0)
      } else if (parallaxState === 2 && num === -1 && imageParallaxState2 === 100) {
        setImageParallaxState(100)
      }

      if (parallaxState === 2 && num === 1 && imageParallaxState === 0 && imageParallaxState3 === 100) {
        setImageParallaxState2(0)
      } else if (parallaxState === 2 && num === -1 && imageParallaxState === 0 && imageParallaxState3 === 100) {
        setImageParallaxState2(100)
      }

      if (parallaxState === 2 && num === 1 && imageParallaxState2 === 0) {
        setImageParallaxState3(0)
      } else if (parallaxState === 2 && num === -1 && imageParallaxState2 === 0 && content2ParallaxState === 0) {
        setImageParallaxState3(100)
      }

      if (parallaxState === 2 && num === 1 && content2ParallaxState === 0 && imageParallaxState3 === 0) {
        setContent2ParallaxState(-footerBoxH)
        setLastSection(true)
      } else if (parallaxState === 2 && num === -1 && lastSection && imageParallaxState3 === 0) {
        setContent2ParallaxState(0)
      }

      setTimeout(() => {
        setScrollStart(true)
      }, 1000)
    }
  }

  return (
    <>
      <Container>
        <Header />
        {winW > 1000 && (
          <VideoBox>
            <video autoPlay loop playsInline muted ref={videoRef}>
              <source src={ob_video} type="video/mp4" />
            </video>
            <div className="mouse_box">
              <button type="button" className="mouse" onClick={onClickVideoScroll}>
                <span></span>
                <span></span>
                <span></span>Scroll
              </button>
            </div>
          </VideoBox>
        )}

        <ParticlesBox ref={particlesRef}>
          <TypewriterBox>
            {particlesStart && (
              <Typewriter
                options={{
                  strings: [
                    'Object Solution',
                    '세상이 변화하면 시스템도 <br />\n' + ' 끊임없이 진화해야 합니다',
                    '진화를 선도하는 기업 <br />\n' + '(주) 엠에스벤터',
                  ],
                  autoStart: true,
                  loop: true,
                  deleteSpeed: 20,
                }}
              />
            )}
          </TypewriterBox>
          <Particles id="tsparticles" options={configParticles} />
        </ParticlesBox>
        <ObjectBox ref={objectRef} className={`${objectActive ? 'active' : ''}`}>
          <div className="object_container">
            <div className="center_line white_line mobile_remove center_active" />
            <div className="left_line white_line mobile_remove left_active" />
            <div className="right_line white_line mobile_remove right_active" />

            <div className="item_container" ref={objectContent1Ref}>
              <div className="top_line white_line top_active" />
              <div className="item_box">
                <div className="item_content">
                  <div className="object item">
                    <div className="object_image_box">
                      <img src={object_img1} alt="" />
                    </div>
                  </div>
                  <div className="text_box item">
                    <div className={`${objectContent1Active ? 'active' : ''} text_content`}>
                      <div className="top_line white_line" />
                      <div className="bottom_line white_line" />

                      <div className="title">
                        NO.<span>1</span>
                      </div>
                      <div className="sub_title">Korea Franchise - Make Up</div>
                      <p>공학적으로 설계된</p>
                      <p>거대 DATABASE로</p>
                      <p>한국형 프랜차이즈를</p>
                      <p>메이크업 하세요</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="item_container" ref={objectContent2Ref}>
              <div className="top_line white_line mobile_remove" />
              <div className="item_box">
                <div className="item_content">
                  <div className="text_box item">
                    <div className={`${objectContent2Active ? 'active' : ''} text_content`}>
                      <div className="top_line white_line" />
                      <div className="bottom_line white_line" />

                      <div className="title">
                        NO.<span>2</span>
                      </div>
                      <div className="sub_title">Hyper - Connected</div>
                      <p>플랫폼과 플랫폼을 연결하고</p>
                      <p>회사와 회사를 연결하는</p>
                      <p>거대 DATABASE에서</p>
                      <p>초연결 서비스를 제공합니다</p>
                    </div>
                  </div>
                  <div className="object item">
                    <div className="object_image_box">
                      <video autoPlay loop playsInline muted>
                        <source src={object_video2} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="item_container" ref={objectContent3Ref}>
              <div className="top_line white_line mobile_remove" />
              <div className="item_box">
                <div className="item_content">
                  <div className="object item">
                    <div className="object_image_box">
                      <video autoPlay loop playsInline muted>
                        <source src={object_video3} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                  <div className="text_box item">
                    <div className={`${objectContent3Active ? 'active' : ''} text_content`}>
                      <div className="top_line white_line" />
                      <div className="bottom_line white_line" />

                      <div className="title">
                        NO.<span>3</span>
                      </div>
                      <div className="sub_title">Infinite Expansion</div>
                      <p>무한 DATABASE확장이</p>
                      <p>귀사의 비즈니스를</p>
                      <p>돋보이게 해드립니다</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="item_container text_line">
            <div className="top_line white_line" />
            <div className="move_text_box">
              <div className="marquee">
                <p>MSBENTER’S OBJECT SOLUTION IS THE BEST IN THE WORLD</p>
                <p>MSBENTER’S OBJECT SOLUTION IS THE BEST IN THE WORLD</p>
                <p>MSBENTER’S OBJECT SOLUTION IS THE BEST IN THE WORLD</p>
              </div>
            </div>
          </div>
          <div className="item_container progress_title">
            <div className="top_line white_line" />
            Introduction Effect
          </div>
          <div className="item_container progress_container" ref={progressRef}>
            <div className="top_line white_line" />
            <div className="bottom_line white_line" />

            <div className="progress_box">
              <div className="progress_item">
                <CircularProgressbarWithChildren
                  maxValue={100}
                  value={progressNumbers.one}
                  strokeWidth={4}
                  styles={buildStyles({
                    rotation: 1,
                    pathTransitionDuration: 1,
                    pathColor: `#FFF`,
                    textColor: '#000',
                    trailColor: '#000',
                    backgroundColor: '#000',
                  })}
                >
                  <div className="progress_txt">
                    <animated.span>{progressNumber1.width.to(x => x.toFixed(0))}</animated.span>%
                  </div>
                </CircularProgressbarWithChildren>
                <div className="txt">주문금액</div>
              </div>
              <div className="progress_item">
                <CircularProgressbarWithChildren
                  maxValue={100}
                  value={progressNumbers.two}
                  strokeWidth={4}
                  styles={buildStyles({
                    rotation: 1,
                    pathTransitionDuration: 1.5,
                    pathColor: `#FFF`,
                    textColor: '#000',
                    trailColor: '#000',
                    backgroundColor: '#000',
                  })}
                >
                  <div className="progress_txt">
                    <animated.span>{progressNumber2.width.to(x => x.toFixed(0))}</animated.span>%
                  </div>
                </CircularProgressbarWithChildren>
                <div className="txt">주문건수</div>
              </div>
              <div className="progress_item">
                <CircularProgressbarWithChildren
                  maxValue={100}
                  value={progressNumbers.three}
                  strokeWidth={4}
                  styles={buildStyles({
                    rotation: 1,
                    pathTransitionDuration: 2,
                    pathColor: `#FFF`,
                    textColor: '#000',
                    trailColor: '#000',
                    backgroundColor: '#000',
                  })}
                >
                  <div className="progress_txt">
                    <animated.span>{progressNumber3.width.to(x => x.toFixed(0))}</animated.span>%
                  </div>
                </CircularProgressbarWithChildren>
                <div className="txt">주문업체</div>
              </div>
            </div>
          </div>
          <div className="item_container contact_title">CONTACT US</div>
          <div className="item_container from_box">
            <img src={object_circle2} alt="" className="circle left" />
            <img src={object_circle} alt="" className="circle right" />
            <div className="top_line white_line" />
            <div className="item_box form_content">
              <div className="center_line white_line mobile_remove" />
              <div className="item_content">
                <div className="object item">
                  <div className="mail_text">
                    <a href="mailto:msb@benter.co.kr">msb@benter.co.kr</a>
                  </div>
                </div>
                <div className="text_box item">
                  <div className="form_item">
                    <form onSubmit={onSubmit}>
                      <div className="input_box">
                        <input
                          type="text"
                          placeholder="name"
                          value={formData.name}
                          onChange={onChangeName}
                          maxLength="20"
                        />
                      </div>
                      <div className="input_box">
                        <input
                          type="text"
                          placeholder="phone"
                          value={formData.phone}
                          onChange={onChangePhone}
                          maxLength="20"
                        />
                      </div>
                      <div className="input_box">
                        <textarea
                          placeholder="text"
                          value={formData.content}
                          onChange={onChangeContent}
                          maxLength="1000"
                        />
                      </div>
                      <div className="button_box">
                        <button type="submit">Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ObjectBox>
        <Footer />
        {/*<ParallaxBox>*/}
        {/*  <div*/}
        {/*    className="content"*/}
        {/*    style={{*/}
        {/*      transform: `translateY(${parallaxState * (windowMode === 'pc' ? '-25' : '-33.33')}%)`,*/}
        {/*      height: `${windowMode === 'pc' ? '400%' : '300%'}`,*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    {windowMode === 'pc' && (*/}
        {/*      <>*/}
        {/*        <TextParallaxBox style={{ height: `${windowMode === 'pc' ? '25%' : '33.33%'}` }}>*/}
        {/*          <video autoPlay loop playsInline muted ref={videoRef}>*/}
        {/*            <source src={ob_video} type="video/mp4" />*/}
        {/*          </video>*/}
        {/*        </TextParallaxBox>*/}
        {/*      </>*/}
        {/*    )}*/}

        {/*    <TextParallaxBox style={{ height: `${windowMode === 'pc' ? '25%' : '33.33%'}` }}>*/}
        {/*      <p className="playfair_display">*/}
        {/*       */}
        {/*      </p>*/}
        {/*    </TextParallaxBox>*/}
        {/*    <TextParallaxBox style={{ height: `${windowMode === 'pc' ? '25%' : '33.33%'}` }}>*/}
        {/*      <p className="noto_sans">*/}
        {/*        세상이 변화하면 시스템도 <br />*/}
        {/*        끊임없이 진화해야 합니다*/}
        {/*      </p>*/}
        {/*    </TextParallaxBox>*/}
        {/*    <TextParallaxBox style={{ height: `${windowMode === 'pc' ? '25%' : '33.33%'}` }}>*/}
        {/*      <p className="noto_sans">*/}
        {/*        진화를 선도하는 기업 <br />*/}
        {/*        (주) 엠에스벤터*/}
        {/*      </p>*/}
        {/*    </TextParallaxBox>*/}
        {/*  </div>*/}
        {/*  <div className="content2" style={{ transform: `translateY(${content2ParallaxState}px)` }}>*/}
        {/*    <ImageParallaxBox style={{ transform: `translateY(${imageParallaxState}%)` }}>*/}
        {/*      <img src={img2} alt="" />*/}
        {/*      <p className="noto_sans">*/}
        {/*        공학적으로 설계된 <br />*/}
        {/*        거대 DataBase로 <br />*/}
        {/*        한국형 프랜차이즈를 <br />*/}
        {/*        메이크업하세요*/}
        {/*      </p>*/}
        {/*    </ImageParallaxBox>*/}
        {/*    <ImageParallaxBox style={{ transform: `translateY(${imageParallaxState2}%)` }}>*/}
        {/*      <img src={img1} alt="" />*/}
        {/*      <p className="noto_sans">*/}
        {/*        플랫폼과 플랫폼을 연결하고 <br />*/}
        {/*        회사와 회사를 연결하는 <br />*/}
        {/*        거대 DataBase에서 <br />*/}
        {/*        초연결 서비스를 제공합니다*/}
        {/*      </p>*/}
        {/*    </ImageParallaxBox>*/}
        {/*    <ImageParallaxBox style={{ transform: `translateY(${imageParallaxState3}%)` }}>*/}
        {/*      <img src={img3} alt="" />*/}
        {/*      <p className="noto_sans auto" style={{ marginTop: `${windowMode === 'pc' ? '0' : 'auto'}` }}>*/}
        {/*        무한 DataBase 확장이 <br />*/}
        {/*        귀사의 비즈니스를 <br />*/}
        {/*        돋보이게 해드립니다*/}
        {/*      </p>*/}
        {/*      {windowMode === 'mobile' && (*/}
        {/*        <button type="button" onClick={() => navigate('/contact')}>*/}
        {/*          문의하기 <span className="material-icons-outlined">keyboard_double_arrow_right</span>*/}
        {/*        </button>*/}
        {/*      )}*/}
        {/*    </ImageParallaxBox>*/}
        {/*    <FooterBox*/}
        {/*      ref={footerBox}*/}
        {/*      style={{*/}
        {/*        transform: `translateY(${footerBoxH}px)`,*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      {windowMode === 'pc' && (*/}
        {/*        <div className="link_move_box">*/}
        {/*          <div className="text_box">*/}
        {/*            가장 완벽한 한국형 프랜차이즈의 완성 <br /> 엠에스벤터와 함께하세요*/}
        {/*          </div>*/}
        {/*          <button type="button" onClick={() => navigate('/contact')}>*/}
        {/*            문의하기 <span className="material-icons-outlined">keyboard_double_arrow_right</span>*/}
        {/*          </button>*/}
        {/*        </div>*/}
        {/*      )}*/}

        {/*      <Footer />*/}
        {/*    </FooterBox>*/}
        {/*  </div>*/}
        {/*</ParallaxBox>*/}
      </Container>
    </>
  )
}

export default ObjectSolutionPage
