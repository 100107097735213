import React, { useState, useEffect } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import QuickMenuBox from '../../components/QuickMenuBox'
import { Container } from './styles'
import { ErrorMessageOpen, SuccessMessageOpen } from '../../hooks/toast'
import { addDoc, collection, doc, setDoc } from 'firebase/firestore'
import { db } from '../../firebase'
import { Link } from 'react-router-dom'
import QuickSideMenu from '../../components/QuickSideMenu'
import axios from 'axios'

const ContactPage = () => {
  // 시작하자 마자 스크롤 위로 올리기
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // 셀렉트 기본 값 설정
  const [selectCheck, setSelectCheck] = useState(1)

  // 셀렉트 버튼 눌렀을 때 값 변경 함수
  const onClickSelect = number => {
    setSelectCheck(number)
  }

  const [company, setCompany] = useState('')

  const onChangeCompany = e => {
    setCompany(e.target.value)
  }

  const [name, setName] = useState('')

  const onChangeName = e => {
    setName(e.target.value)
  }

  const [grade, setGrade] = useState('')

  const onChangeGrade = e => {
    setGrade(e.target.value)
  }

  const [phone, setNumber] = useState('')

  const onChangeNumber = e => {
    setNumber(e.target.value)
  }

  const [email, setEmail] = useState('')

  const onChangeEmail = e => {
    setEmail(e.target.value)
  }

  const [channel, setChannel] = useState('')

  const onChangeChannel = e => {
    setChannel(e.target.value)
  }

  const [content, setText] = useState('')

  const onChangeText = e => {
    setText(e.target.value)
  }

  const [privacy, setPrivacy] = useState(false)

  const onChangePrivacy = e => {
    setPrivacy(!privacy)
  }

  // 개인정보 처리방침 팝업
  const onClickWindowPopup = e => {
    e.preventDefault()
    const options = 'top=10, left=10, width=600, height=800, status=no, menubar=no, toolbar=no, resizable=no'
    window.open('/privacy', 'popup', options)
  }

  // 상담 내용 전송하는 이벤트
  const onSubmit = async e => {
    e.preventDefault()
    if (company.trim().length === 0) {
      ErrorMessageOpen('회사명을 입력해 주세요.')
      return
    }
    if (name.trim().length === 0) {
      ErrorMessageOpen('이름을 입력해 주세요.')
      return
    }
    if (grade.trim().length === 0) {
      ErrorMessageOpen('직급을 입력해 주세요.')
      return
    }
    if (phone.trim().length === 0) {
      ErrorMessageOpen('연락처를 입력해 주세요.')
      return
    }
    const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/
    if (!regPhone.test(phone.trim())) {
      ErrorMessageOpen('올바른 연락처를 입력해주세요.')
      return
    }
    if (!privacy) {
      ErrorMessageOpen('개인정보 처리방침을 체크해 주세요.')
      return
    }
    if (content.trim().length === 0) {
      ErrorMessageOpen('문의사항을 입력해 주세요.')
      return
    }

    const today = new Date()

    let dd = today.getDate()
    let mm = today.getMonth() + 1 //January is 0!
    const yyyy = today.getFullYear()
    let hh = today.getHours()
    let mi = today.getMinutes()

    if (mm < 10) {
      mm = '0' + mm
    }
    if (dd < 10) {
      dd = '0' + dd
    }
    if (hh < 10) {
      hh = '0' + hh
    }
    if (mi < 10) {
      mi = '0' + mi
    }

    const createdAt = `${yyyy}-${mm}-${dd} ${hh}:${mi}`
    const _name = yyyy + mm + dd
    const RandomNumber = Math.random().toString(36).substr(2, 11)
    const id = `notice${RandomNumber}${_name}`

    const data = {
      selectCheck,
      company,
      name,
      grade,
      phone,
      email,
      channel,
      content,
      createdAt,
      id,
      state: false,
      person: '',
    }

    try {
      await axios.post('/api/customer/homepage', {
        selectCheck: data.selectCheck,
        customer_name: data.name,
        customer_phone: data.phone,
        customer_company: data.company,
        inquiry_desc: data.content,
        inquiry_type: 26,
        received_date: data.createdAt,
        customer_position: data.grade,
        inflow: data.channel,
        email: data.email,
      })
      await axios.post('https://socket.relayfms.com/contact')
      SuccessMessageOpen('문의사항 접수가 완료 되었습니다.')
      setSelectCheck(1)
      setCompany('')
      setName('')
      setGrade('')
      setGrade('')
      setNumber('')
      setEmail('')
      setChannel('')
      setText('')
      setPrivacy(!privacy)
    } catch (e) {
      console.error('Error adding document: ', e)
    }
  }

  return (
    <>
      <Container>
        <Header />
        <QuickSideMenu />
        <div className="container">
          <div className="contact">
            <h2>상담센터</h2>
            <div className="tap_menu">
              <ul>
                <li>
                  <button
                    type="button"
                    className={`${selectCheck === 1 ? 'active' : ''}`}
                    onClick={() => onClickSelect(1)}
                  >
                    설치문의
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={`${selectCheck === 2 ? 'active' : ''}`}
                    onClick={() => onClickSelect(2)}
                  >
                    교육문의
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={`${selectCheck === 3 ? 'active' : ''}`}
                    onClick={() => onClickSelect(3)}
                  >
                    도입문의
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    className={`${selectCheck === 4 ? 'active' : ''}`}
                    onClick={() => onClickSelect(4)}
                  >
                    가격문의
                  </button>
                </li>
              </ul>
            </div>
            <form onSubmit={onSubmit}>
              <ul>
                <li>
                  <label>
                    회사명 <span>*</span>
                  </label>
                  <input
                    placeholder="회사명을 입력해주세요."
                    value={company}
                    onChange={onChangeCompany}
                    maxLength="100"
                  />
                </li>
                <li>
                  <label>
                    이름 <span>*</span>
                  </label>
                  <input placeholder="담당자명을 입력해주세요." value={name} onChange={onChangeName} maxLength="20" />
                </li>
                <li>
                  <label>
                    직급 <span>*</span>
                  </label>
                  <input placeholder="직급을 입력해주세요." value={grade} onChange={onChangeGrade} maxLength="20" />
                </li>
              </ul>
              <ul>
                <li>
                  <label>
                    연락처 <span>*</span>
                  </label>
                  <input placeholder="연락처를 입력해주세요." value={phone} onChange={onChangeNumber} maxLength="20" />
                </li>
                <li>
                  <label>이메일</label>
                  <input placeholder="이메일을 입력해주세요." value={email} onChange={onChangeEmail} maxLength="20" />
                </li>
                <li>
                  <label>유입경로</label>
                  <input
                    placeholder="유입경로를 입력해주세요."
                    value={channel}
                    onChange={onChangeChannel}
                    maxLength="100"
                  />
                </li>
              </ul>
              <div className="check_box">
                <Link to="/privacy" onClick={onClickWindowPopup}>
                  개인정보처리방침
                </Link>
                <input type="checkbox" id="check1" checked={privacy} onChange={onChangePrivacy} />
                <label htmlFor="check1" />
              </div>
              <div className="text_area">
                <label>
                  문의사항을 입력해주세요 <span>*</span>
                </label>
                <textarea
                  placeholder="궁금하신 점을 자유롭게 적어주세요. 담당자가 개별적으로 연락드리겠습니다."
                  value={content}
                  onChange={onChangeText}
                  maxLength="1000"
                />
              </div>
              <div className="submit">
                <input value={'제출하기'} type={'submit'} />
              </div>
            </form>
          </div>
        </div>

        <Footer />
      </Container>
    </>
  )
}

export default ContactPage
