import React from 'react'
import { AuthBox } from './styles'

const AuthContainer = ({ children }) => {
  return (
    <>
      <AuthBox>{children}</AuthBox>
    </>
  )
}

export default AuthContainer
