import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 70px;
  box-sizing: border-box;
`

export const TopBannerBox = styled.div`
  width: 100%;
  height: 600px;
  position: relative;
  padding: 0 20px;
  box-sizing: border-box;
  .img_box {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .text_box {
    position: relative;
    z-index: 10;
    width: 100%;
    height: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    line-height: 1.6;
    font-size: 40px;
    font-weight: 600;
  }

  @media screen and (max-width: 1000px) {
    .text_box {
      font-size: 24px;
      text-align: center;
    }
  }
`

export const TextBox = styled.div`
  width: 100%;
  padding: 80px 20px 0;
  box-sizing: border-box;
  .max_box {
    max-width: 1200px;
    margin: 0 auto;
  }
  .text_content {
    margin-bottom: 180px;
    h3 {
      font-size: 30px;
      margin-bottom: 20px;
      color: #222;
    }
    p {
      line-height: 2;
      color: #444;
      font-size: 18px;
    }
  }
  .text_content.right {
    text-align: right;
  }

  @media screen and (max-width: 1000px) {
    .text_content {
      text-align: center;
      h3 {
        font-size: 24px;
      }
      p {
        font-size: 16px;
      }
    }
    .text_content.right {
      text-align: center;
    }
  }
`

export const HistoryScrollBox = styled.div`
  height: 550vh;
  .sticky_box {
    height: 100vh;
    position: sticky;
    top: 70px;
  }
  .sticky_content {
    height: 100%;
  }
  @media screen and (max-width: 1000px) {
    height: auto;
    .sticky_box {
      height: auto;
    }
  }
`

export const HistoryBox = styled.div`
  background-color: #ffbe00;
  color: #fff;
  height: 600px;
  overflow: hidden;
  padding: 0 20px;
  box-sizing: border-box;
  position: relative;
  top: calc(50vh - (554px * 0.7));

  .content_box {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    gap: 30px;

    .content2_box {
      width: 100%;
      max-width: 1200px;
    }

    .title_box {
      width: 32%;
      position: sticky;
      left: 0;
      top: 0;
      height: 400px;
      display: flex;
      justify-content: center;
      flex-direction: column;

      h3 {
        font-size: 60px;
        font-weight: 600;
        margin-bottom: 30px;
      }
      h4 {
        font-size: 35px;
        font-weight: 400;
      }
    }

    .time_line_box {
      transform: translateY(-200px);
      transition: 1s;
      width: 68%;
      padding-top: 40px;
      box-sizing: border-box;
    }
  }

  @media screen and (max-width: 1000px) {
    top: 0;
    height: auto;
    .content_box {
      flex-direction: column;
      .title_box {
        width: 100%;
        height: auto;
        text-align: center;
        padding: 40px 0;
        position: relative;
        z-index: 10;
        box-sizing: border-box;
        background-color: #ffbe00;
        h3 {
          font-size: 30px;
        }
        h4 {
          font-size: 20px;
        }
      }

      .time_line_box {
        position: relative;
        z-index: 8;
        width: 100%;
        padding-bottom: 40px;
        transform: translateY(0) !important;
      }
    }
  }
`

export const LineBox = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  .year {
    position: relative;
    text-align: right;
    top: -10px;
    right: 30px;
    font-size: 20px;
    font-weight: 600;
    flex: 1 1 0;
  }
  ul {
    border-left: 2px solid #fff;
    padding-bottom: 50px;
    position: relative;
    flex: 1 1 0;
    &:after {
      content: '';
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: #fff;
      position: absolute;
      left: -8.5px;
      top: -7.5px;
    }

    li {
      transform: translateX(-20px);
      opacity: 0;
      transition: 1s ease-out;
      position: relative;
      top: -7.5px;
      padding-left: 30px;
      margin-bottom: 10px;
      display: flex;
      align-items: flex-start;
      letter-spacing: -1px;
      .num {
        margin-right: 30px;
        //width: 50px;
      }
      .min {
        width: 55px;
      }
      .txt_min {
        flex: 1;
      }
    }
  }

  &.left {
    .year {
      order: 2;
      right: 0;
      left: 30px;
      text-align: left;
    }
    ul {
      border-left: 0;
      border-right: 2px solid #fff;
      order: 1;
      &:after {
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #fff;
        position: absolute;
        right: -8.5px;
        top: -7.5px;
        left: auto;
      }
      li {
        transform: translateX(20px);
        justify-content: flex-end;
        padding-left: 0;
        padding-right: 20px;

        .num {
          order: 2;
        }

        .txt {
          order: 1;
          padding-right: 30px;
          text-align: right;
        }
      }
    }
  }

  &.active {
    ul {
      li {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    ul li {
      opacity: 1;
      transform: translateX(0);
    }
  }
`

export const IconBox = styled.div`
  //margin-top: -70vh;
  padding: 100px 20px;
  box-sizing: border-box;

  .title_box {
    font-size: 40px;
    text-align: center;
    margin-bottom: 100px;
  }

  .item_box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }

  .icon_content {
    width: 100%;
    max-width: 305px;
    background: linear-gradient(45deg, #fb7099, #fedc45);
    border-radius: 50%;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 100%;
    }

    .line_box {
      position: absolute;
      left: 15px;
      top: 15px;
      right: 15px;
      bottom: 15px;
      border: 3px solid #fff;
      border-radius: 50%;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .number_box {
        width: 40px;
        height: 40px;
        background-color: #fff;
        color: #000;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
      }

      h3 {
        font-size: 22px;
        font-weight: 600;
        padding: 20px 0;
      }

      p {
        font-size: 16px;
        line-height: 1.6;
        font-weight: 600;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .item_box {
      flex-direction: column;
    }
  }
`

export const ContactUsBox = styled.div`
  max-width: 996px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 20px 140px;
  box-sizing: border-box;
  .title_box {
    color: #ffbe00;
    font-size: 24px;
    font-weight: 600;
  }

  .text_box {
    display: flex;
    align-items: flex-start;
    margin-top: 40px;
    flex-wrap: wrap;
    gap: 40px;
    .left {
      min-width: 310px;
    }
    p {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.6;
    }
    strong {
      font-size: 22px;
    }
  }
`
