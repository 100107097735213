import styled, { css } from 'styled-components'
import transitions from '../../assets/css/transitions'

export const ContentBox = styled.div`
  .input_box {
    label {
      display: block;
      font-size: 1rem;
      font-weight: 500;
      margin-bottom: 10px;
    }
    margin-bottom: 2rem;
  }

  .button_box {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    button + button {
      margin-left: 1rem;
    }
  }
`

export const ModalBox = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  box-sizing: border-box;

  ${props =>
    props.isActive
      ? css`
          animation: ${transitions.popInFromBottom} 0.4s forwards ease-in-out;
        `
      : css`
          animation: ${transitions.popOutToBottom} 0.2s forwards ease-in-out;
        `}

  .content {
    width: 100%;
    max-width: 400px;

    h2 {
      font-size: 3rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }
    h3 {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    .thumbnail_box {
      width: 100%;
      position: relative;
      background-color: #d1d5db;
      margin-bottom: 1rem;
      &:before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 56.25%;
      }
      .thumbnail_content {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: #fff;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .remove_icon {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: #f4b81c;
          cursor: pointer;
        }
      }
    }

    .description_box {
      textarea {
        width: 100%;
        height: 150px;
        padding: 1rem;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: #fff;
        border: 1px solid #000;
        outline: none;
        display: block;

        &:focus {
          border: 1px solid #f4b81c;
        }
      }
    }

    .button_box {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      button + button {
        margin-left: 1rem;
      }
    }
  }
`
