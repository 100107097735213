import React, { useEffect, useState } from 'react'
import { HeaderBox } from './styles'
import logo from '../../assets/images/logo_y_1.svg'
import logo_b from '../../assets/images/logo_b.svg'
import { Link, useNavigate, useLocation, NavLink } from 'react-router-dom'

import supportImg1 from '../../assets/images/fix1_1.png'
import supportImg2 from '../../assets/images/fix2_1.png'
import { useSetRecoilState } from 'recoil'
import solutionActiveState from '../../store/solutionActiveState'
import solutionScrollState from '../../store/solutionScrollState'
import { effectActiveSate, effectScrollState } from '../../store/effectPageState'

import TeamViewerQS from '../../assets/images/TeamViewerQS.exe'

const Header = () => {
  let navigate = useNavigate()
  let location = useLocation()
  const [menuActive, setMenuActive] = useState(false)
  const [mobileUrl, setMobileUrl] = useState('')
  const setIsActiveNumber = useSetRecoilState(solutionActiveState)
  const setScrollState = useSetRecoilState(solutionScrollState)
  const setEffectIsActiveNumber = useSetRecoilState(effectActiveSate)
  const setEffectScrollState = useSetRecoilState(effectScrollState)
  const [beforePosition, setBeforePosition] = useState(document.documentElement.scrollTop)
  const [headerActive, setHeaderActive] = useState(false)
  const [winW, setWinW] = useState(document.body.clientWidth)

  useEffect(() => {
    const varUA = navigator.userAgent.toLowerCase() //userAgent 값 얻기

    if (varUA.indexOf('android') > -1) {
      //안드로이드
      setMobileUrl('https://play.google.com/store/apps/details?id=com.msb.fms_order')
    } else if (varUA.indexOf('iphone') > -1 || varUA.indexOf('ipad') > -1 || varUA.indexOf('ipod') > -1) {
      //IOS
      setMobileUrl('https://itunes.apple.com/app/id1470599409?mt=8')
    }
  }, [])

  const onClickMenuToggle = () => {
    setMenuActive(!menuActive)
  }

  const onClickSolution = e => {
    e.preventDefault()
    setIsActiveNumber(1)
    setScrollState(false)
    navigate('/standard_solution')
  }

  const onClickEffect = e => {
    e.preventDefault()
    setEffectIsActiveNumber(null)
    setEffectScrollState(false)
    navigate('/effect')
  }

  useEffect(() => {
    function onResize() {
      resizeEvent()
    }
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const resizeEvent = () => {
    setWinW(document.body.clientWidth)
  }

  useEffect(() => {
    function onScroll() {
      const afterPosition = document.documentElement.scrollTop
      if (location.pathname === '/object_solution') {
        if (afterPosition > 50) {
          if (beforePosition < afterPosition) {
            // 스크롤 아래로
            // console.log('스크롤 아래로')
            setHeaderActive(true)
          } else {
            // 스크롤 위로
            // console.log('스크롤 위로')
            setHeaderActive(false)
          }
        } else {
          // 평상 시
        }
        setBeforePosition(afterPosition)
      }
    }
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [beforePosition])

  return (
    <>
      <HeaderBox
        className={`${headerActive ? 'active' : ''}`}
        // style={location.pathname === '/' && winW > 983 ? { top: '70px' } : { top: '0' }}
      >
        <header>
          <div className="logo_box">
            <Link to="/">
              <img src={location.pathname === '/object_solution' ? logo_b : logo} alt="logo" />
            </Link>
          </div>
          <div className={`${menuActive ? 'active' : ''} menu_box`} onClick={onClickMenuToggle}>
            <span className="bar bar1" />
            <span className="bar bar2" />
            <span className="bar bar3" />
          </div>
          <div className={`${menuActive ? 'active' : ''} menu_list_box`}>
            <ul>
              <li>
                <NavLink to="/standard_solution" onClick={onClickSolution}>
                  Standard solution
                </NavLink>
              </li>
              <li>
                <NavLink to="/object_solution">Object solution</NavLink>
              </li>
              <li>
                <NavLink to="/effect" onClick={onClickEffect}>
                  도입효과
                </NavLink>
              </li>
              <li>
                <NavLink to="/about">회사소개</NavLink>
              </li>
              <li>
                <NavLink to="/contact">상담센터</NavLink>
              </li>
            </ul>
            <div className="button_box">
              <a href="http://pf.kakao.com/_NDgsb" className="kakao_list">
                카카오톡 채널 바로가기
              </a>
              <a href="tel:1544-7120" className="support_list">
                상담 전화 연결하기
              </a>
              <a href={mobileUrl} className="app_down_link">
                발주닷컴 어플 다운받기
              </a>
            </div>
          </div>
          {location.pathname !== '/object_solution' && (
            <>
              <div className="support_box">
                <div className="support_content support1">
                  <img src={supportImg1} alt="" />
                  <p className="txt">24시 긴급</p>
                  <p className="txt mb">상담센터</p>
                  <p className="color">1544</p>
                  <p className="color">7120</p>
                </div>
                <a className="support_content support2" href={TeamViewerQS} download="TeamViewerQS.exe">
                  <img src={supportImg2} alt="" />
                  <p className="txt">원격지원</p>
                  <p className="txt">바로가기</p>
                </a>
              </div>
            </>
          )}

          {/*<span className="mobile_back_box" />*/}
        </header>
      </HeaderBox>
    </>
  )
}

export default Header
