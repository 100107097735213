import styled, { css } from 'styled-components'
import transitions from '../../assets/css/transitions'

export const ContainerBox = styled.div`
  .button_box {
    margin-bottom: 20px;
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .list_box {
    margin-top: 10px;
  }
`

export const ModalBox = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  box-sizing: border-box;

  .back {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(249, 249, 249, 0.85);
  }

  .modal_content {
    position: relative;
    z-index: 10;
    width: 100%;
    overflow: hidden;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    max-width: 400px;
    border-radius: 4px;
    background-color: #f0f0f0;
    ${props =>
      props.isActive
        ? css`
            animation: ${transitions.popInFromBottom} 0.4s forwards ease-in-out;
          `
        : css`
            animation: ${transitions.popOutToBottom} 0.2s forwards ease-in-out;
          `}
  }
`
