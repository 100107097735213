import styled from 'styled-components'

import instaColor from '../../assets/images/footer_insta_c2.png'
import instaGray from '../../assets/images/footer_insta_g2.png'

import faceColor from '../../assets/images/footer_face_c2.png'
import faceGray from '../../assets/images/footer_face_g2.png'

import blogColor from '../../assets/images/footer_blog_c2.png'
import blogGray from '../../assets/images/footer_blog_g2.png'

import kakaoColor from '../../assets/images/footer_kakao_c2.png'
import kakaoGray from '../../assets/images/footer_kakao_g2.png'

export const FooterBox = styled.div`
  background-color: #3f3f41;
  color: #fff;
  padding: 100px 20px;
  box-sizing: border-box;
  z-index: 40;
  position: relative;

  .content_box {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    display: flex;

    .logo_box {
      width: 140px;
      margin-right: 40px;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .text_box {
      color: #aeb1b7;
      p {
        font-size: 14px;
        line-height: 1.5;
      }
      p + p {
        margin-top: 20px;
      }
      a {
        color: #aeb1b7;
      }
    }

    .img_box {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .snsBox {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 10px;

        li {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: #262626;

          a {
            width: 100%;
            height: 100%;
            display: block;
            transition: 0.2s;
          }

          .insta {
            background-image: url(${instaGray});
          }
          .insta:hover {
            background-image: url(${instaColor});
          }
          .face {
            background-image: url(${faceGray});
          }
          .face:hover {
            background-image: url(${faceColor});
          }
          .blog {
            background-image: url(${blogGray});
            background-position: 0 2px;
          }
          .blog:hover {
            background-image: url(${blogColor});
          }
          .kakao {
            background-image: url(${kakaoGray});
          }
          .kakao:hover {
            background-image: url(${kakaoColor});
          }
        }
      }
      .crapeBox {
        display: flex;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
        justify-content: center;
        img {
          display: block;
          height: auto;
          image-rendering: -moz-crisp-edges; /* Firefox */
          image-rendering: -o-crisp-edges; /* Opera */
          image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
          image-rendering: crisp-edges;
        }
        .img1 {
          max-width: 78px;
        }
        .img2 {
          max-width: 107px;
        }
        .img3 {
          max-width: 70px;
        }
        .img4 {
          max-width: 36px;
        }

        .img5 {
          max-width: 70px;
        }
      }
    }
  }

  @media screen and (max-width: 1200px) {
    padding: 40px 20px;
    .content_box {
      flex-direction: column;
      align-items: center;

      .logo_box {
        margin-right: 0;
      }

      .text_box {
        margin-top: 40px;
        text-align: center;
      }

      .img_box {
        margin-top: 40px;
        margin-left: 0;
        gap: 40px;
        .snsBox {
          justify-content: center;
        }
      }
    }
  }

  .none {
    display: none;
  }
`
