import React, { useEffect, useRef, useState } from 'react'
import { ContainerBox } from './styles'
import { SectionTitle } from '../../routes/MainPage/styles'

import citation_1 from '../../assets/images/1.png'
import citation_2 from '../../assets/images/2.png'
import citation_3 from '../../assets/images/3.png'
import citation_4 from '../../assets/images/4.png'
import citation_5 from '../../assets/images/5.png'
import citation_6 from '../../assets/images/6.png'
import citation_6_1 from '../../assets/images/6_1.png'
import citation_6_2 from '../../assets/images/6_2.png'
import citation_6_3 from '../../assets/images/6_3.png'
import citation_6_4 from '../../assets/images/6_4.png'
import citation_6_5 from '../../assets/images/6_5.png'
import citation_6_6 from '../../assets/images/6_6.png'
import citation_6_7 from '../../assets/images/6_7.png'
import citation_6_8 from '../../assets/images/6_8.png'
import citation_6_9 from '../../assets/images/6_9.png'
import citation_6_10 from '../../assets/images/6_10.png'
import citation_7 from '../../assets/images/7.png'
import citation_8 from '../../assets/images/8.png'
import citation_9 from '../../assets/images/9.png'
import citation_10 from '../../assets/images/10.png'
import citation_11 from '../../assets/images/11.png'
import citation_12 from '../../assets/images/12.png'
import citation_13 from '../../assets/images/13.png'
import citation_14 from '../../assets/images/14.png'
import citation_15 from '../../assets/images/15.png'
import citation_16 from '../../assets/images/16.png'
import citation_17 from '../../assets/images/17.png'
import citation_18 from '../../assets/images/18.png'
import citation_19 from '../../assets/images/19.png'
import citation_20 from '../../assets/images/20.png'
// import section5bg from '../../assets/images/section5_bg.png'
import section5bg from '../../assets/images/section5_bg.jpg'

const CrapeBox = ({ main }) => {
  const [list, setList] = useState([
    {
      img: citation_1,
      id: 1,
      isActive: false,
    },
    {
      img: citation_2,
      id: 2,
      isActive: false,
    },
    {
      img: citation_3,
      id: 3,
      isActive: false,
    },
    {
      img: citation_4,
      id: 4,
      isActive: false,
    },
    {
      img: citation_5,
      id: 5,
      isActive: false,
    },
    {
      img: citation_6,
      id: 6,
      isActive: false,
    },
    {
      img: citation_6_1,
      id: 61,
      isActive: false,
    },
    {
      img: citation_6_2,
      id: 62,
      isActive: false,
    },
    {
      img: citation_6_3,
      id: 63,
      isActive: false,
    },
    {
      img: citation_6_4,
      id: 64,
      isActive: false,
    },
    {
      img: citation_6_5,
      id: 65,
      isActive: false,
    },
    {
      img: citation_6_6,
      id: 66,
      isActive: false,
    },
    {
      img: citation_6_7,
      id: 67,
      isActive: false,
    },
    {
      img: citation_6_8,
      id: 68,
      isActive: false,
    },
    {
      img: citation_6_9,
      id: 69,
      isActive: false,
    },
    {
      img: citation_6_10,
      id: 610,
      isActive: false,
    },
    {
      img: citation_7,
      id: 7,
      isActive: false,
    },
    {
      img: citation_8,
      id: 8,
      isActive: false,
    },
    {
      img: citation_9,
      id: 9,
      isActive: false,
    },
    {
      img: citation_10,
      id: 10,
      isActive: false,
    },
    {
      img: citation_11,
      id: 11,
      isActive: false,
    },
    {
      img: citation_12,
      id: 12,
      isActive: false,
    },
    {
      img: citation_13,
      id: 13,
      isActive: false,
    },
    {
      img: citation_14,
      id: 14,
      isActive: false,
    },
    {
      img: citation_15,
      id: 15,
      isActive: false,
    },
    {
      img: citation_16,
      id: 16,
      isActive: false,
    },
    {
      img: citation_17,
      id: 17,
      isActive: false,
    },
    {
      img: citation_18,
      id: 18,
      isActive: false,
    },
    {
      img: citation_19,
      id: 19,
      isActive: false,
    },
    {
      img: citation_20,
      id: 20,
      isActive: false,
    },
  ])

  // function scroll() {
  //   console.log(1)
  // }

  // window.addEventListener('scroll', scroll)
  const ulRef = useRef(null)

  useEffect(() => {
    // console.log(aa[0])
    // aa.forEach(v => {
    //   console.log(v)
    // })
    // console.log(bb.name)
    // console.log(cc[0].name)
    // cc.forEach(v => {
    //   console.log(v.name)
    // })
  }, [])

  useEffect(() => {
    function scroll() {
      // console.log(ulRef.current.childNodes)
      // console.log(window.scrollY)
      // console.log(document.documentElement.clientHeight)
      if (
        ulRef &&
        ulRef.current &&
        ulRef.current.getBoundingClientRect().top < document.documentElement.clientHeight * 0.7
      ) {
        const liList = ulRef.current.childNodes
        // console.log(liList[0].getBoundingClientRect().top)
        liList.forEach((v, number) => {
          // console.log(v)
          // console.log(v.getBoundingClientRect().top)

          if (v.getBoundingClientRect().top < document.documentElement.clientHeight * 0.7) {
            // console.log(v)
            const newList = list.map((f, i) => {
              if (number === i) {
                f.isActive = true
              }
              return f
            })
            setList(newList)
          }
        })
      }
    }
    window.addEventListener('scroll', scroll)

    return () => {
      window.removeEventListener('scroll', scroll)
    }
  }, [])

  return (
    <>
      <ContainerBox style={{ backgroundImage: `url(${section5bg})` }}>
        {main ? (
          <SectionTitle>
            <p className="title">
              가치를 창조하는 <span>엠에스벤터</span>
            </p>
            <p className="sub_title">시장의 변화에 따라 끊임없이 고객의 문제를 발견하고 해결해갑니다.</p>
          </SectionTitle>
        ) : (
          <div className="sub_title2">PATENT & CERTIFICATE</div>
        )}
        <div className="content">
          <ul ref={ulRef}>
            {list.map((v, i) => (
              <li key={v.id} className={`${v.isActive ? 'active' : ''}`}>
                <img src={v.img} alt="" className={`img${(i % 5) + 1}`} />
              </li>
            ))}
          </ul>
        </div>
      </ContainerBox>
    </>
  )
}

export default CrapeBox
