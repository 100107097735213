import styled, { css } from 'styled-components'
import transitions from '../../assets/css/transitions'

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 70px;
  box-sizing: border-box;

  .header_notice {
    width: 100%;
    height: 30px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    line-height: 30px;
    position: fixed;
    left: 0;
    z-index: 20;
  }

  .header_notice1 {
    top: 100px;
    background-color: #ffbe00;
  }
  .header_notice2 {
    background-color: #000;
    top: 130px;
  }
`

export const SectionTitle = styled.div`
  text-align: center;
  line-height: 1.6;
  .title {
    font-size: 40px;
    margin-bottom: 30px;
    span {
      color: #ffbe00;
      font-weight: bold;
    }
  }
  .sub_title {
    font-size: 16px;
  }

  @media screen and (max-width: 800px) {
    .title {
      font-size: 30px;
      span {
        display: block;
      }
    }
    .sub_title {
      font-size: 14px;
    }
  }
`

export const Section1Container = styled.div`
  width: 100%;
  height: 720px;
  position: relative;
  //padding-top: 60px;
  box-sizing: border-box;

  .swiper-container {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    position: relative;
  }

  .swiper-pagination {
    bottom: 40px;
  }

  .swiper-pagination-bullet {
    background-color: #dfdfdf;
    width: 10px;
    height: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    opacity: 1;
    border-radius: 5px;
    transition: width 0.3s;
  }

  .swiper-pagination-bullet-active {
    width: 70px;
    background-color: #f4b81c;
  }

  .nav {
    position: absolute;
    z-index: 10;
    bottom: 35px;
    color: #fff;
    cursor: pointer;
    font-size: 32px;
    width: 14px;
    img {
      display: block;
      width: 100%;
    }
  }

  .nav.prev {
    left: calc(50% - 100px);
  }

  .nav.next {
    right: calc(50% - 100px);
  }

  .video_box {
    width: 100%;
    height: 100%;
    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 100%;
    }
    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .image_box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .img_content1 {
    //background-color: #d6dafb;
    background: linear-gradient(to bottom left, #e3f4fd, #fff);
    position: relative;
    overflow: hidden;

    .content_box {
      position: relative;
      z-index: 10;
      max-width: 1200px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .text_box {
        .title {
          font-weight: bold;
          font-size: 18px;
          color: #545352;
          margin-bottom: 20px;
          letter-spacing: -1px;
          span {
            font-size: 60px;
            color: #222b6b;
          }
        }
      }

      .center_text {
        margin-bottom: 20px;
        letter-spacing: -1px;
      }

      .center_text p {
        font-size: 32px;
        color: #222b6b;
        line-height: 1.5;
        font-weight: bold;
      }

      .bottom_text span {
        color: #545352;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: -1px;
      }
    }
    .img_box {
      position: relative;
      z-index: 10;
      width: 50%;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .circle_box {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      max-width: 1200px;
      width: 100%;
      height: 100%;

      img {
        position: absolute;
      }

      .circle1 {
        top: 56%;
        left: 11%;
        animation: scale 5s 3s both linear infinite;
      }

      .circle2 {
        right: -12%;
        top: -15%;
        animation: scale 5s both linear infinite;
      }

      @keyframes scale {
        0% {
          transform: scale(1);
        }
        50% {
          transform: scale(1.12);
        }
        100% {
          transform: scale(1);
        }
      }
    }
  }

  .img_content2 {
    background-color: #c9dcb2;
    padding: 20px;
    box-sizing: border-box;

    .content_box {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .text_box {
      font-size: 40px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 20px;
      letter-spacing: -1px;
      color: #3d4e40;
    }
    .img_box {
      width: 100%;
      display: flex;
      align-items: center;
      div {
        flex: 1 1 auto;
        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }
  }

  .img_content3 {
    position: relative;

    .content_box {
      position: relative;
      z-index: 10;
      max-width: 1200px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;

      .text_box {
        position: relative;
        z-index: 10;
        .title {
          height: 50px;
          padding: 0 20px;
          font-weight: bold;
          font-size: 16px;
          color: #333;
          display: flex;
          align-items: center;
          letter-spacing: -1px;
          cursor: pointer;

          span.txt {
            font-size: 24px;
            margin-right: 10px;
          }
          .down_icon {
            font-size: 40px;
            margin-left: auto;
            color: #333;
            ${css`
              animation: ${transitions.moveOpacity2} 2s linear infinite;
            `}
          }
        }
        .drop_down.active {
          .title span.txt {
            color: #febe00;
          }
        }
      }

      .drop_down {
        box-sizing: border-box;
        overflow: hidden;
        border-radius: 25px;
        background-color: rgba(255, 255, 255, 0.5);
        box-shadow: 4px 4px 7px 0 rgb(0 0 0 / 10%);
      }

      .drop_down_content {
        height: 0;

        box-sizing: border-box;
        overflow: hidden;
        transition: 0.3s;
      }
      .drop_down.active .drop_down_content {
        padding: 20px;
        padding-top: 0;
        //height: auto;
        height: 56px;
        max-height: 56px;

        p {
          font-size: 16px;
        }
        @media screen and (max-width: 450px) {
          p {
            font-size: 13px;
          }
        }
      }

      .drop_down + div {
        margin-top: 20px;
      }

      .center_text {
        margin-bottom: 20px;
      }

      .center_text p {
        font-size: 23px;
        color: #222b6b;
        line-height: 1.5;
        font-weight: bold;
        letter-spacing: -1px;
      }

      .bottom_text span {
        color: #545352;
        font-size: 16px;
        font-weight: bold;
      }
    }
    .img_box {
      position: relative;
      z-index: 10;
      width: 50%;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .stage {
      perspective: 600px;
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;

      .carousel {
        position: relative;
        width: 100%;
        max-width: 350px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform-style: preserve-3d;
        transition: 1s;
        left: -70px;
        //transform: rotateY(0deg);
      }
    }

    .circle_box {
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      max-width: 1200px;
      width: 100%;
      height: 100%;

      img {
        position: absolute;
      }

      .circle1 {
        top: 56%;
        left: 11%;
      }

      .circle2 {
        right: -12%;
        top: -15%;
      }
    }
  }
  @media screen and (max-width: 1300px) {
    .video_box {
      video {
        object-fit: cover;
      }
    }

    .img_content3 {
      .stage {
        .carousel {
          max-width: 250px;
        }
      }
    }
  }

  //.img_content2 img {
  //  max-width: 100%;
  //  //height: 100%;
  //  //object-fit: contain;
  //}

  @media screen and (max-width: 1000px) {
    height: 600px;

    .img_content1 {
      .content_box {
        width: 100%;
        //height: 100%;
        justify-content: center;
        flex-direction: column;

        .text_box {
          margin: 20px 0;
          text-align: center;
          .title {
            font-size: 16px;
            span {
              font-size: 30px;
            }
          }
          .center_text p {
            font-size: 20px;
          }
        }
      }
      .img_box {
        width: 40%;
      }

      .circle_box {
        .circle2 {
          max-width: 400px;
          right: -12%;
          top: 11%;
        }
      }
    }

    .img_content2 img {
      height: 100%;
      object-fit: contain;
    }

    .img_content3 {
      .content_box {
        width: 100%;
        height: 100%;
        //justify-content: center;
        //flex-direction: column;

        .text_box {
          //margin: 20px 0;
          text-align: center;
          .title {
            font-size: 16px;
            span {
              font-size: 30px;
            }
          }
          .center_text p {
            font-size: 20px;
          }
        }
      }
      .stage {
        .carousel {
          left: -40px;
          max-width: 200px;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    height: auto;
    min-height: 100%;
    .swiper-slide {
      height: auto;
      min-height: calc(100vh - 60px);
    }
    .img_content1 {
      .content_box {
        .text_box {
          .title {
            font-size: 14px;
            span {
              font-size: 20px;
            }
          }
          .center_text p {
            font-size: 16px;
          }
        }
      }
      .img_box {
        width: 70%;
      }

      .circle_box {
        .circle2 {
          max-width: 320px;
          right: -16%;
          top: 23%;
        }
      }
    }

    .img_content2 {
      .text_box {
        display: none;
      }
      .img_box {
        height: 100%;
        flex-direction: column;
        margin-top: 70px;
        //justify-content: center;
        gap: 10px;
        div {
          //height: 30%;
          flex: 0;
          img {
            width: 100%;
            height: auto;
            //max-width: 280px;
          }
        }
        div + div {
          margin-top: 10%;
        }
      }
    }

    .img_content3 {
      .content_box {
        flex-direction: column;
        //justify-content: flex-start;
        .text_box {
          //margin-top: 40px;
          //margin-bottom: 200px;
          .title {
            font-size: 14px;
            span {
              font-size: 20px;
            }
          }
          .center_text p {
            font-size: 16px;
          }
        }
      }
      .stage {
        .carousel {
          left: -40px;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    .img_content1 {
      .img_box {
        width: 100%;
      }

      .circle_box {
        .circle1 {
          max-width: 150px;
        }
        .circle2 {
          max-width: 250px;
          right: -16%;
          top: 23%;
        }
      }
    }
  }

  .icon {
    color: #fff;
  }

  .back_img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media screen and (max-height: 850px) {
    .img_content3 {
      .content_box {
        justify-content: flex-start;
        .text_box {
          margin-top: 20px;
          margin-bottom: 150px;
        }
      }
    }
  }
`

export const Panel = styled.div`
  width: 100%;
  //height: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transition: 1s;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &:nth-child(1) {
    ${props =>
      props.carouselState === 1
        ? css`
            transform: translateX(-30%) translateY(-40%) translateZ(200px);
          `
        : props.carouselState === 2
        ? css`
            transform: translateX(40%) translateY(-50%) translateZ(-100px);
          `
        : props.carouselState === 3
        ? css`
            transform: translateX(-50%) translateY(-120%) translateZ(-200px);
          `
        : css`
            transform: translateX(-110%) translateY(-50%) translateZ(-100px);
          `};
  }
  &:nth-child(2) {
    ${props =>
      props.carouselState === 1
        ? css`
            transform: translateX(40%) translateY(-50%) translateZ(-100px);
          `
        : props.carouselState === 2
        ? css`
            transform: translateX(-50%) translateY(-120%) translateZ(-200px);
          `
        : props.carouselState === 3
        ? css`
            transform: translateX(-110%) translateY(-50%) translateZ(-100px);
          `
        : css`
            transform: translateX(-30%) translateY(-40%) translateZ(200px);
          `};
  }
  &:nth-child(3) {
    ${props =>
      props.carouselState === 1
        ? css`
            transform: translateX(-50%) translateY(-120%) translateZ(-200px);
          `
        : props.carouselState === 2
        ? css`
            transform: translateX(-110%) translateY(-50%) translateZ(-100px);
          `
        : props.carouselState === 3
        ? css`
            transform: translateX(-30%) translateY(-40%) translateZ(200px);
          `
        : css`
            transform: translateX(40%) translateY(-50%) translateZ(-100px);
          `};
  }
  &:nth-child(4) {
    ${props =>
      props.carouselState === 1
        ? css`
            transform: translateX(-110%) translateY(-50%) translateZ(-100px);
          `
        : props.carouselState === 2
        ? css`
            transform: translateX(-30%) translateY(-40%) translateZ(200px);
          `
        : props.carouselState === 3
        ? css`
            transform: translateX(40%) translateY(-50%) translateZ(-100px);
          `
        : css`
            transform: translateX(-50%) translateY(-120%) translateZ(-200px);
          `};
  }
`

export const Section2Container = styled.div`
  background-color: #fff;
  padding: 120px 20px 100px;
  box-sizing: border-box;

  .title,
  .sub_title {
    padding: 0 20px;
    box-sizing: border-box;
  }
  .title {
    letter-spacing: -1px;
  }

  .content_box {
    margin: 100px auto 0;
    position: relative;
    max-width: 1300px;
    padding: 0 80px;
    box-sizing: border-box;

    .swiper-container {
      padding-bottom: 20px;
      box-sizing: border-box;
      .swiper-slide {
        box-shadow: 4px 4px 7px 0 rgb(0 0 0 / 10%);
      }
    }
    ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
    }
    li {
      background-color: #f5f5f5;
    }
    .img_box {
      position: relative;
      &:before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 56.25%;
      }
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      /* img.small {
        width: 180px;
        height: 50px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        object-fit: none;
      } */
      .position_top {
        object-position: top;
      }
    }
    .text_box {
      padding: 1rem;
      box-sizing: border-box;
      position: relative;

      .logo_box {
        position: absolute;
        right: 10px;
        top: -40px;
        width: 80px;
        height: 80px;
        background-color: #f5f5f5;
        padding: 5px;
        box-sizing: border-box;
        border-radius: 15px;
        img {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 10;
          width: 70px;
          display: block;
          max-width: 70px;
        }
      }

      strong {
        display: block;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 1rem;
        color: #ffbe00;
      }
      p {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-height: 1.6;
      }
    }
    .footer_box {
      padding: 1rem;
      box-sizing: border-box;
      button {
        outline: none;
        border: none;
        box-sizing: border-box;
        padding: 0;
        background: transparent;
        margin-left: auto;
        display: flex;
        align-items: center;
        color: #ffbe00;
        cursor: pointer;
        font-weight: bold;

        .icon_box {
          margin-left: 20px;
          position: relative;
          width: 35px;
          text-align: right;
          transition: all 0.3s ease-out;

          .bar {
            width: 100%;
            height: 2px;
            background-color: #ffbe00;
            position: absolute;
            right: 11px;
            top: 43%;
            transform: translateY(-50%);
          }
        }

        &:hover .icon_box {
          width: 50px;
        }
      }
    }

    .nav {
      display: flex;
      position: absolute;
      z-index: 10;
      top: 50%;
      transform: translateY(-50%);
      width: 50px;
      height: 100px;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      cursor: pointer;
      opacity: 1;
    }
    .nav.left::after {
      content: '';
      width: 50px; /* 사이즈 */
      height: 50px; /* 사이즈 */
      border-top: 5px solid #dfdfdf; /* 선 두께 */
      border-right: 5px solid #dfdfdf; /* 선 두께 */
      display: inline-block;
      transform: translate(-50%, -50%) rotate(225deg); /* 각도 */
      position: absolute;
      top: 50%; /* 기본 0px 값으로 해주세요 */
      left: 50%; /* 기본 0px 값으로 해주세요 */
    }

    .nav.right::after {
      content: '';
      width: 50px; /* 사이즈 */
      height: 50px; /* 사이즈 */
      border-top: 5px solid #dfdfdf; /* 선 두께 */
      border-right: 5px solid #dfdfdf; /* 선 두께 */
      display: inline-block;
      transform: translate(-50%, -50%) rotate(45deg); /* 각도 */
      position: absolute;
      top: 50%; /* 기본 0px 값으로 해주세요 */
      left: 50%; /* 기본 0px 값으로 해주세요 */
    }

    .nav:hover::after {
      border-top: 5px solid #f4b81c; /* 선 두께 */
      border-right: 5px solid #f4b81c; /* 선 두께 */
    }

    .nav.left {
      left: 20px;
    }

    .nav.right {
      right: 20px;
    }

    @media screen and (max-width: 700px) {
      padding: 0;
      .nav {
        display: none;
      }
    }
  }

  .slide_content {
    margin: 100px auto 0;
    position: relative;
    max-width: 1300px;
    padding: 0 50px;
    box-sizing: border-box;

    .swiper {
      width: 100%;
      height: 100%;
    }

    .swiper-container {
      //overflow: inherit;
    }

    .swiper-slide {
      height: 340px;
      background-color: #fbfaf1;
      position: relative;
      //transition: all 0.3s ease-out;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;

      .img1 {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 35%;
        height: auto;
        max-width: 300px;
      }

      .logo1 {
        position: absolute;
        right: 30%;
        top: 50%;
        transform: translateY(-50%);
        width: 30%;
        height: auto;
        max-width: 190px;
      }

      .img2 {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 40%;
        height: auto;
        max-width: 240px;
      }

      .logo2 {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        height: auto;
        opacity: 0.35;
      }

      .slide_title_box {
        padding-left: 37%;
        padding-right: 10px;
        font-size: 22px;
        line-height: 1.5;
        font-weight: 500;
        z-index: 10;
      }

      .slide_title_box em {
        font-size: 26px;
        font-weight: bold;
      }

      .slide_text_box {
        margin-top: 20px;
        font-weight: 500;
        padding-left: 37%;
        line-height: 2;
        font-size: 14px;
        z-index: 10;
      }

      .slide_footer {
        position: relative;
        z-index: 10;
        margin-top: 20px;
        margin-bottom: 40px;
        padding-left: 37%;
        display: flex;
        align-items: center;
        font-weight: bold;

        .tag {
          font-size: 12px;
        }

        button {
          outline: none;
          border: none;
          box-sizing: border-box;
          padding: 0;
          margin: 0;
          background: transparent;
          margin-left: 20px;
          display: flex;
          align-items: center;
          color: #ffbe00;
          cursor: pointer;
          font-weight: bold;

          .icon_box {
            margin-left: 20px;
            position: relative;
            width: 35px;
            text-align: right;
            transition: all 0.3s ease-out;

            .bar {
              width: 100%;
              height: 2px;
              background-color: #ffbe00;
              position: absolute;
              right: 11px;
              top: 46%;
              transform: translateY(-50%);
            }
          }

          &:hover .icon_box {
            width: 50px;
          }
        }
      }
    }

    .nav {
      display: flex;
      position: absolute;
      z-index: 10;
      top: 50%;
      transform: translateY(-50%);
      width: 50px;
      height: 100px;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      cursor: pointer;
      opacity: 1;
    }
    .nav.left::after {
      content: '';
      width: 50px; /* 사이즈 */
      height: 50px; /* 사이즈 */
      border-top: 5px solid #dfdfdf; /* 선 두께 */
      border-right: 5px solid #dfdfdf; /* 선 두께 */
      display: inline-block;
      transform: translate(-50%, -50%) rotate(225deg); /* 각도 */
      position: absolute;
      top: 50%; /* 기본 0px 값으로 해주세요 */
      left: 50%; /* 기본 0px 값으로 해주세요 */
    }

    .nav.right::after {
      content: '';
      width: 50px; /* 사이즈 */
      height: 50px; /* 사이즈 */
      border-top: 5px solid #dfdfdf; /* 선 두께 */
      border-right: 5px solid #dfdfdf; /* 선 두께 */
      display: inline-block;
      transform: translate(-50%, -50%) rotate(45deg); /* 각도 */
      position: absolute;
      top: 50%; /* 기본 0px 값으로 해주세요 */
      left: 50%; /* 기본 0px 값으로 해주세요 */
    }

    .nav:hover::after {
      border-top: 5px solid #f4b81c; /* 선 두께 */
      border-right: 5px solid #f4b81c; /* 선 두께 */
    }

    .nav.left {
      left: 0;
    }

    .nav.right {
      right: 0;
    }
  }
  @media screen and (max-width: 1000px) {
    padding: 120px 0 100px;
    .slide_content {
      padding: 0;

      .swiper-slide {
        height: 300px;
        .img1 {
          max-width: 260px;
        }
        .logo1 {
          right: 20px;
        }
        .slide_title_box {
          font-size: 18px;
          em {
            font-size: 22px;
          }
        }
        .slide_text_box {
          font-size: 12px;
        }
      }

      .nav {
        display: none;
      }
    }
  }

  @media screen and (max-width: 700px) {
    .slide_content {
      padding: 0;

      .swiper-slide {
        height: 200px;

        .img1 {
          max-width: 155px;
        }
        .logo1 {
          right: 20px;
        }
        .slide_title_box {
          font-size: 12px;
          em {
            font-size: 14px;
          }
        }
        .slide_text_box {
          display: none;
        }
        .slide_footer {
          margin-bottom: 20px;
          .tag {
            display: none;
          }
          button {
            margin-left: auto;
          }
        }
      }

      .nav {
        display: none;
      }
    }
  }
`

export const Section3Container = styled.div`
  background-color: #fbf9f4;
  padding: 120px 20px;

  .title {
    letter-spacing: -1px;
  }
  .card_list_box {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 100px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 24px;

    @media screen and (max-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (max-width: 800px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 560px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`

export const Section4Container = styled.div`
  position: relative;
  background-color: #fff;
  padding: 0 20px;
  box-sizing: border-box;

  .title {
    letter-spacing: -1px;
  }

  .custom-shape-divider-top-1637126876 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
  }

  .custom-shape-divider-top-1637126876 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
  }

  .custom-shape-divider-top-1637126876 .shape-fill {
    fill: #fbf9f4;
  }

  .content_box {
    padding: 140px 0 100px;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;

    .solution_content {
      width: 100%;
      max-width: 1200px;
      margin: 50px auto 0;
      .solution {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        .icon_box {
          width: 40%;
          position: relative;
          .circle {
            width: 100%;
            height: 100%;
            max-width: 330px;
            max-height: 330px;
            background-color: #fdf6e0;
            border-radius: 50%;
            display: block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          .img {
            width: 100%;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
          }
          .icon2 {
            //width: 68%;
          }
        }
        .icon_box:before {
          content: '';
          display: block;
          width: 100%;
          padding-bottom: 100%;
        }
        .text_box {
          position: relative;
          width: 100%;
          height: 130px;
          max-width: 430px;
          background: #efefef;
          border-radius: 15px;
          padding: 1rem;
          box-sizing: border-box;
          &:after {
            content: '';
            position: absolute;
            border-style: solid;
            border-color: transparent #efefef;
            display: block;
            width: 0;
            z-index: 1;
            top: 85px;
          }
          text-align: center;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;

          .title {
            font-size: 20px;
            font-weight: bold;
            line-height: 1.5;
          }
          p {
            font-size: 16px;
            line-height: 1.5;
          }
        }
      }

      .item_left {
        transform: translateX(-10px);
        opacity: 0;
        transition: 1s ease-out;
        .text_box {
          margin-left: 30px;
          &:after {
            border-width: 20px 20px 20px 0;
            left: -15px;
          }
        }
      }
      .item_left.active {
        transform: translateX(0);
        opacity: 1;
      }

      .item_right {
        transform: translateX(10px);
        opacity: 0;
        transition: 1s ease-out;
        justify-content: flex-end;
        .icon_box {
          max-width: 330px;
        }
        .text_box {
          margin-right: 30px;
          &:after {
            border-width: 20px 0 20px 20px;
            right: -15px;
          }
        }
      }

      .item_right.active {
        transform: translateX(0);
        opacity: 1;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .content_box {
      .solution_content {
        .solution {
          flex-direction: column;
          .text_box {
            margin-top: 40px;
            margin-left: 0;
            &:after {
              top: -20px;
              border-color: #efefef transparent;
            }
          }

          .text2_box {
            order: 2;
          }
          .icon2_box {
            order: 1;
          }
          .icon_box {
            .icon2 {
              width: 100%;
            }
          }
        }
        .item_left {
          .text_box {
            &:after {
              border-width: 0 20px 20px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }

        .item_right {
          .text_box {
            &:after {
              border-width: 0 20px 20px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .content_box {
      .solution_content {
        .solution {
          .icon_box {
            width: 70%;
          }
        }
      }
    }
  }
`

export const Section6Container = styled.div`
  width: 100%;
  padding: 120px 20px 400px;
  box-sizing: border-box;
  background-color: #fbf9f4;

  .title {
    letter-spacing: -1px;
  }

  .content_box {
    max-width: 1200px;
    width: 100%;
    margin: 80px auto 0;

    ul {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 24px;

      button {
        display: block;
        width: 100%;
      }

      .img_box {
        position: relative;
        border-radius: 15px;
        overflow: hidden;

        &:before {
          content: '';
          display: block;
          padding-bottom: 56.25%;
        }

        img {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          image-rendering: -moz-crisp-edges; /* Firefox */
          image-rendering: -o-crisp-edges; /* Opera */
          image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
          image-rendering: crisp-edges;
        }
      }

      .title_box {
        margin-top: 10px;
        p {
          font-size: 18px;
          color: #000;
          font-weight: 500;
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .content_box ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (max-width: 500px) {
    .content_box ul {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`

export const Section7Container = styled.div`
  padding: 0 20px 100px;
  margin-top: -310px;
  box-sizing: border-box;
  .content {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    div {
      flex: 1 auto;
    }
    .text_box {
      font-weight: 500;
      .top {
        font-size: 20px;
        line-height: 1.6;
        letter-spacing: -1px;
      }
      .btm {
        margin-top: 50px;
        p {
          display: flex;
          align-items: center;
          font-size: 20px;
          margin-bottom: 10px;
          img {
            width: 40px;
            height: auto;
            margin-right: 10px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .content {
      flex-direction: column;
    }
  }
`
export const DownCenterContainer = styled.div`
  padding: 100px 0;
  box-sizing: border-box;
  background-color: #fbf9f4;

  .down_inner {
    max-width: 1200px;
    margin: 0 auto;

    h2 {
      font-size: 40px;
      text-align: center;
      color: #333;
    }
    .down_box {
      margin-top: 50px;
      display: flex;
      justify-content: space-between;

      p {
        font-size: 24px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 20px;
        color: #565656;
      }
      .pc {
        background: #fff;
        border-radius: 20px;
        padding: 25px;
        .pc_btn {
          display: flex;
          justify-content: space-between;
          gap: 5px;

          a {
            display: inline-block;
            height: 80px;

            img {
              height: 100%;
            }
          }
        }
      }
      .mobile {
        background: #fff;
        border-radius: 20px;
        padding: 25px;
        .mobile_btn {
          display: flex;
          justify-content: space-between;
          gap: 5px;
          a {
            display: inline-block;
            height: 80px;

            img {
              height: 100%;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .down_inner .down_box {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }
  @media screen and (max-width: 600px) {
    .down_inner h2 {
      font-size: 30px;
    }
    .down_inner .down_box p {
      font-size: 20px;
    }
    .down_inner .down_box .pc .pc_btn {
      flex-direction: column;
    }
    .down_inner .down_box .mobile .mobile_btn {
      flex-direction: column;
    }
  }
`
