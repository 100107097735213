import React, { useEffect, useRef } from 'react'
import { Container, ContentBox } from './styles'
import Header from '../../components/Header'
import QuickMenuBox from '../../components/QuickMenuBox'
import Footer from '../../components/Footer'

import img1 from '../../assets/images/review_1_img.png'
// import img1_logo from '../../assets/images/review_1_logo.png'
import img1_logo from '../../assets/images/review_1_logo2.png'
import img2 from '../../assets/images/review_2_img.png'
import img2_logo from '../../assets/images/review_2_logo.png'
import img3 from '../../assets/images/review_3_img.png'
import img3_logo from '../../assets/images/review_3_logo.png'
import img4 from '../../assets/images/review_4_img.png'
import img4_logo from '../../assets/images/review_4_logo.png'
import img5 from '../../assets/images/review_5_img.png'
import img5_logo from '../../assets/images/review_5_logo.png'
import img6 from '../../assets/images/review_6_img.png'
import img6_logo from '../../assets/images/review_6_logo.png'
import img7 from '../../assets/images/review_7_img.jpeg'
import img7_logo from '../../assets/images/review_7_logo.png'
import img8 from '../../assets/images/review_8_img.jpg'
import img8_logo from '../../assets/images/review_8_logo.png'
// import img9 from '../../assets/images/review_9_img.png'
import img9 from '../../assets/images/review_9_img2.png'
// import img9 from '../../assets/images/review_9_img.jpg'
import img9_logo from '../../assets/images/review_9_logo.png'
import img10 from '../../assets/images/review_10_img.png'
import img10_logo from '../../assets/images/review_10_logo.png'

import { useRecoilValue } from 'recoil'
import solutionScrollState from '../../store/solutionScrollState'
import { effectActiveSate, effectScrollState } from '../../store/effectPageState'
import QuickSideMenu from '../../components/QuickSideMenu'

const EffectPage = () => {
  const scrollState = useRecoilValue(effectScrollState)
  const isActiveNumber = useRecoilValue(effectActiveSate)

  const card1Ref = useRef(null)
  const card2Ref = useRef(null)
  const card3Ref = useRef(null)
  const card4Ref = useRef(null)
  const card5Ref = useRef(null)
  const card6Ref = useRef(null)
  const card7Ref = useRef(null)
  const card8Ref = useRef(null)
  const card9Ref = useRef(null)
  const card10Ref = useRef(null)

  useEffect(() => {
    if (scrollState) {
      let scroll
      if (isActiveNumber === 1) {
        scroll = card1Ref.current.offsetTop
      } else if (isActiveNumber === 2) {
        scroll = card2Ref.current.offsetTop
      } else if (isActiveNumber === 3) {
        scroll = card3Ref.current.offsetTop
      } else if (isActiveNumber === 4) {
        scroll = card4Ref.current.offsetTop
      } else if (isActiveNumber === 5) {
        scroll = card5Ref.current.offsetTop
      } else if (isActiveNumber === 6) {
        scroll = card6Ref.current.offsetTop
      } else if (isActiveNumber === 7) {
        scroll = card7Ref.current.offsetTop
      } else if (isActiveNumber === 8) {
        scroll = card8Ref.current.offsetTop
      } else if (isActiveNumber === 9) {
        scroll = card9Ref.current.offsetTop
      } else if (isActiveNumber === 10) {
        scroll = card10Ref.current.offsetTop
      }
      window.scrollTo(0, scroll - 40)
      // const scroll = contentRef.current.offsetTop
      // window.scrollTo(0, scroll - 70)
    } else {
      window.scrollTo(0, 0)
    }
  }, [scrollState, isActiveNumber])
  return (
    <>
      <Container>
        <Header />
        <QuickSideMenu />
        <ContentBox>
          <div className="black_box" />
          <div className="content">
            <div className="content_mini">
              <div className="card_box" ref={card1Ref}>
                <img src={img1_logo} className="logo" alt="" />

                <div className="img_container">
                  <img src={img1} alt="" />
                </div>
                <div className="text_container">
                  <h2>“사업초기 경영전반에 나침반 같은 역할을 해주었던 엠에스벤터의 솔루션”</h2>
                  <div className="text_box">
                    <p>
                      처음에 회사를 설립하고 운영해 나갈 때 기초적인 기반을 잡기가 쉽지 않았는데 FMS 프로그램을
                      사용하면서 회사의 기반을 단단하게 잡을 수 있었습니다. 회사의 물류체계가 아무것도 잡혀있지 않았던
                      저희가 프로그램을 사용하면서 어떻게 회사를 운영해야 할지 정확한 계획을 세울 수 있게
                      도와주었습니다.
                    </p>
                    <p>
                      엠에스벤터의 솔루션 덕분에 회사 매출액 성장에도 큰 도움이 되었습니다. 또한 여러 가지 방면의
                      보고서를 통해 한눈에 회사의 부족한 점이 무엇인지 파악할 수 있고 그 부분을 쉽고 빠르게 보완해 나가
                      회사의 성장을 도모할 수 있었습니다.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card_box" ref={card2Ref}>
                <img src={img2_logo} className="logo small" alt="" />

                <div className="img_container">
                  <img src={img2} alt="" />
                </div>
                <div className="text_container">
                  <h2>“엠에스벤터의 솔루션 도입 후 300% 매출 신장, 거래처 수 350% 증가의 효과를 가져왔어요 ”</h2>
                  <div className="text_box">
                    <p>
                      엠에스벤터의 FMS프로그램을 사용하기 전에는 이것저것 신경 쓸 일도 많고 본사와 가맹점 간 제대로
                      해결되지 못한 문제들까지 업무에 번거로움이 많았습니다. 엠에스벤터를 만나고 나서는 업무의 질이
                      달라졌어요. 아날로그틱하게 돌아가던 업무와 시스템들이 체계적이고 선진화된 느낌으로 바뀌었습니다.
                      그 덕분에 매출과 거래처 수 증가에도 큰 효과를 얻었습니다. 300% 매출 신장과 거래처 수 350%가 증가한
                      걸 보면 정말 엠에스벤터와 함께하길 잘했다는 생각이 들어요.
                    </p>
                    <p>
                      또한 프로그램을 도입할 때부터 지금까지 느껴온 바로는 직원분들의 응대는 가히 업계 최고라고 말할 수
                      있을 것 같습니다. 사소한 문제부터 사업에 대해 고민하는 부분까지 친절하게 상담을 해주시니
                      심적으로나 업무적으로나 많은 도움이 되었습니다. 현재 프랜차이즈 사업을 하고 계시거나 구상 중이신
                      분들은 꼭 한번 엠에스벤터와 상담해 보셨으면 좋겠어요.
                    </p>
                  </div>
                </div>
              </div>

              <div className="card_box" ref={card3Ref}>
                <img src={img3_logo} className="logo small" alt="" />

                <div className="img_container">
                  <img src={img3} alt="" />
                </div>
                <div className="text_container">
                  <h2>“매일 새벽부터 비효율적이고 지루했던 업무시간이 놀랍도록 단축되었어요”</h2>
                  <div className="text_box">
                    <p>
                      엠에스벤터의 FMS를 만나기 전에는 이 회사 저 회사 알만한 프로그램들을 사용해 봤지만, 우리 회사가
                      원하는 기능을 충족해 주는 프로그램이 없어 결국 3개 이상의 프로그램을 병행해서 사용했었습니다. 여러
                      개의 프로그램을 매일 번갈아 사용하다 보니 업무시간도 오래 걸리고 효율도 많이 떨어졌죠. 무엇보다
                      월말결산이나 분기별 보고서 정리를 위한 자료를 정리할 때는 야근을 하지 않을 수 없었습니다. 이래서는
                      안되겠다는 생각에 인터넷 서치를 통해 엠에스벤터의 FMS라는 프로그램을 알게 되었습니다.
                    </p>
                    <p>
                      제가 원하는 모든 기능이 FMS에서 지원된다는 것이 믿어지지 않을 정도로 기뻤습니다. 이미 사용하고
                      있고 익숙해진 프로그램을 또다시 바꾸자고 제안하는 것이 쉽지는 않았지만 FMS의 장점을 설명하니
                      동료들과 대표님이 저의 제안에 동의해 주셨습니다. 지금은 모두들 FMS를 선택하길 잘했다고 만족하고
                      있네요. 정말 탁월한 선택을 한 것 같아요.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card_box" ref={card9Ref}>
                <img src={img9_logo} className="logo" alt="" />

                <div className="img_container">
                  <img src={img9} alt="" />
                </div>
                <div className="text_container">
                  <h2>“반복되는 업무와 복잡한 재고 관리가 사라졌어요”</h2>
                  <div className="text_box">
                    <p>
                      모든 업무를 수기로 작성하여 처리하던 저희의 업무방식은 회사의 성장세에 차질이 생기고 걸림돌이
                      되었습니다. 업무의 자동화가 필요하다고 생각하던 참에 우연치 않게 엠에스벤터를 만나게 되었어요.
                      많은 전산 업체를 만나 봤지만 엠에스벤터만큼 편리하고 완벽하게 우리의 업무를 해결한 곳은 없었어요.
                    </p>
                    <p>
                      저희가 제시하는 모든 업무의 처리가 가능하다고 답변해주는 엠에스벤터를 믿고 FMS를 사용하게
                      됐습니다. 현재 저희는 엠에스벤터가 제시하는 다양한 보고 양식과 자동 보고서 작성 기능을 이용해 업무
                      시간이 비약적으로 단축됐어요. 또한 엉켜있는 재고관리와 전산을 엠에스벤터 덕분에 안전하고 정확하게
                      세팅할 수 있었습니다.
                    </p>
                    <p>
                      24시간 긴급 유선 서비스를 제공하고 있기 때문에 어떤 일이 생겨도 우리의 업무를 응대해 주는 업체라는
                      신뢰가 만들어져 저희는 더욱 엠에스벤터를 믿고 따라갈 수 있었습니다. 회사 직원들 모두 엠에스벤터의
                      서비스에 만족하고 있네요. 전산이 엉켜있거나 업무의 수기 작업으로 인해 고통받으시는 분들은 꼭
                      엠에스벤터의 솔루션을 받아봤으면 좋겠네요.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card_box" ref={card4Ref}>
                <img src={img4_logo} className="logo" alt="" />

                <div className="img_container">
                  <img src={img4} alt="" />
                </div>
                <div className="text_container">
                  <h2>
                    “고객응대가 빠른 엠에스벤터 덕분에 본사는 물론 가맹점의 업무까지 신속하고 효율적으로 처리할 수
                    있어요”
                  </h2>
                  <div className="text_box">
                    <p>
                      엠에스벤터 프로그램을 사용하면서 좋았던 점은 고객 응대가 빠르고 친절하다는 점이었습니다. 또 고객
                      입장에서 최대한 맞춰주려고 하는 느낌을 받아서 좋았습니다. 저희 회사는 엔터프라이즈 버전을 사용하고
                      있는데요, 정말 다양한 기능이 많아 저희 회사에서 필요로 하는 모든 것을 다 충족해 주고 있습니다.
                    </p>
                    <p>
                      발주닷컴이라는 주문 앱도 유저인터페이스로부터 고객이 접근하기 쉽고, 사용방법도 쉬워서 처음 주문할
                      때도 어렵지 않게 사용하였습니다. 개인적으로 한마디 하자면 엠에스벤터 프로그램을 알게 되어서 정말
                      큰 축복입니다.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card_box" ref={card5Ref}>
                <img src={img5_logo} className="logo" alt="" />

                <div className="img_container">
                  <img src={img5} alt="" />
                </div>
                <div className="text_container">
                  <h2>“가맹점과의 마찰을 줄이고 진정한 상생의 관계로 발전할 수 있는 계기를 마련했어요”</h2>
                  <div className="text_box">
                    <p>
                      가맹점들과 주문 및 미수금으로 인해 발생하게 되는 분쟁이나 불만사항이 엠에스벤터의 발주 시스템을
                      도입한 후에 제로에 가까울 정도로 줄었어요. 덕분에 가맹점과 웃으며 발전적인 이야기를 더 많이 할 수
                      있게 되었습니다.
                    </p>
                    <p>
                      원하는 자료를 한눈에 비교 분석할 수 있는 기능들로 업무보고의 편리성은 물론, 가맹점 관리에 없어서는
                      안되는 정보들을 얻게 되었습니다. 가맹점별 특성을 고려한 맞춤 기능 활용으로 재고의 효율적인 흐름과
                      매출 증대로 수익률도 상승했습니다.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card_box" ref={card6Ref}>
                <img src={img6_logo} className="logo" alt="" />

                <div className="img_container">
                  <img src={img6} alt="" />
                </div>
                <div className="text_container">
                  <h2>“시스템의 안정화를 이루고 고객서비스에 자신감이 붙었어요”</h2>
                  <div className="text_box">
                    <p>
                      매출 미수금에 관련된 고민을 하던 중에 엠에스벤터의 FMS프로그램을 만나 잘 정리되었어요. 직원들도
                      엠에스벤터의 프로그램을 사용하기 전보다 훨씬 수월하게 업무에 임하고 있습니다. 시스템 사용 중
                      불편하거나 모르는 점이 있을 때 엠에스벤터의 적극적인 대응으로 프로그램 개발을 지원받아 시스템의
                      안정성이 확보되는 등 저의 회사에 좋은 발판이 되었습니다.
                    </p>
                    <p>
                      그리고 엠에스벤터의 고객센터는 24시간 언제든지 편하게 전화응대 서비스를 제공하고 있어 사업을
                      운영하는 데 있어서 불안하지 않게 사용할 수 있어 매우 편리합니다.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card_box" ref={card7Ref}>
                <img src={img7_logo} className="logo" alt="" />

                <div className="img_container">
                  <img src={img7} alt="" />
                </div>
                <div className="text_container">
                  <h2>
                    “엠에스벤터의 솔루션을 커스터마이징하여 심플하고 직관성있는 발주시스템으로 매장들이 만족하고 있어요”
                  </h2>
                  <div className="text_box">
                    <p>
                      다른 여러 프로그램들을 사용해 봤지만 불필요하고 쓸데없는 기능들이 너무 많아 복잡하기만 하고
                      적응하기 어려웠습니다. 그에 반해 엠에스벤터 프로그램은 간단하고 직관성이 있어 시스템을 처음
                      도입하기에 별다른 어려움이 없었고 매출, 미수금, 재고관리 등 꼭 필요한 기능들은 모두 포함이 되어
                      있어서 부족함 없이 잘 사용하고 있습니다. 스마트폰을 많이 사용하는 트렌드에 맞춰 아이폰 및
                      안드로이드폰을 통해 발주를 할 수 있다는 점도 정말 편리했습니다.
                    </p>
                    <p>
                      필요한 기능이 있어 엠에스벤터에 요청한 적이 있었는데 고객의 입장에서 내 일처럼 같이 고민해 주시고
                      해결해 주셔서 정말 감동했습니다. 엠에스벤터 프로그램을 통해 업무 효율이 많이 향상되었고 앞으로도
                      저희 '커피파는 사람들'은 엠에스벤터와 함께 성장해나가고 싶습니다.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card_box" ref={card8Ref}>
                <img src={img8_logo} className="logo" alt="" />

                <div className="img_container">
                  <img src={img8} alt="" />
                </div>
                <div className="text_container">
                  <h2>“친절한 서비스로 극대화 되는 엠에스벤터의 프로그램 효과”</h2>
                  <div className="text_box">
                    <p>
                      엠에스벤터의 솔루션을 도입 후 처음에는 간단한 주문을 통해 매출이나 수금 업무와 같은 기능만
                      사용했었는데, 시간이 지날수록 깨알 같은 기능이 있어 많이 놀라곤 했습니다. 전화를 통해 원하는
                      기능을 요구하면 무리한 요구임에도 불구하고 단순히 안된다고 하는 게 아닌 내부적인 회의를 통해
                      이유를 알려주시니 되려 배려 받는 기분이 들었습니다. 전에 사용했던 프로그램 업체에서는 기능 추가도
                      어려웠고 이미 개발된 기능에 저희가 맞춰야 하는 경우가 대부분이었는데, 그때를 생각하면 엠에스벤터의
                      서비스 마인드가 훌륭하다는 것을 실감하게 됩니다.
                    </p>
                    <p>
                      엠에스벤터의 프로그램은 사용하면 할수록 활용할 수 있는 범위와 효율성이 늘어난다고 생각합니다.
                      모르는 기능이 있으면 전화나 원격 서비스로 친절하게 알려주시거나, 직접 알려주기 위해 방문교육까지
                      진행해 주시니 정말 감사하게 생각했습니다. 새벽에 불가피한 일이 생겨 전화드려도 친절하게 맞아주시니
                      사용자를 진심으로 생각한다는 마음이 느껴져 오랜 기간 사용 중입니다.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card_box" ref={card10Ref}>
                <img src={img10_logo} className="logo" alt="" />

                <div className="img_container">
                  <img src={img10} alt="" />
                </div>
                <div className="text_container">
                  <h2>“엠에스벤터가 제시해 준 계육 가공장 솔루션은 지금까지 보지 못했던 프로그램”</h2>
                  <div className="text_box">
                    <p>
                      태성에프엔비는 35년간 닭공장을 운영하면서 프로그램의 중요성을 잘 몰랐습니다. 대부분의 프로그램
                      공급 회사는 사용자의 입장을 고려하여 프로그램을 공급하는 것이 아닌 본인들이 개발해 놓은 프로그램에
                      사용자가 맞춰야만 하는 현실이었습니다. 업무 특성상 프로그램에 의존할 수밖에 없기에 당사에 맞는
                      프로그램을 찾으려고 수많은 업체를 만나봤지만 어느 한 곳도 우리의 업무를 이해하고 반영해 준 곳은
                      없었습니다.
                    </p>
                    <p>
                      그러던 중 지인의 추천으로 엠에스벤터의 솔루션을 만나게 되었습니다. 엠에스벤터가 제시해 준 계육
                      가공장 솔루션은 지금까지 보지 못했던 프로그램이었습니다. 프로그램을 계약하기 전 나눈 몇 마디
                      만으로도 엠에스벤터가 업무를 얼마나 잘 파악하고 있는지 알 수 있었습니다. 프로그램을 도입하고
                      감탄이 멈추지 않았습니다. 복잡하게 꼬여있던 업무가 엠에스벤터의 솔루션을 도입하여 간단해졌고
                      빨라졌습니다. 직원들의 야근 시간은 줄어들게 되었고, 안전한 전산 관리로 업무 역시 안정적으로
                      돌아갔습니다.
                    </p>
                    <p>
                      저희와 같은 업종의 회사를 운영 중인 대표님들이 원치 않는 프로그램으로 많은 스트레스와 고민을 안고
                      있다는 걸 충분히 이해하고 있습니다. 만약 엠에스벤터의 솔루션 도입을 고민 중인 대표님들이 이 글은
                      본다면 고민하지 말고 상담 먼저 받아 보시기를 추천 드립니다. 여러분들도 저희처럼 엠에스벤터와 몇
                      마디만 나누게 된다면 엠에스벤터의 솔루션이 얼마나 고객의 입장에서 만들어지고 서비스되는지 알게 될
                      것입니다.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContentBox>
        <Footer />
      </Container>
    </>
  )
}

export default EffectPage
