import React, { useEffect, useState } from 'react'
import { FormContent } from './styles'
import Button from '../../components/Button'
import Input from '../../components/Input'
import AuthContainer from '../../containers/AuthContainer'
import Card from '../../components/Card'
import { useNavigate } from 'react-router-dom'

import { auth } from '../../firebase'
import { browserSessionPersistence, setPersistence, signInWithEmailAndPassword } from 'firebase/auth'
import { useRecoilValue } from 'recoil'
import authState from '../../store/authState'

import { ErrorMessageOpen } from '../../hooks/toast'

const LoginPage = () => {
  let navigate = useNavigate()
  const userData = useRecoilValue(authState)
  const [id, setId] = useState('')
  const [password, setPassword] = useState('')

  const onChangeId = e => {
    setId(e.target.value)
  }

  const onChangePassword = e => {
    setPassword(e.target.value)
  }

  const onSubmit = async e => {
    e.preventDefault()
    if (id.trim().length === 0) {
      ErrorMessageOpen('이메일을 입력해주세요.')
      return
    }

    if (password.trim().length === 0) {
      ErrorMessageOpen('비밀번호를 입력해주세요.')
      return
    }
    try {
      await signInWithEmailAndPassword(auth, id, password)
    } catch (e) {
      // console.error(e)
      ErrorMessageOpen('접속정보가 틀렸습니다.')
    }
  }

  useEffect(() => {
    if (userData && userData.userEmail !== '') {
      navigate('/admin')
    }
  }, [userData])

  return (
    <>
      <AuthContainer>
        <Card>
          <FormContent>
            <h3>LOGIN</h3>
            <form className="form_group" onSubmit={onSubmit}>
              <div className="input_group">
                <label htmlFor="id">아이디</label>
                <Input value={id} onChange={onChangeId} id="id" />
              </div>
              <div className="input_group">
                <label htmlFor="id">비밀번호</label>
                <Input value={password} onChange={onChangePassword} id="password" type="password" />
              </div>
              <div className="btn_box">
                <Button width="100%" type="submit">
                  로그인
                </Button>
                {/*<Button width="100%">회원가입 하러 가기</Button>*/}
              </div>
            </form>
          </FormContent>
        </Card>
      </AuthContainer>
    </>
  )
}

export default LoginPage
