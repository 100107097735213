import React, { useEffect, useState } from 'react'
import { QuickSideMenuContainer, TopScroll } from './styles'

// import baljuOs from '../../assets/images/balju_Setup_1.0.0(1).exe'
// import fms_order_setup from '../../assets/images/fms_order_setup.exe'
import baljuOs from '../../assets/images/balju-setup-1.0.5.exe'
import TeamViewerQS from '../../assets/images/TeamViewerQS.exe'

import arrow from '../../assets/images/arrow.png'
import { Link } from 'react-router-dom'

const QuickSideMenu = () => {
  const [sideState, setSideState] = useState(true)
  const [scrollY, setScrollY] = useState(0)
  const [ScrollActive, setScrollActive] = useState(false)

  const onClickSideToggle = () => {
    // console.log(111)
    if (sideState) {
      setSideState(false)
    } else {
      setSideState(true)
    }
  }

  //리사이즈
  useEffect(() => {
    resizeEvent()
  }, [])

  useEffect(() => {
    function onResize() {
      resizeEvent()
    }
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const resizeEvent = () => {
    const winW = document.body.clientWidth
    if (winW > 1350) {
      setSideState(true)
    } else {
      setSideState(false)
    }
  }

  //탑버튼 클릭 이벤트
  const onClickTopBtn = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
      left: 0,
    })
  }

  //탑버튼 스크롤 이벤트
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.pageYOffset)
      if (scrollY > 50) {
        setScrollActive(true)
      } else {
        setScrollActive(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [scrollY])

  return (
    <>
      <QuickSideMenuContainer className={sideState ? 'active' : ''}>
        <ul>
          <li>
            <a href={TeamViewerQS}>
              <span>원격지원</span>
            </a>
          </li>
          <li>
            <Link to="/contact">
              <span>상담하기</span>
            </Link>
          </li>
          <li>
            <a href={baljuOs} download="balju1.0.5.exe">
              <span>발주닷컴</span>
            </a>
          </li>
          <li>
            <a href="http://pf.kakao.com/_NDgsb" target="_blank" rel="noreferrer noopener">
              <span>카카오톡</span>
            </a>
          </li>
        </ul>
        <div className="customer_center">
          <span></span>
          <p>24시 긴급상담</p>
          <p className="color">1544-7120</p>
        </div>
        <button className="btn" onClick={onClickSideToggle}>
          {/* <span className="material-icons-outlined">chevron_right</span> */}
          <img src={arrow} alt="" />
        </button>
        <TopScroll className={ScrollActive ? 'active' : ''} onClick={onClickTopBtn}>
          <span>TOP</span>
          <span className="material-icons-outlined">expand_less</span>
        </TopScroll>
      </QuickSideMenuContainer>
    </>
  )
}

export default QuickSideMenu
