import React, { useEffect } from 'react'
const { kakao } = window

const KakaoMap = () => {
  useEffect(() => {
    const script = document.createElement('script')

    script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=28c4c5191a70b14680cb50e0f1b5db2e&autoload=false`
    document.head.appendChild(script)
    script.onload = () => {
      window?.kakao.maps.load(() => {
        let container = document.getElementById('map')
        let options = {
          center: new window.kakao.maps.LatLng(37.41618237604673, 126.68014161489799),
          level: 3,
        }

        let map = new window.kakao.maps.Map(container, options)
        // // 버튼 클릭에 따라 지도 이동 기능을 막거나 풀고 싶은 경우에는 map.setDraggable 함수를 사용합니다
        // function setDraggable(draggable) {
        //   // 마우스 드래그로 지도 이동 가능여부를 설정합니다
        //   map.setDraggable(draggable)
        // }
        // setDraggable()

        // 버튼 클릭에 따라 지도 확대, 축소 기능을 막거나 풀고 싶은 경우에는 map.setZoomable 함수를 사용합니다
        function setZoomable(zoomable) {
          // 마우스 휠로 지도 확대,축소 가능여부를 설정합니다
          map.setZoomable(zoomable)
        }
        setZoomable()

        // 마커가 표시될 위치입니다
        let markerPosition = new window.kakao.maps.LatLng(37.41618237604673, 126.68014161489799)

        // 마커를 생성합니다
        let marker = new window.kakao.maps.Marker({
          position: markerPosition,
        })

        // 마커가 지도 위에 표시되도록 설정합니다
        marker.setMap(map)

        let iwContent =
            '<div style="padding:5px;"><a href="https://map.kakao.com/link/map/인천 연수구 벚꽃로 130-3,37.41618237604673,126.68014161489799" style="color:#222;" target="_blank">연수구 벚꽃로 130-3</a></div>', // 인포윈도우에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다
          iwPosition = new window.kakao.maps.LatLng(37.41618237604673, 126.68014161489799) //인포윈도우 표시 위치입니다

        // 인포윈도우를 생성합니다
        let infowindow = new window.kakao.maps.InfoWindow({
          position: iwPosition,
          content: iwContent,
        })

        // 마커 위에 인포윈도우를 표시합니다. 두번째 파라미터인 marker를 넣어주지 않으면 지도 위에 표시됩니다
        infowindow.open(map, marker)
      })
    }

    return () => script.remove()
  }, [])

  // useEffect(() => {
  //   let container = document.getElementById('map')
  //
  //   let options = {
  //     center: new window.kakao.maps.LatLng(37.41618237604673, 126.68014161489799),
  //     level: 3,
  //   }
  //
  //   let map = new window.kakao.maps.Map(container, options)
  //
  //   // // 버튼 클릭에 따라 지도 이동 기능을 막거나 풀고 싶은 경우에는 map.setDraggable 함수를 사용합니다
  //   // function setDraggable(draggable) {
  //   //   // 마우스 드래그로 지도 이동 가능여부를 설정합니다
  //   //   map.setDraggable(draggable)
  //   // }
  //   // setDraggable()
  //
  //   // 버튼 클릭에 따라 지도 확대, 축소 기능을 막거나 풀고 싶은 경우에는 map.setZoomable 함수를 사용합니다
  //   function setZoomable(zoomable) {
  //     // 마우스 휠로 지도 확대,축소 가능여부를 설정합니다
  //     map.setZoomable(zoomable)
  //   }
  //   setZoomable()
  //
  //   // 마커가 표시될 위치입니다
  //   let markerPosition = new window.kakao.maps.LatLng(37.41618237604673, 126.68014161489799)
  //
  //   // 마커를 생성합니다
  //   let marker = new window.kakao.maps.Marker({
  //     position: markerPosition,
  //   })
  //
  //   // 마커가 지도 위에 표시되도록 설정합니다
  //   marker.setMap(map)
  //
  //   let iwContent =
  //       '<div style="padding:5px;"><a href="https://map.kakao.com/link/map/인천 연수구 벚꽃로 130-3,37.41618237604673,126.68014161489799" style="color:#222;" target="_blank">연수구 벚꽃로 130-3</a></div>', // 인포윈도우에 표출될 내용으로 HTML 문자열이나 document element가 가능합니다
  //     iwPosition = new window.kakao.maps.LatLng(37.41618237604673, 126.68014161489799) //인포윈도우 표시 위치입니다
  //
  //   // 인포윈도우를 생성합니다
  //   let infowindow = new window.kakao.maps.InfoWindow({
  //     position: iwPosition,
  //     content: iwContent,
  //   })
  //
  //   // 마커 위에 인포윈도우를 표시합니다. 두번째 파라미터인 marker를 넣어주지 않으면 지도 위에 표시됩니다
  //   infowindow.open(map, marker)
  // }, [])

  return (
    <>
      <div style={{ marginTop: '150px', marginBottom: '70px' }}>
        <div style={{ width: '100%', height: '400px' }} id="map"></div>
      </div>
    </>
  )
}

export default KakaoMap
