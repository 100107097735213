import React from 'react'
import { CardHeader, HeaderList } from './styles'

const ContactListHeader = () => {
  const data = [
    {
      title: '구분',
    },
    {
      title: '이름',
    },
    {
      title: '직급',
    },
    {
      title: '회사명',
    },
    {
      title: '문의시간',
    },
    {
      title: '담당자',
    },
    {
      title: '해결완료시간',
    },
    {
      title: '상세보기',
    },
    {
      title: '기타',
    },
  ]
  return (
    <>
      <CardHeader>
        {data.map((item, i) => (
          <HeaderList key={i}>
            <span>{item.title}</span>
          </HeaderList>
        ))}
      </CardHeader>
    </>
  )
}

export default ContactListHeader
