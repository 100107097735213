import React, { useEffect, useRef, useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import VoucherEventHeader from '../../components/VoucherEventHeader'
import {
  EventContainer,
  OnlyMs,
  ProgramBalju,
  ProgramFms,
  ProgramPda,
  Section1,
  Section2,
  Section3,
  Section4,
  Section5,
  Section6,
} from './styles'
import { ErrorMessageOpen, SuccessMessageOpen } from '../../hooks/toast'
import { Link } from 'react-router-dom'

import topImg from '../../assets/images/Scenes04.png'
import price from '../../assets/images/price.png'
import check from '../../assets/images/program_check.png'
import programFms from '../../assets/images/program_fms.png'
import programFms2 from '../../assets/images/FMSIMG.png'
import programBalju from '../../assets/images/program_balju.png'
import programBalJu from '../../assets/images/BALJU1.png'
import programPda from '../../assets/images/program_pda.png'
import programPda2 from '../../assets/images/PDAIMG.png'
import smallCircle from '../../assets/images/small_circle.png'
import only1 from '../../assets/images/only_1.png'
import only2 from '../../assets/images/only_2.png'
import only3 from '../../assets/images/only_3.png'
import only4 from '../../assets/images/only_4.png'
import only5 from '../../assets/images/only_5.png'
import only6 from '../../assets/images/only_6.png'
import pledge from '../../assets/images/2022kvoucher_document_pledge.pdf'
import indemnityReport from '../../assets/images/2022kvoucherz_document_saup.pdf'
import plusList from '../../assets/images/2022kvoucher_plus_list.pdf'
import { doc, setDoc } from 'firebase/firestore'
import { db } from '../../firebase'

import 'aos/dist/aos.css'
// import '../../assets/images/aos.css'
import Aos from 'aos'
import axios from 'axios'

const EventPage = () => {
  // 시작하자 마자 스크롤 위로 올리기
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      Aos.refresh()
    }, 500)
    return () => clearTimeout(timeout)
  }, [])

  //aos
  useEffect(() => {
    Aos.init({
      once: true,
      // mirror: true,
    })
    // Aos.refresh()
  })

  // 프로그램 소개 탭
  const [tabState, setTabState] = useState(1)

  const onClickTab = num => {
    setTabState(num)
  }

  //form
  const [company, setCompany] = useState('')
  const onChangeCompany = e => {
    setCompany(e.target.value)
  }

  const [name, setName] = useState('')
  const onChangeName = e => {
    setName(e.target.value)
  }

  const [staff, setStaff] = useState('')
  const onChangeStaff = e => {
    setStaff(e.target.value)
  }

  const [channel, setChannel] = useState('')
  const onChangeChannel = e => {
    setChannel(e.target.value)
  }

  const [email, setEmail] = useState('')
  const onChangeEmail = e => {
    setEmail(e.target.value)
  }

  const [phone, setNumber] = useState('')
  const onChangeNumber = e => {
    setNumber(e.target.value)
  }

  const [content, setText] = useState('')
  const onChangeText = e => {
    setText(e.target.value)
  }

  // 상담 내용 전송하는 이벤트
  const onSubmit = async e => {
    e.preventDefault()
    if (company.trim().length === 0) {
      ErrorMessageOpen('회사명을 입력해 주세요.')
      return
    }
    if (name.trim().length === 0) {
      ErrorMessageOpen('담당자 명을 입력해 주세요.')
      return
    }
    if (staff.trim().length === 0) {
      ErrorMessageOpen('거래처 수를 입력해 주세요.')
      return
    }
    if (channel.trim().length === 0) {
      ErrorMessageOpen('유입경로를 입력해 주세요.')
      return
    }
    if (email.trim().length === 0) {
      ErrorMessageOpen('이메일을 입력해 주세요.')
      return
    }
    if (phone.trim().length === 0) {
      ErrorMessageOpen('연락처를 입력해 주세요.')
      return
    }
    const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/
    if (!regPhone.test(phone.trim())) {
      ErrorMessageOpen('올바른 연락처를 입력해주세요.')
      return
    }
    if (!privacy) {
      ErrorMessageOpen('개인정보 처리방침을 체크해 주세요.')
      return
    }
    if (content.trim().length === 0) {
      ErrorMessageOpen('문의사항을 입력해 주세요.')
      return
    }

    const today = new Date()

    let dd = today.getDate()
    let mm = today.getMonth() + 1 //January is 0!
    const yyyy = today.getFullYear()
    let hh = today.getHours()
    let mi = today.getMinutes()

    if (mm < 10) {
      mm = '0' + mm
    }
    if (dd < 10) {
      dd = '0' + dd
    }
    if (hh < 10) {
      hh = '0' + hh
    }
    if (mi < 10) {
      mi = '0' + mi
    }

    const createdAt = `${yyyy}-${mm}-${dd} ${hh}:${mi}`
    const _name = yyyy + mm + dd
    const RandomNumber = Math.random().toString(36).substr(2, 11)
    const id = `notice${RandomNumber}${_name}`

    const data = {
      selectCheck: 5,
      company,
      name,
      staff,
      phone,
      email,
      channel,
      content,
      createdAt,
      id,
      state: false,
      person: '',
    }

    try {
      await axios.post('/api/customer/homepage', {
        selectCheck: data.selectCheck,
        customer_name: data.name,
        customer_phone: data.phone,
        customer_company: data.company,
        inquiry_desc: data.content,
        inquiry_type: 26,
        received_date: data.createdAt,
        staff: data.staff,
        inflow: data.channel,
        email: data.email,
      })
      await axios.post('https://socket.relayfms.com/contact')
      SuccessMessageOpen('문의사항 접수가 완료 되었습니다.')
      setCompany('')
      setName('')
      setStaff('')
      // setGrade('')
      setNumber('')
      setEmail('')
      setChannel('')
      setText('')
      setPrivacy(!privacy)
    } catch (e) {
      console.error('Error adding document: ', e)
    }
  }

  // 개인정보 처리방침
  const [privacy, setPrivacy] = useState(false)

  const onChangePrivacy = e => {
    setPrivacy(!privacy)
  }
  const onClickWindowPopup = e => {
    e.preventDefault()
    const options = 'top=10, left=10, width=600, height=800, status=no, menubar=no, toolbar=no, resizable=no'
    window.open('/privacy', 'popup', options)
  }

  const eventForm = useRef(null)

  //scrollto event
  const onClickScrollTo = () => {
    window.scrollTo({
      top: eventForm.current.getBoundingClientRect().top,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <>
      <Header />
      <EventContainer>
        <Section1 data-aos="fade-down" data-aos-offset="300" data-aos-easing="ease-in-sine">
          <div className="topWrap">
            <div className="topText">
              <p>K-비대면 바우처 공급 기업 선정</p>
              <div className="topTitle">
                <p>
                  <span>70%</span> 할인된 금액으로
                </p>
                <p>엠에스벤터와 함께하세요!</p>
              </div>
              <p>파격적인 가격으로 엠에스벤터의 솔루션을 받아보세요.</p>
              <p>단순한 물류관리 기능을 넘어서서 회사의 기반을 다지고</p>
              <p>확장시킬 수 있는 좋은 기회입니다.</p>
              <div className="topButton">
                <button type="button" onClick={onClickScrollTo}>
                  지금 바로 문의하기
                </button>
                <a href="https://www.k-voucher.kr/page/productSearch?search_category=bmm.cmpny_nm&search_keyword=%EC%97%A0%EC%97%90%EC%8A%A4%EB%B2%A4%ED%84%B0">
                  <button>바우처 상품 바로가기</button>
                </a>
              </div>
            </div>
            <div className="topImg">
              <img src={topImg} />
            </div>
          </div>
        </Section1>
        <Section2>
          <div className="titleWrap" data-aos="fade-down" data-aos-easing="ease-in-sine">
            <p className="title">수요기업 특별혜택 상품 구성</p>
            <p className="subTitle">비대면 바우처 플랫폼에 수요기업으로 선정되셨나요?</p>
            <p className="subTitle">70% 할인된 금액으로 엠에스벤터의 솔루션을 받아보세요!</p>
          </div>
          <div className="sec2Img" data-aos="fade-down" data-aos-easing="ease-in-sine" data-aos-once="false">
            <img src={price} />
          </div>
        </Section2>
        <Section3>
          <div className="titleWrap" data-aos="fade-down" data-aos-offset="300" data-aos-easing="ease-in-sine">
            <p className="title">똑똑하고 편리한 프랜차이즈 관리</p>
            <p className="subTitle">필요한 기능은 다 있습니다.</p>
            <p className="subTitle">프로그램이 설치된 곳이라면 어디든지!</p>
          </div>
          <div className="programBox" data-aos="fade-down" data-aos-easing="ease-in-sine">
            <ul className="tapTitle">
              <li className={tabState === 1 ? 'active' : ''} onClick={() => onClickTab(1)}>
                FMS물류관리
              </li>
              <li className={tabState === 2 ? 'active' : ''} onClick={() => onClickTab(2)}>
                발주닷컴
              </li>
              <li className={tabState === 3 ? 'active' : ''} onClick={() => onClickTab(3)}>
                PDA이력관리
              </li>
            </ul>
            <div data-aos="fade-down" data-aos-easing="ease-in-sine">
              {tabState === 1 ? (
                <>
                  <ProgramFms>
                    <div className="fmsImg">
                      <img src={programFms2} />
                    </div>
                    <div className="fmsText">
                      <ul>
                        <li>
                          <img src={check} />
                          프랜차이즈/식품 물류유통관리 프로그램
                        </li>
                        <li>
                          <img src={check} />
                          본사의 프로그램 설정으로 매출처 관리 원활
                        </li>
                        <li>
                          <img src={check} />
                          다양한 보고서 양식으로 업무시간 단축
                        </li>
                        <li>
                          <img src={check} />
                          발주닷컴과 높은 연계성으로 효율적인 관리 가능
                        </li>
                        <li>
                          <img src={check} />
                          사업장 규모와 업무형태에 따라 맞춤 서비스 제공
                        </li>
                      </ul>
                    </div>
                  </ProgramFms>
                </>
              ) : tabState === 2 ? (
                <>
                  <ProgramBalju>
                    <div className="baljuImg">
                      <img src={programBalJu} />
                    </div>
                    <div className="baljuText">
                      <ul>
                        <li>
                          <img src={check} />
                          가맹점주들을 위한 편리한 발주전용 어플
                        </li>
                        <li>
                          <img src={check} />
                          PC, 안드로이드 핸드폰, 아이폰 상관없이 모든 환경에서 주문 가능
                        </li>
                        <li>
                          <img src={check} />
                          FMS와 높은 연계성으로 본사 설정사항 자동으로 실시간 적용
                        </li>
                        <li>
                          <img src={check} />
                          직관적인 UI디자인으로 남녀노소 모두 편리한 발주 가능
                        </li>
                        <li>
                          <img src={check} />
                          실시간으로 재고수량을 반영하여 주문오류 및 누락 방지
                        </li>
                      </ul>
                    </div>
                  </ProgramBalju>
                </>
              ) : (
                <>
                  <ProgramPda>
                    <div className="pdaImg">
                      <img src={programPda2} />
                    </div>
                    <div className="pdaText">
                      <ul>
                        <li>
                          <img src={check} />
                          PDA바코드 시스템으로 완벽한 이력관리
                        </li>
                        <li>
                          <img src={check} />
                          재고를 개별적으로 관리하여 재고 손실 ZERO
                        </li>
                        <li>
                          <img src={check} />
                          농축수산물 및 계육의 이력관리와 신고를 편리하게!
                        </li>
                        <li>
                          <img src={check} />
                          중량제품을 수량과 중량재고로 분리하여 정확한 재고관리
                        </li>
                        <li>
                          <img src={check} />
                          PDA 스캔한 자료가 실시간으로 FMS에 전송되어 수기작업 감소
                        </li>
                      </ul>
                    </div>
                  </ProgramPda>
                </>
              )}
            </div>
          </div>
        </Section3>
        <Section4>
          <div className="titleWrap" data-aos="fade-down" data-aos-easing="ease-in-sine">
            <p className="title">수요기업 신청 및 사용방법</p>
            <p className="subTitle">신규, 기존 고객 모두 신청 가능합니다.</p>
            <p className="subTitle">사업 예산이 소진되기 전에 서둘러 구매하세요!</p>
          </div>
          <div className="contentsWrap">
            <div className="cont cont1" data-aos="flip-left" data-aos-easing="ease-in-sine">
              <div className="circle">
                <span className="circle_item"></span>
                <div className="text_item">
                  <p className="number">1</p>
                  <p className="text">수요기업 신청</p>
                </div>
              </div>
              <div className="decoration">
                <img src={smallCircle} />
              </div>
              <div className="desc">
                <p>신청 조건과 제출서류를</p>
                <p>잘 확인하여 신청합니다.</p>
              </div>
            </div>
            <div className="cont cont2" data-aos="flip-left" data-aos-easing="ease-in-sine" data-aos-delay="200">
              <div className="circle">
                <span className="circle_item"></span>
                <div className="text_item">
                  <p className="number">2</p>
                  <p className="text">결제수단 선택</p>
                </div>
              </div>
              <div className="decoration">
                <img src={smallCircle} />
              </div>
              <div className="desc">
                <p>신한카드 or 제로페이</p>
                <p>추후 변경이 불가능하니</p>
                <p>신중히 선택하세요.</p>
              </div>
            </div>
            <div className="cont cont3" data-aos="flip-left" data-aos-easing="ease-in-sine" data-aos-delay="400">
              <div className="circle">
                <span className="circle_item"></span>
                <div className="text_item">
                  <p className="number">3</p>
                  <p className="text">공급기업 선택</p>
                </div>
              </div>
              <div className="decoration">
                <img src={smallCircle} />
              </div>
              <div className="desc">
                <p>엠에스벤터의 서비스 중</p>
                <p>원하는 규모의 프로그램,</p>
                <p>결제금액을 선택하세요.</p>
              </div>
            </div>
            <div className="cont cont4" data-aos="flip-left" data-aos-easing="ease-in-sine" data-aos-delay="600">
              <div className="circle">
                <span className="circle_item"></span>
                <div className="text_item">
                  <p className="number">4</p>
                  <p className="text">최종 결제하기</p>
                </div>
              </div>
              <div className="decoration">
                <img src={smallCircle} />
              </div>
              <div className="desc">
                <p>공급기업에서 거래를</p>
                <p>수락하면 최종 결제가</p>
                <p>됩니다</p>
              </div>
            </div>
            <div className="cont cont5" data-aos="flip-left" data-aos-easing="ease-in-sine" data-aos-delay="800">
              <div className="circle">
                <span className="circle_item"></span>
                <div className="text_item">
                  <p className="number">5</p>
                  <p className="text">엠에스벤터 솔루션</p>
                  <p className="text">받아보기</p>
                </div>
              </div>
              <div className="decoration">
                <img src={smallCircle} />
              </div>
              <div className="desc">
                <p>엠에스벤터의 솔루션을</p>
                <p>마음껏 누려보세요!</p>
              </div>
            </div>
          </div>
        </Section4>
        <Section5>
          <div className="titleWrap" data-aos="fade-down" data-aos-easing="ease-in-sine">
            <p className="title">제출 서류</p>
            <p className="subTitle">선착순 마감이므로 필요 서류를 살펴보시고 미리 준비해주세요.</p>
            <p className="subTitle">각 서류는 스캔하여 PDF파일로 저장해주세요.</p>
          </div>
          <div className="filsu" data-aos="fade-down" data-aos-easing="ease-in-sine">
            <p className="filsuTitle">필수서류</p>
            <div className="filsuDocument">
              <div className="condition">
                <p>사업신청 마감일(4.14) 기준</p>
                <p>최근 3개월 이내 발급분에 한함</p>
              </div>
              <ul>
                <li data-aos="fade-down" data-aos-easing="ease-in-sine" data-aos-delay="100">
                  <div className="documentName">
                    <p>사업자등록증/</p>
                    <p>사업자등록증명원</p>
                  </div>
                  <div className="deco">
                    <img src={smallCircle} />
                  </div>
                  <div className="site">
                    <p>세무서 또는 국세청 홈텍스</p>
                    <a
                      href="https://www.hometax.go.kr/websquare/websquare.wq?w2xPath=/ui/pp/index_pp.xml"
                      target="_blank"
                    >
                      www.hometax.go.kr
                    </a>
                  </div>
                </li>
                <li data-aos="fade-down" data-aos-easing="ease-in-sine" data-aos-delay="200">
                  <div className="documentName">
                    <p>국세 완납증명서</p>
                  </div>
                  <div className="deco">
                    <img src={smallCircle} />
                  </div>
                  <div className="site">
                    <p>세무서 또는 국세청 홈텍스</p>
                    <a
                      href="https://www.hometax.go.kr/websquare/websquare.wq?w2xPath=/ui/pp/index_pp.xml"
                      target="_blank"
                    >
                      www.hometax.go.kr
                    </a>
                  </div>
                </li>
                <li data-aos="fade-down" data-aos-easing="ease-in-sine" data-aos-delay="300">
                  <div className="documentName">
                    <p>지방세 완납</p>
                    <p>증명서</p>
                  </div>
                  <div className="deco">
                    <img src={smallCircle} />
                  </div>
                  <div className="site">
                    <p>주민센터 또는 정부24</p>
                    <a
                      href="https://www.gov.kr/search?srhQuery=%EC%A7%80%EB%B0%A9%EC%84%B8%EC%99%84%EB%82%A9%EC%A6%9D%EB%AA%85%EC%84%9C"
                      target="_blank"
                    >
                      www.gov.kr
                    </a>
                  </div>
                </li>
                <li data-aos="fade-down" data-aos-easing="ease-in-sine" data-aos-delay="400">
                  <div className="documentName">
                    <p>법인등기부등본</p>
                  </div>
                  <div className="deco">
                    <img src={smallCircle} />
                  </div>
                  <div className="site">
                    <p>등기소 또는 대법원 인터넷등기소</p>
                    <a href="http://www.iros.go.kr/PMainJ.jsp" target="_blank">
                      www.iros.go.kr
                    </a>
                  </div>
                </li>
                <li data-aos="fade-down" data-aos-easing="ease-in-sine" data-aos-delay="500">
                  <div className="documentName">
                    <p>4대보험 사업장</p>
                    <p>가입자 명부</p>
                  </div>
                  <div className="deco">
                    <img src={smallCircle} />
                  </div>
                  <div className="site">
                    <p>4대 사회보험 정보연계센터</p>
                    <a href="https://www.4insure.or.kr/ins4/ptl/Main.do" target="_blank">
                      www.4insure.or.kr
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <span className="line"></span>
            <div className="filsuDocument">
              <div className="condition">
                <p>사업 신청기간 내</p>
                <p>작성분에 한함</p>
              </div>
              <ul>
                <li data-aos="fade-down" data-aos-easing="ease-in-sine" data-aos-delay="400">
                  <div className="documentName">
                    <p>청렴서약서</p>
                  </div>
                  <div className="deco">
                    <img src={smallCircle} />
                  </div>
                  <div className="site">
                    <p>양식다운받기</p>
                    <a href={pledge} target="_blank">
                      <span className="material-icons-outlined">file_download</span>
                    </a>
                  </div>
                </li>
                <li data-aos="fade-down" data-aos-easing="ease-in-sine" data-aos-delay="500">
                  <div className="documentName">
                    <p>사업수행 확약서</p>
                  </div>
                  <div className="deco">
                    <img src={smallCircle} />
                  </div>
                  <div className="site">
                    <p>양식다운받기</p>
                    <a href={indemnityReport} target="_blank">
                      <span className="material-icons-outlined">file_download</span>
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="plus" data-aos="fade-down" data-aos-easing="ease-in-sine">
            <p className="plusTitle">가점사항</p>
            <p>최대 20점이며 사업신청 마감일(4.14)을 기준으로 유요한 인증서에 한함</p>
            <div className="plusList">
              <ul data-aos="zoom-in" data-aos-easing="ease-in-sine" data-aos-delay="300" data-aos-duration="500">
                <li>벤처기업</li>
                <li>이노비즈기업</li>
                <li>메인비즈기업</li>
                <li>강소기업</li>
                <li>소재/부품/장비 강소기업</li>
                <li>내일채움공제 가입기업</li>
              </ul>
              <ul data-aos="zoom-in" data-aos-easing="ease-in-sine" data-aos-delay="400" data-aos-duration="600">
                <li>청년내일채움공제 가입기업</li>
                <li>장애인기업</li>
                <li>여성기업</li>
                <li>가족친화인증기업</li>
                <li>창업초기기업(3년)</li>
              </ul>
              <p className="notice">* 정책우대 가점 항목당 3점이며 최대 10점을 넘길 수 없음 *</p>
              <p
                className="staff"
                data-aos="zoom-in"
                data-aos-easing="ease-in-sine"
                // data-aos-delay="1100"
                data-aos-duration="500"
              >
                고용원 수 : 2~4인(2점), 5~9인(4점), 10~19인(6점), 20~29인(8점), 30~39인(10점)
              </p>
              <div className="listDown">
                <a
                  href={plusList}
                  data-aos="zoom-in"
                  data-aos-easing="ease-in-sine"
                  // data-aos-delay="1100"
                  data-aos-duration="500"
                >
                  가점리스트 다운받기<span className="material-icons-outlined">file_download</span>
                </a>
              </div>
            </div>
          </div>
        </Section5>
        <OnlyMs>
          <div className="titleWrap" data-aos="fade-down" data-aos-easing="ease-in-sine">
            <p className="title">ONLY MSBENTER</p>
            <p className="subTitle">오직 엠에스벤터에서만 누릴 수 있는</p>
            <p className="subTitle">특별한 서비스를 만나보세요!</p>
          </div>
          <ul>
            <li data-aos="fade-down" data-aos-easing="ease-in-sine">
              <div className="OnlyImg">
                <img src={only1} alt="" />
              </div>
              <div className="OnlyText">
                <div className="title">기업 맞춤 컨설팅</div>
                <div className="desc">
                  프로그램 도입 전, 기업의 규모와
                  <br /> 업종에 맞는 프로그램을 맞춤 <br />
                  컨설팅하여 합리적인 프로그램 제공
                </div>
              </div>
            </li>
            <li data-aos="fade-down" data-aos-easing="ease-in-sine" data-aos-delay="100">
              <div className="OnlyImg">
                <img src={only2} alt="" />
              </div>
              <div className="OnlyText">
                <div className="title">1:1 전담 매니저</div>
                <div className="desc">
                  한 달간 1:1 전담 매니저를 배정하여 <br />
                  프로그램 구축 지원 및 집중 케어 <br />
                  서비스를 제공
                </div>
              </div>
            </li>
            <li data-aos="fade-down" data-aos-easing="ease-in-sine" data-aos-delay="200">
              <div className="OnlyImg">
                <img src={only3} alt="" />
              </div>
              <div className="OnlyText">
                <div className="title">무료 상담/교육</div>
                <div className="desc">
                  무료로 온라인, 오프라인 상담 및 <br />
                  교육 서비스를 제공하여 원활한 <br />
                  프로그램 사용을 지원
                </div>
              </div>
            </li>
            <li data-aos="fade-down" data-aos-easing="ease-in-sine">
              <div className="OnlyImg">
                <img src={only4} alt="" />
              </div>
              <div className="OnlyText">
                <div className="title">스타트업 지원</div>
                <div className="desc">
                  창업한 지 1년 이내 기업 전폭 지원! <br />
                  프로그램 한달 무료제공과 전문가의 <br />
                  전산 세팅 솔루션 제공
                </div>
              </div>
            </li>
            <li data-aos="fade-down" data-aos-easing="ease-in-sine" data-aos-delay="100">
              <div className="OnlyImg">
                <img src={only5} alt="" />
              </div>
              <div className="OnlyText">
                <div className="title">긴급 콜센터 운영</div>
                <div className="desc">
                  전문 매니저의 24시간 응대
                  <br /> 서비스로 긴급한 경우 시간에 관계 <br /> 없이 즉각적인 대응 가능
                </div>
              </div>
            </li>
            <li data-aos="fade-down" data-aos-easing="ease-in-sine" data-aos-delay="200">
              <div className="OnlyImg">
                <img src={only6} alt="" />
              </div>
              <div className="OnlyText">
                <div className="title">프리미엄 개발 서비스</div>
                <div className="desc">
                  무료로 표준 개발 서비스 진행 및 <br />
                  고객사의 요구사항을 적극 반영한 <br />
                  지속적인 기능 업데이트
                </div>
              </div>
            </li>
          </ul>
        </OnlyMs>
        <Section6 ref={eventForm}>
          <div className="titleWrap" data-aos="fade-down" data-aos-easing="ease-in-sine">
            <p className="title">비대면 바우처 상담 문의</p>
            <p className="subTitle">엠에스벤터의 솔루션을 파격적인 가격에 이용할 수 있는 기회!</p>
            <p className="subTitle">빠르게 시작할수록 좋습니다, 놓치지 마세요!</p>
          </div>
          <form onSubmit={onSubmit} data-aos="fade-down" data-aos-easing="ease-in-sine">
            <ul>
              <li>
                <label htmlFor="">
                  <input type="text" placeholder="회사명" value={company} onChange={onChangeCompany} maxLength="100" />
                </label>
                <label htmlFor="">
                  <input type="text" placeholder="담당자명" value={name} onChange={onChangeName} maxLength="20" />
                </label>
              </li>
              <li>
                <label htmlFor="">
                  <input type="text" placeholder="거래처 수" value={staff} onChange={onChangeStaff} />
                </label>
                <label htmlFor="">
                  <input
                    type="text"
                    placeholder="유입경로"
                    value={channel}
                    onChange={onChangeChannel}
                    maxLength="100"
                  />
                </label>
              </li>
              <li>
                <label htmlFor="">
                  <input type="text" placeholder="이메일" value={email} onChange={onChangeEmail} maxLength="50" />
                </label>
                <label htmlFor="">
                  <input type="text" placeholder="연락처" value={phone} onChange={onChangeNumber} maxLength="20" />
                </label>
              </li>
              <div className="privacy">
                <Link to="/privacy" onClick={onClickWindowPopup}>
                  개인정보처리방침
                </Link>
                <input type="checkbox" id="check1" checked={privacy} onChange={onChangePrivacy} />
                <label htmlFor="check1" />
              </div>
              <div className="textArea">
                <label htmlFor="">
                  <textarea
                    type="text"
                    placeholder="문의하실 내용 및 상담 가능한 시간을 적어주세요."
                    value={content}
                    onChange={onChangeText}
                    maxLength="1000"
                  />
                </label>
              </div>
            </ul>
            <div className="submit">
              <input value={'제출하기'} type={'submit'} />
            </div>
          </form>
        </Section6>

        <Footer />
      </EventContainer>
    </>
  )
}

export default EventPage
