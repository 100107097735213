// import loadable from '@loadable/component'
import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'

import MainPage from './routes/MainPage'
import TestPage from './routes/TestPage'
import LoginPage from './routes/LoginPage'
import AdminPage from './routes/AdminPage'
import AdminNoticePage from './routes/AdminNoticePage'
import AdminNoticeWritePage from './routes/AdminNoticeWritePage'
import StandardSolutionPage from './routes/StandardSolutionPage'
import ObjectSolutionPage from './routes/ObjectSolutionPage'
import AboutPage from './routes/AboutPage'
import EffectPage from './routes/EffectPage'
import ContactPage from './routes/ContactPage'
import AdminContentPage from './routes/AdminContentPage'
import PrivacyPage from './routes/PrivacyPage'
import NotPage from './routes/NotPage'
import EventPage from './routes/EventPage'

// const LoginPage = loadable(() => import('./routes/LoginPage'))
// const AdminPage = loadable(() => import('./routes/AdminPage'))
// const AdminNoticePage = loadable(() => import('./routes/AdminNoticePage'))
// const AdminNoticeWritePage = loadable(() => import('./routes/AdminNoticeWritePage'))
// const PrivacyPage = loadable(() => import('./routes/PrivacyPage'))
// const NotPage = loadable(() => import('./routes/NotPage'))

import authState from './store/authState'
import { useRecoilState } from 'recoil'

import { auth } from './firebase'
import { onAuthStateChanged } from 'firebase/auth'

import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
import AdminSettingPage from './routes/AdminSettingPage'

function App() {
  const [loggedInUser, setLoggedInUser] = useRecoilState(authState)

  useEffect(() => {
    onAuthStateChanged(auth, user => {
      // console.log(user)
      setLoggedInUser({ userEmail: user?.email || '' })
    })
  }, [])

  return (
    <>
      <Routes>
        <Route index path="/" element={<MainPage />} />
        <Route path="/test" element={<TestPage />} />
        <Route path="admin">
          <Route index element={<AdminPage />} />
          <Route path="notice" element={<AdminNoticePage />} />
          <Route path="settings" element={<AdminSettingPage />} />
          <Route path="write" element={<AdminNoticeWritePage />}>
            <Route path=":id" element={<AdminNoticeWritePage />} />
          </Route>
          <Route path="contact" element={<AdminContentPage />} />
        </Route>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/standard_solution" element={<StandardSolutionPage />} />
        <Route path="/object_solution" element={<ObjectSolutionPage />} />
        <Route path="/effect" element={<EffectPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="*" element={<NotPage />} />
        <Route path="/event" element={<EventPage />} />
      </Routes>
      <ToastContainer />
    </>
  )
}

export default App
