import styled, { css } from 'styled-components'

export const ButtonBox = styled.button`
  width: ${props => props.width};
  outline: none;
  border: none;
  box-sizing: border-box;
  //height: 2rem;
  font-size: 0.875rem;
  padding: 0.75rem 1.2rem;
  background: #f4b81c;
  background: ${props => (props.theme === 'primary' ? '#f4b81c' : props.theme === 'secondary' ? '#EF4444' : '#e9ecef')};
  color: ${props => (props.theme === 'tertiary' ? '#495057' : '#fff')};
  border-radius: 0.25rem;
  line-height: 1;
  font-weight: 600;
  cursor: pointer;

  ${props =>
    props.loading === 'true'
      ? css`
          opacity: 0.8;
          pointer-events: none;
        `
      : css`
          opacity: 1;
        `}

  &:focus {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  }
  &:hover {
    opacity: 0.8;
  }
`
