import styled from 'styled-components'

export const HeaderBox = styled.div`
  width: 100%;
  height: 70px;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 101;
  background-color: #fff;
  padding: 0 20px;
  box-sizing: border-box;
  /* border-bottom: 1px solid rgba(0, 27, 55, 0.1); */
  box-shadow: 5px 5px 20px rgb(0 0 0 / 15%);
  transition: transform 0.2s ease-out;
  &.active {
    transform: translateY(-70px);
  }
  header {
    width: 100%;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    //padding-right: 100px;

    .logo_box {
      width: 130px;
      margin-right: 120px;
      position: relative;
      z-index: 21;
    }
    .menu_list_box {
      flex: 1;
      position: relative;
      z-index: 30;
      height: 100%;

      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;

        li {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        a {
          font-size: 18px;
          font-weight: 500;
          letter-spacing: -1px;
          color: #222;
          position: relative;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        a:before {
          content: '';
          display: block;
          position: absolute;
          left: 50%;
          top: calc(100% - 4px);
          transform: translateX(-50%);
          width: 0;
          height: 4px;
          background-color: #f4b81c;
          transition: 0.3s;
        }
        a:hover:before {
          width: 100%;
        }
        a.active {
          color: #febe00;
        }
      }
    }

    .button_box {
      display: none;
    }

    .menu_box {
      width: 30px;
      height: 25px;
      box-sizing: border-box;
      cursor: pointer;
      position: relative;
      margin-left: auto;
      display: none;
      z-index: 101;
      //display: none;
      //right: 1rem;
      //top: 1rem;
      //z-index: 10;
      .bar {
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: #000;
        transition: 0.3s ease-out;
        border-radius: 3px;
      }
      .bar1 {
        left: 0;
        top: 0;
      }
      .bar2 {
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      .bar3 {
        left: 0;
        top: 100%;
        transform: translateY(-100%);
      }
    }
    .menu_box.active {
      .bar1 {
        left: 0;
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }
      .bar2 {
        opacity: 0;
      }
      .bar3 {
        left: 0;
        top: 50%;
        transform: translateY(-50%) rotate(-45deg);
      }
    }

    .support_box {
      display: none;
      position: fixed;
      right: 80px;
      top: 120px;
      z-index: 21;
      .support_content {
        box-shadow: 6px 5px 15px 0 rgb(0 0 0 / 10%);
        display: block;
        padding: 1rem;
        width: 95px;
        box-sizing: border-box;
        border-radius: 10px;
        margin-bottom: 10px;
        outline: none;
        background-color: #fff;
        border: 2px solid #febe00;

        img {
          display: block;
          width: 100%;
          height: auto;
          margin-bottom: 10px;
        }
        p {
          line-height: 1.2;
          text-align: center;
          font-weight: 500;
        }
        .txt {
          font-size: 16px;
          letter-spacing: -1px;
        }
        .mb {
          margin-bottom: 10px;
        }
        .color {
          font-size: 20px;
          color: #f4b81c;
          font-weight: bold;
        }
      }
      .support2 {
        cursor: pointer;
        color: #000;
      }
    }

    .mobile_back_box {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 69px;
      background-color: #fff;
      z-index: 20;
    }

    @media screen and (max-width: 1000px) {
      .menu_list_box {
        display: flex;
        flex-direction: column;
        position: fixed;
        padding-top: 70px;
        left: 0;
        top: -100%;
        width: 100%;
        height: 100%;
        z-index: 10;
        box-sizing: border-box;
        background-color: #fff;
        transition: 0.3s ease-out;
        overflow: auto;
        ul {
          flex-direction: column;
          height: auto;
          li {
            width: 100%;
          }
          a {
            width: 100%;
            padding: 2rem;
            box-sizing: border-box;
            display: block;
            text-align: center;
          }
          a:hover:before {
            width: 0;
          }
        }
      }
      .menu_list_box.active {
        display: flex;
        top: 0;
      }
      .menu_box {
        display: block;
      }
      .support_box {
        display: none;
      }

      .button_box {
        margin-top: auto;
        margin-bottom: 50px;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        box-sizing: border-box;

        a {
          height: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          border-radius: 10px;
          font-size: 16px;
          font-weight: 500;
        }
        .kakao_list {
          color: #f4b81c;
          margin-bottom: 20px;
          /* border: 3px solid #f4b81c; */
        }
        .support_list {
          /* color: #fff; */
          color: #f4b81c;
          /* background-color: #f4b81c; */
          margin-bottom: 20px;
        }
        .app_down_link {
          /* border: 3px solid #f4b81c; */
          color: #f4b81c;
        }
      }
    }
  }

  @media screen and (max-width: 1520px) {
    header {
      padding-right: 100px;
      .support_box {
        right: 10px;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    header {
      padding-right: 0;
    }
  }
`
