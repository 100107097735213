import React, { useEffect, useRef, useState } from 'react'
import { Container, ContentContainerBox, TextBox, TimeLineBox, TopControllerBox } from './styles'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import QuickMenuBox from '../../components/QuickMenuBox'
import Footer from '../../components/Footer'

import { useRecoilState, useRecoilValue } from 'recoil'
import solutionActiveState from '../../store/solutionActiveState'
import solutionScrollState from '../../store/solutionScrollState'

import box_img from '../../assets/images/box_icon.png'

import standard_1_bg from '../../assets/images/standard_1_bg.png'
// import standard_1_bg from '../../assets/images/standard_1_bg.jpg'
import standard_1_icon1 from '../../assets/images/standard_1_icon1.png'
import standard_1_icon2 from '../../assets/images/standard_1_icon2.png'
import standard_1_icon3 from '../../assets/images/standard_1_icon3.png'
import standard_1_icon4 from '../../assets/images/standard_1_icon4.png'

import standard_2_bg from '../../assets/images/standard_2_bg.png'
import standard_2_icon1 from '../../assets/images/standard_2_icon1.png'
import standard_2_icon2 from '../../assets/images/standard_2_icon2.png'
import standard_2_icon3 from '../../assets/images/standard_2_icon3.png'

import standard_3_bg from '../../assets/images/standard_3_bg.png'
import standard_3_icon1 from '../../assets/images/standard_3_icon1.png'
import standard_3_icon2 from '../../assets/images/standard_3_icon2_.png'

import standard_4_bg from '../../assets/images/standard_4_bg.png'
import standard_4_icon1 from '../../assets/images/standard_4_icon1.png'
import standard_4_icon2 from '../../assets/images/standard_4_icon2.png'

import standard_5_bg from '../../assets/images/standard_5_bg.png'
import standard_5_icon1 from '../../assets/images/standard_5_icon1.png'
import standard_5_icon2 from '../../assets/images/standard_5_icon2.png'

import standard_6_bg from '../../assets/images/standard_6_bg.png'
import standard_6_icon1 from '../../assets/images/standard_6_icon1.png'
import standard_6_icon2 from '../../assets/images/standard_6_icon2.png'

import standard_7_bg from '../../assets/images/standard_7_bg.png'
import standard_7_icon1 from '../../assets/images/standard_7_icon1.png'
import standard_7_icon2 from '../../assets/images/standard_7_icon2.png'
import standard_7_icon3 from '../../assets/images/standard_7_icon3.png'

import standard_8_bg from '../../assets/images/standard_8_bg.png'
import standard_8_icon1 from '../../assets/images/standard_8_icon1.png'
import standard_8_icon2 from '../../assets/images/standard_8_icon2.png'

import standard_9_bg from '../../assets/images/standard_9_bg.png'
import standard_9_icon1 from '../../assets/images/standard_9_icon1.png'
import standard_9_icon2 from '../../assets/images/standard_9_icon2.png'

import standard_10_bg from '../../assets/images/standard_10_bg.png'
import standard_10_icon1 from '../../assets/images/standard_10_icon1.png'
import standard_10_icon2 from '../../assets/images/standard_10_icon2.png'

import standard_11_bg from '../../assets/images/standard_11_bg.png'
import standard_11_icon1 from '../../assets/images/standard_11_icon1.png'
import standard_11_icon2 from '../../assets/images/standard_11_icon2.png'

import skateboar from '../../assets/images/skateboar.svg'
import QuickSideMenu from '../../components/QuickSideMenu'

const StandardSolutionPage = () => {
  let navigate = useNavigate()
  const [isActiveNumber, setIsActiveNumber] = useRecoilState(solutionActiveState)
  const scrollState = useRecoilValue(solutionScrollState)
  // const [isActiveNumber, setIsActiveNumber] = useState(1)
  const [closedNumber, setClosedNumber] = useState(1)
  const contentRef = useRef(null)

  useEffect(() => {
    let timeoutId

    timeoutId = setTimeout(() => {
      setClosedNumber(isActiveNumber)
    }, 200)

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [isActiveNumber])

  const onClickTimeLine = id => {
    setIsActiveNumber(id)
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    if (scrollState) {
      const scroll = contentRef.current.offsetTop
      window.scrollTo(0, scroll - 70)
    } else {
      window.scrollTo(0, 0)
    }
  }, [scrollState])

  const onClickScrollMove = () => {
    const scroll = contentRef.current.offsetTop
    window.scrollTo(0, scroll - 70)
  }

  const [timeActive, setTimeActive] = useState(false)

  useEffect(() => {
    function onScroll() {
      if (contentRef && contentRef.current && contentRef.current.getBoundingClientRect().top < 190) {
        setTimeActive(true)
      } else {
        setTimeActive(false)
      }
    }
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <>
      <Container>
        <Header />
        <QuickSideMenu />
        <TopControllerBox>
          <div className="content_box">
            <div className="max_content">
              <h2>
                편리함이 장착된
                <br /> <strong>성공솔루션</strong>을 제공합니다.
              </h2>
              <div className="box_content">
                <div className="text_content">
                  {closedNumber === 1 ? (
                    <>
                      <TextBox isActive={isActiveNumber === 1}>
                        <h3>FMS 물류관리</h3>
                        <p>업계 최고의 표준화된 다양한 기능은</p>
                        <p>귀사의 까다로운 요구사항을 충족해 드릴 것입니다.</p>
                      </TextBox>
                    </>
                  ) : closedNumber === 2 ? (
                    <>
                      <TextBox isActive={isActiveNumber === 2}>
                        <h3>발주닷컴</h3>
                        <p>거래처별로 다양한 거래조건을 자유롭게</p>
                        <p>설정하여 발주업무를 번거로움 없이</p>
                        <p>클릭 한 번으로 처리할 수 있습니다.</p>
                      </TextBox>
                    </>
                  ) : closedNumber === 3 ? (
                    <>
                      <TextBox isActive={isActiveNumber === 3}>
                        <h3>POS수발주</h3>
                        <p>매장POS전용 발주프로그램으로</p>
                        <p>가맹점과 본사의 신속한 주문배송</p>
                        <p>서비스를 제공해드립니다.</p>
                      </TextBox>
                    </>
                  ) : closedNumber === 4 ? (
                    <>
                      <TextBox isActive={isActiveNumber === 4}>
                        <h3>대리주문</h3>
                        <p>발주처의 주문내역을 영업담당자가 직접</p>
                        <p>입력하여 물류 유통업무의 높은 서비스를</p>
                        <p>제공할 수 있습니다.</p>
                      </TextBox>
                    </>
                  ) : closedNumber === 5 ? (
                    <>
                      <TextBox isActive={isActiveNumber === 5}>
                        <h3>PDA이력관리</h3>
                        <p>농축수산물 이력관리와 중량제품의</p>
                        <p>중량집계를 개별적으로 처리하여</p>
                        <p>완벽한 재고관리가 가능합니다.</p>
                      </TextBox>
                    </>
                  ) : closedNumber === 6 ? (
                    <>
                      <TextBox isActive={isActiveNumber === 6}>
                        <h3>시세관리</h3>
                        <p>농축수산물의 시세변동가를 변동시점에서</p>
                        <p>적용하여 주문 시 변동시세 기준으로</p>
                        <p>판매가격이 자동 적용됩니다.</p>
                      </TextBox>
                    </>
                  ) : closedNumber === 7 ? (
                    <>
                      <TextBox isActive={isActiveNumber === 7}>
                        <h3>생산관리</h3>
                        <p>농축수산물 및 식품가공업의 표준기능으로</p>
                        <p>특화되어 식품가공 생산 관리의</p>
                        <p>다양한 업무를 지원합니다.</p>
                      </TextBox>
                    </>
                  ) : closedNumber === 8 ? (
                    <>
                      <TextBox isActive={isActiveNumber === 8}>
                        <h3>재고현황판</h3>
                        <p>집하장에서 창고별, 품목별 재고 현황을</p>
                        <p>실시간으로 제공하여 주문자에게</p>
                        <p>재고정보를 제공합니다.</p>
                      </TextBox>
                    </>
                  ) : closedNumber === 9 ? (
                    <>
                      <TextBox isActive={isActiveNumber === 9}>
                        <h3>설문조사</h3>
                        <p>프랜차이즈 업무 특성을 고려하여</p>
                        <p>다양한 고객층의 설문조사 활동을</p>
                        <p>디바이스별로 참여할 수 있게 지원합니다.</p>
                      </TextBox>
                    </>
                  ) : closedNumber === 10 ? (
                    <>
                      <TextBox isActive={isActiveNumber === 10}>
                        <h3>순번주문</h3>
                        <p>농수산물의 특성에 맞게 주문순서를 합리적으로</p>
                        <p>지정하여 주문집계 업무의 혼선을</p>
                        <p>방지할 수 있습니다.</p>
                      </TextBox>
                    </>
                  ) : closedNumber === 11 ? (
                    <>
                      <TextBox isActive={isActiveNumber === 11}>
                        <h3>CS관리</h3>
                        <p>가맹점과 고객의 불만사항을 담당자</p>
                        <p>권한으로 전문 관리할 수 있는</p>
                        <p>프랜차이즈 기능을 제공합니다.</p>
                      </TextBox>
                    </>
                  ) : closedNumber === 12 ? (
                    <>
                      <TextBox isActive={isActiveNumber === 12}>
                        <h3>관리회계</h3>
                        <p>
                          <br />
                        </p>
                        <p>준비중인 서비스 입니다.</p>
                      </TextBox>
                    </>
                  ) : (
                    <>
                      <TextBox isActive={isActiveNumber === 12}>
                        <h3>회계관리</h3>
                        <p>농축수산물 이력관리와 중량제품의</p>
                        <p>중량집계를 개별적으로 처리하여</p>
                        <p>완벽한 재고관리가 가능합니다.</p>
                      </TextBox>
                    </>
                  )}

                  <div className="button_box">
                    <button className="pink" type="button" onClick={onClickScrollMove}>
                      제품정보
                      <span className="material-icons-outlined">arrow_forward_ios</span>
                    </button>
                    <button className="blue" type="button" onClick={() => navigate('/contact')}>
                      상담하기
                      <span className="material-icons-outlined">arrow_forward_ios</span>
                    </button>
                  </div>
                </div>
                <img src={box_img} alt="" className="icon" />
              </div>
            </div>
          </div>
        </TopControllerBox>
        <TimeLineBox className={`${timeActive ? 'active' : ''}`}>
          <div className="line_content">
            <ul>
              <li className={`${isActiveNumber === 1 ? 'active' : ''}`} onClick={() => onClickTimeLine(1)}>
                <span className="boll" />
                <div className="txt">FMS 물류관리</div>
              </li>
              <li className={`${isActiveNumber === 2 ? 'active' : ''}`} onClick={() => onClickTimeLine(2)}>
                <span className="boll" />
                <div className="txt top">발주닷컴</div>
              </li>
              <li className={`${isActiveNumber === 3 ? 'active' : ''}`} onClick={() => onClickTimeLine(3)}>
                <span className="boll" />
                <div className="txt">POS수발주</div>
              </li>
              <li className={`${isActiveNumber === 4 ? 'active' : ''}`} onClick={() => onClickTimeLine(4)}>
                <span className="boll" />
                <div className="txt top">대리주문</div>
              </li>
              <li className={`${isActiveNumber === 10 ? 'active' : ''}`} onClick={() => onClickTimeLine(10)}>
                <span className="boll" />
                <div className="txt">순번주문</div>
              </li>
              <li className={`${isActiveNumber === 5 ? 'active' : ''}`} onClick={() => onClickTimeLine(5)}>
                <span className="boll" />
                <div className="txt top">PDA이력관리</div>
              </li>
              <li className={`${isActiveNumber === 7 ? 'active' : ''}`} onClick={() => onClickTimeLine(7)}>
                <span className="boll" />
                <div className="txt">생산관리</div>
              </li>
              <li className={`${isActiveNumber === 6 ? 'active' : ''}`} onClick={() => onClickTimeLine(6)}>
                <span className="boll" />
                <div className="txt top">시세관리</div>
              </li>
              <li className={`${isActiveNumber === 11 ? 'active' : ''}`} onClick={() => onClickTimeLine(11)}>
                <span className="boll" />
                <div className="txt">CS관리</div>
              </li>
              <li className={`${isActiveNumber === 12 ? 'active' : ''}`} onClick={() => onClickTimeLine(12)}>
                <span className="boll" />
                <div className="txt top">관리회계</div>
              </li>
              <li className={`${isActiveNumber === 8 ? 'active' : ''}`} onClick={() => onClickTimeLine(8)}>
                <span className="boll" />
                <div className="txt">재고현황판</div>
              </li>
              <li className={`${isActiveNumber === 9 ? 'active' : ''}`} onClick={() => onClickTimeLine(9)}>
                <span className="boll" />
                <div className="txt top">설문조사</div>
              </li>
            </ul>
          </div>
          <span className="line" />
        </TimeLineBox>
        <ContentContainerBox ref={contentRef}>
          <div className={`${closedNumber === 1 ? 'active' : ''} img_content_box`}>
            <div className="bg_content">
              <div className="text_box">
                <h2>FMS</h2>
                <h3>(Franchise/Food Management System)</h3>
                <p>
                  가맹점 물류관리 기능을 넘어 <br />
                  가맹점을 확장할 수 있는 <br />
                  효율적인 도구가 되어 드릴 것입니다.
                </p>
              </div>
              <div className="bg_box">
                <img src={standard_1_bg} alt="" />
              </div>
            </div>
            <div className="content_box">
              <div className="max_content">
                <div className="title">
                  <span>똑똑</span>하고 <span>편리</span>한 물류관리
                </div>
                <div className="icon_text_box">
                  <div className="img_box">
                    <img src={standard_1_icon1} alt="" />
                  </div>
                  <div className="text_box">
                    <p>
                      <span className="neon_center">프랜차이즈/식품 물류유통관리 프로그램</span>으로 <br />
                      본사와 가맹점의 상생관계에 입각한 서비스를 제공합니다. <br />
                      본사의 프로그램 설정으로 매출처의 관리가 원활해집니다.
                    </p>
                  </div>
                </div>
                <div className="icon_text_box right">
                  <div className="text_box right">
                    <p>
                      <span className="neon_center">다양한 보고서 양식을 보유</span>하고 있는 FMS는 <br />
                      <span className="neon_center">자동 보고서 작성 기능</span>으로 업무시간은 단축시키고 <br />
                      편리함을 높혀줍니다.
                    </p>
                  </div>
                  <div className="img_box right">
                    <img src={standard_1_icon2} alt="" />
                  </div>
                </div>
                <div className="title">
                  높아지는 <span>확장 가능성</span>
                </div>
                <div className="icon_text_box">
                  <div className="img_box">
                    <img src={standard_1_icon3} alt="" />
                  </div>
                  <div className="text_box">
                    <p>
                      엠에스벤터의 FMS 프로그램은 <span className="neon_center">높은 연계성으로</span> <br />
                      단순한 물류관리 기능을 넘어서서 회사의 기반을 다지고 <br />
                      가맹점을 확장할 수 있는 효율적인 도구입니다.
                    </p>
                  </div>
                </div>
                <div className="icon_text_box right">
                  <div className="text_box right">
                    <p>
                      비즈니스 규모와 특성에 따른 다양한 버전의 FMS서비스와 <br />
                      <span className="neon_center">시세관리, 생산관리 등 각종 연계프로그램</span> <br />
                      서비스를 제공합니다.
                    </p>
                  </div>
                  <div className="img_box right">
                    <img src={standard_1_icon4} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`${closedNumber === 2 ? 'active' : ''} img_content_box`}>
            <div className="bg_content">
              <div className="text_box">
                <h2>발주닷컴</h2>
                <p>
                  시간과 장소에 구애 받지 않고 본사에 <br />
                  바로 주문이 가능하며 실시간 재고수량을 <br />
                  반영하여 주문 오류가 없습니다. <br />
                </p>
              </div>
              <div className="bg_box">
                <img src={standard_2_bg} alt="" />
              </div>
            </div>
            <div className="content_box">
              <div className="max_content">
                <div className="title">
                  언제 어디서든 <span>편하게 주문</span>
                </div>
                <div className="icon_text_box">
                  <div className="img_box">
                    <img src={standard_2_icon1} alt="" />
                  </div>
                  <div className="text_box">
                    <p>
                      가맹점주들을 위한 편리한 <span className="neon_center">발주전용 어플입니다.</span> <br />
                      pc또는 안드로이드 핸드폰, 아이폰 상관없이 <br />
                      모든 환경에서 주문할 수 있습니다. <br />
                    </p>
                  </div>
                </div>
                <div className="icon_text_box right">
                  <div className="text_box right">
                    <p>
                      사용자의 편리성을 극대화시킨 발주닷컴은 <br />
                      <span className="neon_center">남녀노소</span> 가리지 않고 발주 업무를 간편하게 할 수 있도록 <br />
                      도와줍니다.
                    </p>
                  </div>
                  <div className="img_box right">
                    <img src={standard_2_icon2} alt="" />
                  </div>
                </div>
                <div className="title">
                  누락 주문 없이 <span>완벽한 주문</span>
                </div>
                <div className="icon_text_box">
                  <div className="img_box">
                    <img src={standard_2_icon3} alt="" />
                  </div>
                  <div className="text_box">
                    <p>
                      본사 FMS 프로그램에서 가맹점 별 판매단가, 주문시간과 <br />
                      요일, 여신한도, 배송자 및 배송정보 등 다양한 내용을 <br />
                      가맹점별로 설정하면 가맹점 발주프로그램에 <br />
                      <span className="neon_center">자동으로 실시간 적용</span>됩니다.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${closedNumber === 3 ? 'active' : ''} img_content_box`}>
            <div className="bg_content">
              <div className="text_box">
                <h2>POS수발주</h2>
                <p>
                  POS기기로 편하게 주문이 가능하며 <br />
                  실시간으로 가맹점 여신관리가 가능합니다.
                </p>
              </div>
              <div className="bg_box">
                <img src={standard_3_bg} alt="" />
              </div>
            </div>
            <div className="content_box">
              <div className="max_content">
                <div className="title">
                  <span>POS기기</span>로 편하게 주문
                </div>
                <div className="icon_text_box">
                  <div className="img_box">
                    <img src={standard_3_icon1} alt="" />
                  </div>
                  <div className="text_box">
                    <p>
                      가맹점과 본사의 신속한 주문 배송 서비스를 제공하며 <br />
                      본사 FMS프로그램에서 가맹점별 <span className="neon_center">판매단가,</span> <br />
                      <span className="neon_center">주문시간/요일, 여신한도, 배송자 및 배송정보, 판매상품설정, </span>
                      <br />
                      브랜드 설정, 등 다양한 내용을 가맹점별로 설정하면 <br />
                      가맹점 발주프로그램에 자동으로 실시간 적용됩니다.
                    </p>
                  </div>
                </div>
                <div className="title right">
                  가맹점 <span>여신관리 기능</span>
                </div>
                <div className="icon_text_box right">
                  <div className="text_box right">
                    <p>
                      사용자의 편리성을 극대화시킨 POS기기는 <br />
                      프로그램이 설치 된 곳에서 언제든지 <br />
                      <span className="neon_center">간편하게 주문가능</span> 할 수 있도록 <br />
                      도와줍니다.
                    </p>
                  </div>
                  <div className="img_box right">
                    <img src={standard_3_icon2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${closedNumber === 4 ? 'active' : ''} img_content_box`}>
            <div className="bg_content">
              <div className="text_box">
                <h2>대리주문</h2>
                <p>
                  긴급발주 및 가맹점의 특수상황에서도 <br />
                  대응서비스가 가능하여 본사와 가맹점 간 <br />
                  유대관계 형성을 도와줍니다. <br />
                </p>
              </div>
              <div className="bg_box">
                <img src={standard_5_bg} alt="" />
              </div>
            </div>
            <div className="content_box">
              <div className="max_content">
                <div className="title">
                  특수한 상황에서도 <span>당황은 NO</span>
                </div>
                <div className="icon_text_box">
                  <div className="img_box">
                    <img src={standard_5_icon1} alt="" />
                  </div>
                  <div className="text_box">
                    <p>
                      가맹점의 주문내역을 본사 또는 슈퍼바이저가 전화, 문자나 <br />
                      카톡 등으로 접수한 후 <span className="neon_center">대리주문 프로그램</span>을 이용하여 <br />
                      <span className="neon_center">주문내역을 전산화</span> 할 수 있는 시스템입니다. <br />
                    </p>
                  </div>
                </div>
                <div className="title right">
                  본사와 가맹점 간 <span>유대관계 형성</span>
                </div>
                <div className="icon_text_box right">
                  <div className="text_box right">
                    <p>
                      <span className="neon_center">긴급발주 및 가맹점의 특수한 상황</span>에서도 서비스 대응이 <br />
                      가능합니다. 본사와 가맹점 간의 유대관계를 형성시키고 <br />
                      <span className="neon_center">고령사용자들의 편리성</span>을 높혀줍니다. <br />
                    </p>
                  </div>
                  <div className="img_box right">
                    <img src={standard_5_icon2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${closedNumber === 5 ? 'active' : ''} img_content_box`}>
            <div className="bg_content">
              <div className="text_box">
                <h2>PDA시스템</h2>
                <p>
                  정확한 재고 관리 기능과 <br />
                  계육 이력관리 신고를 더욱 편하게 <br />할 수 있습니다.
                </p>
              </div>
              <div className="bg_box">
                <img src={standard_4_bg} alt="" />
              </div>
            </div>
            <div className="content_box">
              <div className="max_content">
                <div className="title">
                  <span>정확한</span> 재고 관리 가능
                </div>
                <div className="icon_text_box">
                  <div className="img_box">
                    <img src={standard_4_icon1} alt="" />
                  </div>
                  <div className="text_box">
                    <p>
                      바코드 시스템을 이용해 <span className="neon_center">매입/매출, 원자재 투입/반납,</span> <br />
                      <span className="neon_center">완성품 입/출고 등</span> 여러 가지 업무를 정확하고 빠르게 <br />
                      처리할 수 있습니다.
                    </p>
                  </div>
                </div>
                <div className="title right">
                  편리한 <span>이력관리 신고</span>
                </div>
                <div className="icon_text_box right">
                  <div className="text_box right">
                    <p>
                      중량제품의 재고관리가 <span className="neon_center">수량과 중량재고로 분리</span> 되어 <br />
                      <span className="neon_center">정확한 재고 관리</span>가 가능하며 <br />
                      개별재고 관리법으로 <span className="neon_center">재고 손실</span>이 사라지고 <br />
                      <span className="neon_center">축산물 이력관리 신고까지 편리</span>하게 해결할 수 있습니다
                    </p>
                  </div>
                  <div className="img_box right">
                    <img src={standard_4_icon2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${closedNumber === 6 ? 'active' : ''} img_content_box`}>
            <div className="bg_content">
              <div className="text_box">
                <h2>시세관리</h2>
                <p>
                  자동으로 변동시세가 적용되어 <br />
                  업무를 최소한으로 줄여줍니다.
                </p>
              </div>
              <div className="bg_box">
                <img src={standard_6_bg} alt="" />
              </div>
            </div>
            <div className="content_box">
              <div className="max_content">
                <div className="title">
                  <span>자동</span>으로 적용되는 <span>시세</span>
                </div>
                <div className="icon_text_box">
                  <div className="img_box">
                    <img src={standard_6_icon1} alt="" />
                  </div>
                  <div className="text_box">
                    <p>
                      <span className="neon_center">수시로 변동되는</span> 원자재의 시세에 따라 매출처별로 <br />
                      <span className="neon_center">설정된 마진을 적용</span>하고 모든 거래처의 판매단가가 <br />
                      신속하게 자동 변경되는 <span className="neon_center">자동판매단가</span> <br />
                      설정프로그램입니다.
                    </p>
                  </div>
                </div>
                <div className="title right">
                  눈에 띄게 <span>감소</span>하는 <span>업무시간</span>
                </div>
                <div className="icon_text_box right">
                  <div className="text_box right">
                    <p>
                      시세관리 품목과 일반 상품은 분리된 판매단가가 운영되어 <br />
                      축산물, 농산물, 해산물 등 <span className="neon_center">하루가 다르게 변하는 시세를</span> <br />
                      <span className="neon_center">자동으로 적용</span>할 수 있어 <br />
                      업무 시간이 눈에 띄게 감소합니다.
                    </p>
                  </div>
                  <div className="img_box right">
                    <img src={standard_6_icon2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${closedNumber === 7 ? 'active' : ''} img_content_box`}>
            <div className="bg_content">
              <div className="text_box">
                <h2>생산관리</h2>
                <p>
                  계육 및 육가공 생산현장에 특화된 솔루션이 <br />
                  품질 향상은 물론 선진화된 생산라인으로 <br />
                  업그레이드 시켜드립니다.
                </p>
              </div>
              <div className="bg_box">
                <img src={standard_7_bg} alt="" />
              </div>
            </div>
            <div className="content_box">
              <div className="max_content">
                <div className="title">
                  FMS의 주문을 <span>클릭 한 번</span>으로 집계
                </div>
                <div className="icon_text_box">
                  <div className="img_box">
                    <img src={standard_7_icon1} alt="" />
                  </div>
                  <div className="text_box">
                    <p>
                      <span className="neon_center">주문량 자동 집계에</span> 따른 생산량 산출 및 생산계획 수립, <br />
                      원자재 투입량 분석 및 원자재 투입/반납 등 <br />
                      <span className="neon_center">제조현장의 장비를 실시간 모니터링</span>하여 생산 효율성을 <br />
                      높일 수 있도록 도와주는 프로그램입니다.
                    </p>
                  </div>
                </div>
                <div className="title right">
                  <span>자동</span>으로 <span>관리</span>되는 <span>재고</span>
                </div>
                <div className="icon_text_box right">
                  <div className="text_box right">
                    <p>
                      <span className="neon_center">BOM구성</span>이 가능하여 제품별
                      <span className="neon_center">원자재 소요량</span>을 <br />
                      <span className="neon_center">자동으로 산출</span> 및 재고를 관리하게 되어 <br />
                      자재이동이 명확해집니다.
                    </p>
                  </div>
                  <div className="img_box right">
                    <img src={standard_7_icon2} alt="" />
                  </div>
                </div>
                <div className="title">
                  생산현장을 더욱 <span>스마트</span>하게
                </div>
                <div className="icon_text_box">
                  <div className="img_box">
                    <img src={standard_7_icon3} alt="" />
                  </div>
                  <div className="text_box">
                    <p>
                      생산현장에서 발생하는 상황을 <span className="neon_center">실시간으로 파악함</span>으로써 <br />
                      생산라인 개선이나 최상의 생산시스템을 구축하기 위한 <br />
                      의사결정에 큰 역할을 합니다.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${closedNumber === 8 ? 'active' : ''} img_content_box`}>
            <div className="bg_content">
              <div className="text_box">
                <h2>재고현황시스템</h2>
                <p>
                  실시간으로 변동되는 제품의 재고(구매가능수량)를 <br />
                  현황판에 노출시켜 원활한 거래를 도와줍니다.
                </p>
              </div>
              <div className="bg_box">
                <img src={standard_8_bg} alt="" />
              </div>
            </div>
            <div className="content_box">
              <div className="max_content">
                <div className="title">
                  <span>한눈에</span> 보는 실시간 <span>재고</span>
                </div>
                <div className="icon_text_box">
                  <div className="img_box">
                    <img src={standard_8_icon1} alt="" />
                  </div>
                  <div className="text_box">
                    <p>
                      <span className="neon_center">실시간으로 변동되는 제품의 재고를 현황판에 노출</span>시켜 <br />
                      민감한 재고 변동 제품의 판매를 효율적이고 신속하게 <br />
                      진행할 수 있도록 도와주는 시스템입니다.
                    </p>
                  </div>
                </div>
                <div className="title right">
                  <span>순번대기</span> 주문 시스템과 <span>연계</span>
                </div>
                <div className="icon_text_box right">
                  <div className="text_box right">
                    <p>
                      <span className="neon_center">순번대기 주문 시스템과 연계</span>되어 <br />
                      현장 주문 업무의 혼선이 방지되고 <br />
                      재고수량 변동이 심한 상품 및 한정 상품 등의 <br />
                      주문 업무를 도와 <span className="neon_center">솔루션의 기능</span>으로 활용됩니다.
                    </p>
                  </div>
                  <div className="img_box right">
                    <img src={standard_2_icon2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${closedNumber === 9 ? 'active' : ''} img_content_box`}>
            <div className="bg_content">
              <div className="text_box">
                <h2>설문조사</h2>
                <p>
                  가맹점과 고객의 다양한 요구사항을 듣고 <br />
                  본사의 서비스 개발을 도와줍니다.
                </p>
              </div>
              <div className="bg_box">
                <img src={standard_9_bg} alt="" />
              </div>
            </div>
            <div className="content_box">
              <div className="max_content">
                <div className="title">
                  가맹점과 본사의 <span>소통창구</span>
                </div>
                <div className="icon_text_box">
                  <div className="img_box">
                    <img src={standard_9_icon1} alt="" />
                  </div>
                  <div className="text_box">
                    <p>
                      가맹점들의 요구사항, 신규서비스에 대한 타당성 예측등 <br />
                      <span className="neon_center">가맹점과의 소통</span>을 위한 커뮤니케이션 도구입니다.
                    </p>
                  </div>
                </div>
                <div className="title right">
                  본사의 <span>성장</span>을 위한 <span>발판</span>
                </div>
                <div className="icon_text_box right">
                  <div className="text_box right">
                    <p>
                      <span className="neon_center">가맹점과 고객의 다양한 요구사항</span>을 조사하여 <br />
                      빠르게 변화하는 니즈와 시장현황에 대응 할 수있는 <br />
                      도구로 활용됩니다. <span className="neon_center">안정적인 물류의 흐름을 유지</span>시키며 <br />
                      새로운 상품과 서비스를 개발하는 데 도움을 줍니다.
                    </p>
                  </div>
                  <div className="img_box right">
                    <img src={standard_9_icon2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${closedNumber === 10 ? 'active' : ''} img_content_box`}>
            <div className="bg_content">
              <div className="text_box">
                <h2>순번대기주문</h2>
                <p>
                  자동으로 적용되는 순번대기시스템은 <br />
                  투명하고 효율적인 주문프로그램을 제공할 수 있습니다.
                </p>
              </div>
              <div className="bg_box">
                <img src={standard_10_bg} alt="" />
              </div>
            </div>
            <div className="content_box">
              <div className="max_content">
                <div className="title">
                  <span>자동</span>으로 적용되는 <span>주문 순서</span>
                </div>
                <div className="icon_text_box">
                  <div className="img_box">
                    <img src={standard_10_icon1} alt="" />
                  </div>
                  <div className="text_box">
                    <p>
                      <span className="neon_center">고객의 주문 순서와 대기번호, 현재 재고</span> 등을 자동으로 <br />
                      적용하여 은행 창고와 같이 순번에 의해 주문이 <br />
                      접수됩니다.
                    </p>
                  </div>
                </div>
                <div className="title right">
                  <span>투명</span>하고 <span>편리</span>한 주문
                </div>
                <div className="icon_text_box right">
                  <div className="text_box right">
                    <p>
                      <span className="neon_center">주문 과정이 투명해지고 고객의 주문 편리성</span>이 <br />
                      한층 증가합니다. 본사 업무도 정확하고 <br />
                      신속하게 진행되도록 도와줍니다.
                    </p>
                  </div>
                  <div className="img_box right">
                    <img src={standard_10_icon2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${closedNumber === 11 ? 'active' : ''} img_content_box`}>
            <div className="bg_content">
              <div className="text_box">
                <h2>CS관리</h2>
                <p>
                  단독 케어로 가맹점 및 고객의 서비스 활동을 <br />
                  적극 맞춤 관리하여 업무시간을 단축시켜주는 <br />
                  프로그램입니다.
                </p>
              </div>
              <div className="bg_box">
                <img src={standard_11_bg} alt="" />
              </div>
            </div>
            <div className="content_box">
              <div className="max_content">
                <div className="title">
                  <span>1:1 케어로</span> 서비스 개선
                </div>
                <div className="icon_text_box">
                  <div className="img_box">
                    <img src={standard_11_icon1} alt="" />
                  </div>
                  <div className="text_box">
                    <p>
                      프랜차이즈 본사가 가맹점 및 고객의 서비스 활동을 <br />
                      <span className="neon_center">적극 맞춤관리</span>하여 개선된 서비스 활동을 운영할 수 있도록
                      <br />
                      도와주는 표준 기능이 탑재된 시스템입니다. <br />
                      유선상으로만 이루어졌던 업무가 <br />
                      <span className="neon_center">프로그램으로 처리</span> 가능하여 <br />
                      가맹점과 본사 모두 <span className="neon_center">업무시간이 단축</span>됩니다.
                    </p>
                  </div>
                </div>
                <div className="title right">
                  <span>정확한 서비스</span>응대 가능
                </div>
                <div className="icon_text_box right">
                  <div className="text_box right">
                    <p>
                      매출처와 가맹점들은 <span className="neon_center">실시간으로 문의사항 및 불만사항 등록</span>이
                      가능하고 본사는 이를 바탕으로 <span className="neon_center">서비스 활동을 스스로 진단</span>하며
                      경영 환경과 의사결정 참조자료로 활용될 수있습니다.
                    </p>
                  </div>
                  <div className="img_box right">
                    <img src={standard_11_icon2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`${closedNumber === 12 ? 'active' : ''} img_content_box`}>
            <div className="coming">
              <img src={skateboar} alt="" />
              <p>COMING</p>
              <p>SOON</p>
            </div>
          </div>
        </ContentContainerBox>
        <Footer />
      </Container>
    </>
  )
}

export default StandardSolutionPage
