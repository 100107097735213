import React from 'react'
import { InputTag } from './styles'

const Input = ({ value, onChange, type, id, required, size }) => {
  return (
    <>
      <InputTag value={value} onChange={onChange} id={id} type={type} required={required} size={size} />
    </>
  )
}

Input.defaultProps = {
  type: 'text',
  size: 'normal',
}

export default Input
