import styled from 'styled-components'

export const ContainerBox = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 40px;
`

export const TitleBox = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
`
