import React, { useEffect, useRef, useState } from 'react'
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Header from '../../components/Header'
import QuickMenuBox from '../../components/QuickMenuBox'
import MainCard from '../../components/MainCard'
import ContactUsForm from '../../components/ContactUsForm'
import NoticeModal from '../../components/NoticeModal'

// swiper core styles
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import {
  MainContainer,
  Section1Container,
  Section2Container,
  Section3Container,
  Section4Container,
  Section6Container,
  Section7Container,
  SectionTitle,
  DownCenterContainer,
  Panel,
} from './styles'

import main_video from '../../assets/images/main_video2.mp4'

import mainImage1 from '../../assets/images/slide_re.png'
import mainImage1_circle1 from '../../assets/images/circle1.png'
import mainImage1_circle2 from '../../assets/images/circle2.png'

import mainImage2_img1 from '../../assets/images/mainslide3_img1.png'
import mainImage2_img2 from '../../assets/images/mainslide3_img2.png'
import mainImage2_img3 from '../../assets/images/mainslide3_img3.png'

import mainImage_m2_img1 from '../../assets/images/mainslide_m3_img1.png'
import mainImage_m2_img2 from '../../assets/images/mainslide_m3_img2.png'
import mainImage_m2_img3 from '../../assets/images/mainslide_m3_img3.png'

// import mainImage3_img1 from '../../assets/images/mainImage3_img2.png'
import mainImage3_img1 from '../../assets/images/mainImage3_img3.png'
// import mainImage3_img_bg from '../../assets/images/mainImage3_img_bg.png'
import mainImage3_img_bg from '../../assets/images/mainImage3_img_bg.jpg'

// import slide_mes from '../../assets/images/newSlide1.png'
// import slide_crm from '../../assets/images/newSlide3.png'
// import slide_orm from '../../assets/images/newSlide2.png'
// import slide_cpm from '../../assets/images/newSlide4.png'
import slide_mes from '../../assets/images/newSlide1_small.png'
import slide_crm from '../../assets/images/newSlide3_small.png'
import slide_orm from '../../assets/images/newSlide2_small.png'
import slide_cpm from '../../assets/images/newSlide4_small.png'

import slideRight from '../../assets/images/right2.png'
import slideLeft from '../../assets/images/left2.png'

import section2_img1 from '../../assets/images/review_1_img.png'
// import section2_img1_logo from '../../assets/images/review_1_logo.png'
import section2_img1_logo2 from '../../assets/images/review_1_logo2.png'
import section2_img2 from '../../assets/images/review_2_img.png'
import section2_img2_logo from '../../assets/images/review_2_logo.png'
import section2_img3 from '../../assets/images/review_3_img.png'
import section2_img3_logo from '../../assets/images/review_3_logo.png'
import section2_img4 from '../../assets/images/review_4_img.png'
import section2_img4_logo from '../../assets/images/review_4_logo.png'
import section2_img5 from '../../assets/images/review_5_img.png'
import section2_img5_logo from '../../assets/images/review_5_logo.png'
import section2_img6 from '../../assets/images/review_6_img.png'
import section2_img6_logo from '../../assets/images/review_6_logo.png'
import section2_img7 from '../../assets/images/review_7_img.jpeg'
import section2_img7_logo from '../../assets/images/review_7_logo.png'
import section2_img8 from '../../assets/images/review_8_img.jpg'
import section2_img8_logo from '../../assets/images/review_8_logo.png'
// import section2_img9 from '../../assets/images/review_9_img.png'
import section2_img9 from '../../assets/images/review_9_img2.png'
// import section2_img9 from '../../assets/images/review_9_img.jpg'
import section2_img9_logo from '../../assets/images/review_9_logo.png'
import section2_img10 from '../../assets/images/review_10_img.png'
import section2_img10_logo from '../../assets/images/review_10_logo.png'

import cardImg1 from '../../assets/images/p1.png'
import cardImg2 from '../../assets/images/p2.png'
import cardImg3 from '../../assets/images/p3.png'
import cardImg4 from '../../assets/images/p4.png'
import cardImg5 from '../../assets/images/p5.png'
import cardImg6 from '../../assets/images/p6.png'
import cardImg7 from '../../assets/images/p7.png'
import cardImg8 from '../../assets/images/p8.png'
import cardImg9 from '../../assets/images/p9.png'
import cardImg10 from '../../assets/images/p10.png'
import cardImg11 from '../../assets/images/p11.png'
import cardImg12 from '../../assets/images/p12.png'

import solution1 from '../../assets/images/solution1.svg'
import solution2 from '../../assets/images/solution2.svg'
import solution3 from '../../assets/images/solution3.svg'

import contact_mail from '../../assets/images/contact_mail.png'
import contact_call from '../../assets/images/contact_call.png'

// import downImg1 from '../../assets/images/googleplay.png'
// import downImg2 from '../../assets/images/appstore.png'
// import downImg3 from '../../assets/images/balju.png'
// import downImg4 from '../../assets/images/fmsorder.png'
import downImg1 from '../../assets/images/googleplay_small.png'
import downImg2 from '../../assets/images/appstore_small.png'
import downImg3 from '../../assets/images/balju_small.png'
import downImg4 from '../../assets/images/fmsorder_small.png'

// import baljuOs from '../../assets/images/balju1.0.0.exe'
import baljuOs from '../../assets/images/balju-setup-1.0.4.exe'
import fms_order_setup from '../../assets/images/fms_order_setup.exe'

SwiperCore.use([Navigation, Pagination, A11y, Autoplay])

import { db } from '../../firebase'
import { doc, getDocs, collection, query, where, limit, orderBy, getDoc } from 'firebase/firestore'
import { Link, useNavigate } from 'react-router-dom'
import Footer from '../../components/Footer'
import { useSetRecoilState } from 'recoil'
import solutionActiveState from '../../store/solutionActiveState'
import solutionScrollState from '../../store/solutionScrollState'
import { effectActiveSate, effectScrollState } from '../../store/effectPageState'
import CrapeBox from '../../components/CrapeBox'
import VoucherEventHeader from '../../components/VoucherEventHeader'
import QuickSideMenu from '../../components/QuickSideMenu'

let slideStopTimeOut
let slideTimeOut1
let slideTimeOut2
let slideTimeOut3
let slideTimeOut4

const MainPage = () => {
  let navigate = useNavigate()
  const setIsActiveNumber = useSetRecoilState(solutionActiveState)
  const setScrollState = useSetRecoilState(solutionScrollState)
  const setEffectIsActiveNumber = useSetRecoilState(effectActiveSate)
  const setEffectScrollState = useSetRecoilState(effectScrollState)
  const [noticeList, setNoticeList] = useState([])

  const mainVideoRef = useRef(null)

  const prevRef = useRef(null)
  const nextRef = useRef(null)

  const prevRef2 = useRef(null)
  const nextRef2 = useRef(null)

  const solutionRef1 = useRef(null)
  const [solution1Active, setSolution1Active] = useState(false)

  const solutionRef2 = useRef(null)
  const [solution2Active, setSolution2Active] = useState(false)

  const solutionRef3 = useRef(null)
  const [solution3Active, setSolution3Active] = useState(false)

  const [windowMode, setWindowMode] = useState(null)

  const getNotices = async () => {
    const q = query(collection(db, 'notice'), orderBy('createdAt', 'desc'), limit(4))
    const querySnapshot = await getDocs(q)
    // console.log(querySnapshot)
    const list = querySnapshot.docs.map(e => e.data())
    // console.log(list)
    setNoticeList(list)
  }

  useEffect(() => {
    getNotices()
  }, [])

  // const [slide2Number, setSlide2Number] = useState(2)

  useEffect(() => {
    resizeEvent()
  }, [])

  useEffect(() => {
    function onResize() {
      resizeEvent()
    }
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const resizeEvent = () => {
    const winW = document.body.clientWidth
    if (winW >= 800) {
      setWindowMode('pc')
    } else {
      setWindowMode('mobile')
    }
  }

  useEffect(() => {
    function onScroll() {
      if (solutionRef1.current && solutionRef1.current.getBoundingClientRect().top < document.body.clientHeight * 0.7) {
        setSolution1Active(true)
      } else {
        setSolution1Active(false)
      }
      if (solutionRef2.current && solutionRef2.current.getBoundingClientRect().top < document.body.clientHeight * 0.7) {
        setSolution2Active(true)
      } else {
        setSolution2Active(false)
      }
      if (solutionRef3.current && solutionRef3.current.getBoundingClientRect().top < document.body.clientHeight * 0.7) {
        setSolution3Active(true)
      } else {
        setSolution3Active(false)
      }
    }
    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  // 프로그램 카드 버튼을 눌렀을 때 솔루션 페이지 이동
  const onClickSolutionMove = id => {
    setIsActiveNumber(id)
    setScrollState(true)
    navigate('/standard_solution')
  }

  // 카드 모달 상태값 및 데이터 값
  const [noticeActive, setNoticeActive] = useState(false)
  const [noticeTitle, setNoticeTitle] = useState('')
  const [noticeContent, setNoticeContent] = useState('')

  // 소식 카드 눌렀을때 모달 오픈
  const onClickNoticeModalOpen = async id => {
    const docRef = doc(db, 'notice', id)
    const docSnap = await getDoc(docRef)
    setNoticeTitle(docSnap.data().title)
    setNoticeContent(docSnap.data().content)
    setNoticeActive(true)
  }

  // 카드 모달 닫기 이벤트
  const noticeClose = () => {
    setNoticeActive(false)
  }

  // 도입효과 버튼 눌렀들 때 링크 이동
  const onClickEffectMove = id => {
    setEffectIsActiveNumber(id)
    setEffectScrollState(true)
    navigate('/effect')
  }

  // 캐서셀 관련 코드
  const [carouselState, setCarouselState] = useState(1)

  // 캐러샐 돌아가는효과
  const onClickCarouselRotate = number => {
    setCarouselState(number)
  }

  useEffect(() => {
    return () => {
      clearTimeout(slideStopTimeOut)
      clearTimeout(slideTimeOut1)
      clearTimeout(slideTimeOut2)
      clearTimeout(slideTimeOut3)
      clearTimeout(slideTimeOut4)
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <MainContainer>
        {/*<VoucherEventHeader />*/}
        <Header />
        <QuickMenuBox />
        <QuickSideMenu />
        {/*<div className="header_notice header_notice1">24시 긴급 상담센터 1577-7120</div>*/}
        {/*<div className="header_notice header_notice2">원격지원 서비스 바로가기</div>*/}
        <Section1Container>
          <Swiper
            spaceBetween={0}
            slidesPerView={1}
            // loop={true}
            centeredSlides={true}
            // autoplay={{
            //   delay: 12050,
            //   disableOnInteraction: false,
            // }}
            // onSlideChange={() => console.log('slide change')}
            // onSwiper={swiper => console.log(swiper)}
            pagination={{ clickable: true }}
            navigation={{
              // Both prevEl & nextEl are null at render so this does not work
              prevEl: prevRef.current ? prevRef.current : undefined,
              nextEl: nextRef.current ? nextRef.current : undefined,
            }}
            onInit={swiper => {
              swiper.params.navigation.prevEl = prevRef.current
              swiper.params.navigation.nextEl = nextRef.current
              swiper.navigation.update()
            }}
            onSwiper={swiper => {
              if (swiper.activeIndex === 0) {
                mainVideoRef.current.play()
                slideStopTimeOut = setTimeout(() => {
                  mainVideoRef.current.pause()
                }, 12050)
                slideTimeOut1 = setTimeout(() => {
                  swiper.slideTo(1)
                }, 13000)
              }
            }}
            onSlideChange={swiper => {
              if (swiper.activeIndex === 0) {
                clearTimeout(slideTimeOut1)
                clearTimeout(slideTimeOut2)
                clearTimeout(slideTimeOut3)
                clearTimeout(slideTimeOut4)
                mainVideoRef.current.currentTime = 0
                mainVideoRef.current.play()
                slideStopTimeOut = setTimeout(() => {
                  mainVideoRef.current.pause()
                }, 12050)
                slideTimeOut1 = setTimeout(() => {
                  swiper.slideTo(1)
                }, 13000)
              }
              if (swiper.activeIndex === 1) {
                clearTimeout(slideStopTimeOut)
                clearTimeout(slideTimeOut1)
                clearTimeout(slideTimeOut3)
                clearTimeout(slideTimeOut4)
                mainVideoRef.current.pause()
                slideTimeOut2 = setTimeout(() => {
                  swiper.slideTo(2)
                }, 5000)
              }
              if (swiper.activeIndex === 2) {
                clearTimeout(slideStopTimeOut)
                clearTimeout(slideTimeOut1)
                clearTimeout(slideTimeOut2)
                clearTimeout(slideTimeOut4)
                mainVideoRef.current.pause()
                slideTimeOut3 = setTimeout(() => {
                  swiper.slideTo(3)
                }, 10000)
              }
              if (swiper.activeIndex === 3) {
                clearTimeout(slideStopTimeOut)
                clearTimeout(slideTimeOut1)
                clearTimeout(slideTimeOut2)
                clearTimeout(slideTimeOut3)
                mainVideoRef.current.pause()
                slideTimeOut4 = setTimeout(() => {
                  swiper.slideTo(0)
                }, 5000)
              }
            }}
          >
            <SwiperSlide>
              <div className="video_box">
                <video loop playsInline muted ref={mainVideoRef}>
                  <source src={main_video} type="video/mp4" />
                </video>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="image_box img_content1">
                <div className="content_box">
                  <div className="text_box">
                    <div className="title">
                      <span>FMS</span> FRANCHISE/FOOD MANAGEMENT SYSTEM
                    </div>
                    <div className="center_text">
                      <p>프랜차이즈/식품물류 유통관리 프로그램으로,</p>
                      <p>본사와 가맹점의 상생관계에 입각한</p>
                      <p>서비스를 제공합니다</p>
                    </div>
                    <div className="bottom_text">
                      <span>FMS라이트 / FMS오피스 / FMS소호 / FMS엔터프라이즈</span>
                    </div>
                  </div>
                  <div className="img_box">
                    <img src={mainImage1} alt="이미지" />
                  </div>
                </div>
                <div className="circle_box">
                  <img src={mainImage1_circle1} alt="circle" className="circle1" />
                  <img src={mainImage1_circle2} alt="circle" className="circle2" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="image_box img_content3">
                <div className="content_box">
                  <div className="text_box">
                    <div
                      className={`${carouselState === 1 ? 'active' : ''} drop_down`}
                      onClick={() => onClickCarouselRotate(1)}
                    >
                      <div className="title">
                        <span className="txt">MES</span> Manufacturing Execution System
                        <span className="material-icons-outlined down_icon">expand_more</span>
                      </div>
                      <div className="drop_down_content">
                        <p>현 재고로 적정 생산량을 자동 파악 후, 생산계획에서</p>
                        <p>간편하게 주문할 수 있는 시스템</p>
                      </div>
                    </div>
                    <div
                      className={`${carouselState === 2 ? 'active' : ''} drop_down`}
                      onClick={() => onClickCarouselRotate(2)}
                    >
                      <div className="title">
                        <span className="txt">OMS</span> Order Management System{' '}
                        <span className="material-icons-outlined down_icon">expand_more</span>
                      </div>
                      <div className="drop_down_content">
                        <p>언제, 어디서나, 생각날 때, 놓치는 주문 없이</p>
                        <p>간편하게 주문할 수 있는 시스템</p>
                      </div>
                    </div>
                    <div
                      className={`${carouselState === 3 ? 'active' : ''} drop_down`}
                      onClick={() => onClickCarouselRotate(3)}
                    >
                      <div className="title">
                        <span className="txt">CRM</span> Customer Relationship Management
                        <span className="material-icons-outlined down_icon">expand_more</span>
                      </div>
                      <div className="drop_down_content">
                        <p>가맹점과 고객의 불만사항을 세부적으로 전문 관리</p>
                        <p>할 수 있는 프로그램</p>
                      </div>
                    </div>
                    <div
                      className={`${carouselState === 4 ? 'active' : ''} drop_down`}
                      onClick={() => onClickCarouselRotate(4)}
                    >
                      <div className="title">
                        <span className="txt">CPM</span> Chicken/Current Price Management
                        <span className="material-icons-outlined down_icon">expand_more</span>
                      </div>
                      <div className="drop_down_content">
                        <p>당일 시세를 자동 반영하여 판매단가를 자동으로</p>
                        <p>설정하는 프로그램</p>
                      </div>
                    </div>

                    {/*<div className="title">*/}
                    {/*  <span>MES</span> MANUFACTURING EXECUTION SYSTEM*/}
                    {/*</div>*/}
                    {/*<div className="center_text">*/}
                    {/*  */}
                    {/*</div>*/}
                    {/*<div className="title">*/}
                    {/*  <span>FMS</span> Franchise Management System*/}
                    {/*</div>*/}
                  </div>

                  <div className="stage">
                    <div className="carousel">
                      <Panel carouselState={carouselState}>
                        <img src={slide_mes} alt="" />
                      </Panel>
                      <Panel carouselState={carouselState}>
                        <img src={slide_cpm} alt="" />
                      </Panel>
                      <Panel carouselState={carouselState}>
                        <img src={slide_crm} alt="" />
                      </Panel>
                      <Panel carouselState={carouselState}>
                        <img src={slide_orm} alt="" />
                      </Panel>
                    </div>
                  </div>
                  {/*<div className="img_box">*/}
                  {/*  <img src={mainImage3_img1} alt="이미지" />*/}
                  {/*</div>*/}
                </div>
                <div className="back_img">
                  <img src={mainImage3_img_bg} alt="" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="image_box img_content2">
                <div className="content_box">
                  <div className="text_box">20년간 축척된 노하우로 고객사의 성장과 성공을 보장합니다.</div>
                  <div className="img_box">
                    {windowMode === 'pc' ? (
                      <>
                        <div>
                          <img src={mainImage2_img1} alt="img1" />
                        </div>
                        <div>
                          <img src={mainImage2_img2} alt="img1" />
                        </div>
                        <div>
                          <img src={mainImage2_img3} alt="img1" />
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <img src={mainImage_m2_img1} alt="img1" />
                        </div>
                        <div>
                          <img src={mainImage_m2_img2} alt="img1" />
                        </div>
                        <div>
                          <img src={mainImage_m2_img3} alt="img1" />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <span className="nav prev" ref={prevRef}>
              <img src={slideLeft} alt="" />
            </span>
            <span className="nav next" ref={nextRef}>
              <img src={slideRight} alt="" />
            </span>
          </Swiper>
        </Section1Container>
        <Section2Container>
          <SectionTitle>
            <p className="title">
              엠에스벤터와 함께 <span>성장하는 기업사례</span>
            </p>
            <p className="sub_title">실제 기업리뷰로 검증된 엠에스벤터의 놀라운 성장효과를 만나보세요!</p>
          </SectionTitle>

          <div className="content_box">
            <Swiper
              slidesPerView={windowMode === 'pc' ? 3 : 1}
              spaceBetween={windowMode === 'pc' ? 30 : 0}
              centeredSlides={true}
              loop={true}
              navigation={{
                // Both prevEl & nextEl are null at render so this does not work
                prevEl: prevRef2.current ? prevRef2.current : undefined,
                nextEl: nextRef2.current ? nextRef2.current : undefined,
              }}
              onInit={swiper => {
                swiper.params.navigation.prevEl = prevRef2.current
                swiper.params.navigation.nextEl = nextRef2.current
                swiper.navigation.update()
              }}
            >
              <SwiperSlide>
                <div className="img_box">
                  <img src={section2_img9} alt="" />
                </div>
                <div className="text_box">
                  <div className="logo_box">
                    <img src={section2_img9_logo} alt="" />
                  </div>
                  <strong>페디아이티앤</strong>
                  <p>
                    회사의 가파른 성장세는 모든 업무를 수기로 작성하여 처리하던 저희는 사업에 차질이 생기고 회사의
                    성장에는 걸림돌이 됐습니다. 업무의 자동화가 필요하다고 생각하던 참에 우연치 않게 엠에스벤터를 만나게
                    됐어요. 많은 전산 업체를 만나 봤지만 엠에스벤터만큼 편리하고 완벽하게 우리의 업무를 해결한 곳은
                    없었어요. 저희가 제시하는 모든 업무의 처리가 가능하다고 답변해주는 엠에스벤터를 믿고 FMS를 사용하게
                    됐습니다.
                  </p>
                </div>
                <div className="footer_box">
                  <button type="button" onClick={() => onClickEffectMove(9)}>
                    <span>Read More</span>
                    <div className="icon_box">
                      <span className="bar" />
                      <span className="right_icon material-icons-outlined">chevron_right</span>
                    </div>
                  </button>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img_box">
                  <img src={section2_img10} alt="" />
                </div>
                <div className="text_box">
                  <div className="logo_box">
                    <img src={section2_img10_logo} alt="" />
                  </div>
                  <strong>(주)태성에프엔비</strong>
                  <p>
                    태성에프엔비는 35년간 닭공장을 운영하면서 프로그램의 중요성을 잘 몰랐습니다. 대부분의 프로그램 공급
                    회사는 사용자의 입장을 고려하여 프로그램을 공급하는 것이 아닌 본인들이 개발해 놓은 프로그램에
                    사용자가 맞춰야만 하는 현실이었습니다. 업무 특성상 프로그램에 의존할 수밖에 없기에 당사에 맞는
                    프로그램을 찾으려고 수많은 업체를 만나봤지만 어느 한 곳도 우리의 업무를 이해하고 반영해 준 곳은
                    없었습니다.
                  </p>
                </div>
                <div className="footer_box">
                  <button type="button" onClick={() => onClickEffectMove(10)}>
                    <span>Read More</span>
                    <div className="icon_box">
                      <span className="bar" />
                      <span className="right_icon material-icons-outlined">chevron_right</span>
                    </div>
                  </button>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img_box">
                  <img src={section2_img4} alt="" />
                </div>
                <div className="text_box">
                  <div className="logo_box">
                    <img src={section2_img4_logo} alt="" />
                  </div>
                  <strong>김가네</strong>
                  <p>
                    엠에스벤터 프로그램을 사용하면서 좋았던 점은 고객 응대가 빠르고 친절하다는 점이었습니다. 또 고객
                    입장에서 최대한 맞춰주려고 하는 느낌을 받아서 좋았습니다.저희 회사는 엔터프라이즈 버전을 사용하고
                    있는데요, 정말 다양한 기능이 많아 저희 회사에서 필요로 하는 모든 것을 다 충족해 주고 있습니다.
                    발주닷컴이라는 주문 앱도 유저인터페이스로부터 고객이 접근하기 쉽고, 사용방법도 쉬워서
                  </p>
                </div>
                <div className="footer_box">
                  <button type="button" onClick={() => onClickEffectMove(4)}>
                    <span>Read More</span>
                    <div className="icon_box">
                      <span className="bar" />
                      <span className="right_icon material-icons-outlined">chevron_right</span>
                    </div>
                  </button>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img_box">
                  <img src={section2_img5} alt="" />
                </div>
                <div className="text_box">
                  <div className="logo_box">
                    <img src={section2_img5_logo} alt="" />
                  </div>
                  <strong>도담찜닭</strong>
                  <p>
                    가맹점들과 주문 및 미수금으로 인해 발생하게 되는 분쟁이나 불만사항이 엠에스벤터의 발주 시스템을
                    도입한 후에 제로에 가까울 정도로 줄었어요. 덕분에 가맹점과 웃으며 발전적인 이야기를 더 많이 할 수
                    있게 되었습니다. 원하는 자료를 한눈에 비교 분석할 수 있는 기능들로 업무보고의 편리성은 물론, 가맹점
                    관리에 없어서는 안되는 정보들을 얻게 되었습니다.
                  </p>
                </div>
                <div className="footer_box">
                  <button type="button" onClick={() => onClickEffectMove(5)}>
                    <span>Read More</span>
                    <div className="icon_box">
                      <span className="bar" />
                      <span className="right_icon material-icons-outlined">chevron_right</span>
                    </div>
                  </button>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img_box">
                  <img src={section2_img6} alt="" />
                </div>
                <div className="text_box">
                  <div className="logo_box">
                    <img src={section2_img6_logo} alt="" />
                  </div>
                  <strong>홍익돈까스</strong>
                  <p>
                    매출 미수금에 관련된 고민을 하던 중에 엠에스벤터의 FMS프로그램을 만나 잘 정리되었어요. 직원들도
                    엠에스벤터의 프로그램을 사용하기 전보다 훨씬 수월하게 업무에 임하고 있습니다. 시스템 사용 중
                    불편하거나 모르는 점이 있을 때 엠에스벤터의 적극적인 대응으로 프로그램 개발을 지원받아 시스템의
                    안정성이 확보되는 등 저의 회사에 좋은 발판이 되었습니다.
                  </p>
                </div>
                <div className="footer_box">
                  <button type="button" onClick={() => onClickEffectMove(6)}>
                    <span>Read More</span>
                    <div className="icon_box">
                      <span className="bar" />
                      <span className="right_icon material-icons-outlined">chevron_right</span>
                    </div>
                  </button>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img_box">
                  <img src={section2_img7} alt="" />
                </div>
                <div className="text_box">
                  <div className="logo_box">
                    <img src={section2_img7_logo} alt="" />
                  </div>
                  <strong>커피파는사람들</strong>
                  <p>
                    다른 여러 프로그램들을 사용해 봤지만 불필요하고 쓸데없는 기능들이 너무 많아 복잡하기만 하고 적응하기
                    어려웠습니다. 그에 반해 엠에스벤터 프로그램은 간단하고 직관성이 있어 시스템을 처음 도입하기에 별다른
                    어려움이 없었고 매출, 미수금, 재고관리 등 꼭 필요한 기능들은 모두 포함이 되어 있어서 부족함 없이 잘
                    사용하고 있습니다.
                  </p>
                </div>
                <div className="footer_box">
                  <button type="button" onClick={() => onClickEffectMove(7)}>
                    <span>Read More</span>
                    <div className="icon_box">
                      <span className="bar" />
                      <span className="right_icon material-icons-outlined">chevron_right</span>
                    </div>
                  </button>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img_box">
                  <img src={section2_img8} alt="" />
                </div>
                <div className="text_box">
                  <div className="logo_box">
                    <img src={section2_img8_logo} alt="" />
                  </div>
                  <strong>마왕족발</strong>
                  <p>
                    엠에스벤터의 솔루션을 도입 후 처음에는 간단한 주문을 통해 매출이나 수금 업무와 같은 기능만
                    사용했었는데, 시간이 지날수록 깨알 같은 기능이 있어 많이 놀라곤 했습니다. 전화를 통해 원하는 기능을
                    요구하면 무리한 요구임에도 불구하고 단순히 안된다고 하는 게 아닌 내부적인 회의를 통해 이유를
                    알려주시니 되려 배려 받는 기분이 들었습니다.
                  </p>
                </div>
                <div className="footer_box">
                  <button type="button" onClick={() => onClickEffectMove(8)}>
                    <span>Read More</span>
                    <div className="icon_box">
                      <span className="bar" />
                      <span className="right_icon material-icons-outlined">chevron_right</span>
                    </div>
                  </button>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img_box">
                  <img src={section2_img1} alt="" />
                </div>
                <div className="text_box">
                  <div className="logo_box">
                    <img src={section2_img1_logo2} alt="" />
                  </div>
                  <strong>자담치킨</strong>
                  <p>
                    처음에 회사를 설립하고 운영해 나갈 때 기초적인 기반이 없었는데 FMS 프로그램을 사용하면서 저희 회사의
                    기반을 단단하게 잡을 수 있었습니다. 회사의 물류체계가 아무것도 잡혀있지 않았던 저희가 프로그램을
                    사용하면서 어떻게 회사를 운영해야 할지 정확한 계획을 세울 수 있게 도와주었습니다. 엠에스벤터의
                    솔루션 덕분에 회사 매출액 성장에도 큰 도움이 되었습니다.
                  </p>
                </div>
                <div className="footer_box">
                  <button type="button" onClick={() => onClickEffectMove(1)}>
                    <span>Read More</span>
                    <div className="icon_box">
                      <span className="bar" />
                      <span className="right_icon material-icons-outlined">chevron_right</span>
                    </div>
                  </button>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img_box">
                  <img className="position_top" src={section2_img2} alt="" />
                </div>
                <div className="text_box">
                  <div className="logo_box">
                    <img src={section2_img2_logo} alt="" />
                  </div>
                  <strong>찬들푸드</strong>
                  <p>
                    엠에스벤터의 FMS프로그램을 사용하기 전에는 이것저것 신경 쓸 일도 많고 본사와 가맹점 간 제대로
                    해결되지 못한 문제들까지 업무에 번거로움이 많았습니다. 엠에스벤터를 만나고 나서는 업무의 질이
                    달라졌어요. 아날로그틱하게 돌아가던 업무와 시스템들이 체계적이고 선진화된 느낌으로 바뀌었습니다. 그
                    덕분에 매출과 거래처 수 증가에도 큰 효과를 얻었습니다. 300% 매출 신장과 거래처 수 350%가 증가한 걸
                    보면 정말 엠에스벤터와 함께하길 잘했다는 생각이 들어요.
                  </p>
                </div>
                <div className="footer_box">
                  <button type="button" onClick={() => onClickEffectMove(2)}>
                    <span>Read More</span>
                    <div className="icon_box">
                      <span className="bar" />
                      <span className="right_icon material-icons-outlined">chevron_right</span>
                    </div>
                  </button>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="img_box">
                  <img src={section2_img3} alt="" />
                </div>
                <div className="text_box">
                  <div className="logo_box">
                    <img src={section2_img3_logo} alt="" />
                  </div>
                  <strong>케이닥</strong>
                  <p>
                    엠에스벤터의 FMS를 만나기 전에는 이 회사 저 회사 알만한 프로그램들을 사용해 봤지만, 우리 회사가
                    원하는 기능을 충족해 주는 프로그램이 없어 결국 3개 이상의 프로그램을 병행해서 사용했었습니다. 여러
                    개의 프로그램을 매일 번갈아 사용하다 보니 업무시간도 오래 걸리고 효율도 많이 떨어졌죠. 무엇보다
                    월말결산이나 분기별 보고서 정리를 위한 자료를 정리할 때는 야근을 하지 않을 수 없었습니다.
                  </p>
                </div>
                <div className="footer_box">
                  <button type="button" onClick={() => onClickEffectMove(3)}>
                    <span>Read More</span>
                    <div className="icon_box">
                      <span className="bar" />
                      <span className="right_icon material-icons-outlined">chevron_right</span>
                    </div>
                  </button>
                </div>
              </SwiperSlide>
            </Swiper>
            <span className="nav left" ref={prevRef2} />
            <span className="nav right" ref={nextRef2} />
          </div>
        </Section2Container>

        <Section3Container>
          <SectionTitle>
            <p className="title">
              엠에스벤터의 <span>다양한 프로그램</span>
            </p>
            <p className="sub_title">다양한 프로그램으로 적재적소에 알맞게 사용할 수 있습니다.</p>
          </SectionTitle>
          <div className="card_list_box">
            <MainCard src={cardImg1} onClick={() => onClickSolutionMove(1)} text="편리한 물류관리" btnText="FMS" />
            <MainCard
              src={cardImg2}
              onClick={() => onClickSolutionMove(2)}
              text="어디서나 주문가능"
              btnText="발주닷컴"
            />
            <MainCard src={cardImg3} onClick={() => onClickSolutionMove(3)} text="POS전용 수발주" btnText="수발주" />
            <MainCard
              src={cardImg5}
              onClick={() => onClickSolutionMove(4)}
              text="수발주 대리서비스"
              btnText="대리주문"
            />
            <MainCard
              src={cardImg10}
              onClick={() => onClickSolutionMove(10)}
              text="공평한 순번대기"
              btnText="순번주문"
            />
            <MainCard src={cardImg4} onClick={() => onClickSolutionMove(5)} text="바코드로 이력관리" btnText="PDA" />
            <MainCard src={cardImg7} onClick={() => onClickSolutionMove(7)} text="자동화 생산관리" btnText="생산관리" />
            <MainCard
              src={cardImg11}
              onClick={() => onClickSolutionMove(11)}
              text="가맹점 불만접수처리"
              btnText="CS관리"
            />
            <MainCard
              src={cardImg6}
              onClick={() => onClickSolutionMove(6)}
              text="당일시세 자동반영"
              btnText="시세관리"
            />
            <MainCard
              src={cardImg8}
              onClick={() => onClickSolutionMove(8)}
              text="재고파악과 제품판매"
              btnText="재고현황판"
            />
            <MainCard
              src={cardImg9}
              onClick={() => onClickSolutionMove(9)}
              text="문제해결 설문조사"
              btnText="설문조사"
            />
            <MainCard
              src={cardImg12}
              onClick={() => onClickSolutionMove(12)}
              text="계수분석 경영관리"
              btnText="회계관리"
            />
          </div>
        </Section3Container>

        <Section4Container>
          <div className="custom-shape-divider-top-1637126876">
            <svg
              data-name="Layer 1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1200 120"
              preserveAspectRatio="none"
            >
              <path
                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                className="shape-fill"
              ></path>
            </svg>
          </div>
          <div className="content_box">
            <SectionTitle>
              <p className="title">
                복잡한 문제는 쉽게! <span>엠에스벤터의 솔루션</span>
              </p>
              <p className="sub_title">업계 최고 수준의 차별화된 고급 서비스를 제공합니다.</p>
            </SectionTitle>
            <div className="solution_content">
              <div className={`${solution1Active ? 'active' : ''} solution item_left`} ref={solutionRef1}>
                <div className="icon_box">
                  <span className="circle" />
                  <img src={solution1} alt="icon" className="img" />
                </div>
                <div className="text_box">
                  <div className="title">무료 표준화 개발 서비스</div>
                  <p>다양한 업무형태의 요구환경을 수용하고</p>
                  <p>표준화된 기능 개발에 적극 참여합니다.</p>
                </div>
              </div>
              <div className={`${solution2Active ? 'active' : ''} solution item_right`} ref={solutionRef2}>
                <div className="text_box text2_box">
                  <div className="title">24시간 케어 솔루션</div>
                  <p>365일 24시간 전산관리 응대 서비스로</p>
                  <p>안정성 높은 서비스 품질을 제공합니다.</p>
                </div>
                <div className="icon_box icon2_box">
                  <span className="circle" />
                  <img src={solution2} alt="icon" className="img icon2" />
                </div>
              </div>
              <div className={`${solution3Active ? 'active' : ''} solution item_left`} ref={solutionRef3}>
                <div className="icon_box">
                  <span className="circle" />
                  <img src={solution3} alt="icon" className="img" />
                </div>
                <div className="text_box">
                  <div className="title">다양한 디바이스 지원</div>
                  <p>PC와 모바일 그리고 웹 기반 등의</p>
                  <p>솔루션 구축으로 업무 접근성을 지원합니다.</p>
                </div>
              </div>
            </div>
          </div>
        </Section4Container>
        <DownCenterContainer>
          <div className="down_inner">
            <h2>발주닷컴 다운로드</h2>
            <div className="down_box">
              <div className="pc">
                <p>PC 프로그램 다운로드</p>
                <div className="pc_btn">
                  <a href={baljuOs} download="balju1.0.4.exe">
                    <img src={downImg3} alt />
                  </a>
                </div>
              </div>
              <div className="mobile">
                <p>Android 어플 다운로드</p>
                <div className="mobile_btn">
                  <a href="https://play.google.com/store/apps/details?id=com.msb.fms_order" target="_blank">
                    <img src={downImg1} alt />
                  </a>
                </div>
              </div>
              <div className="mobile">
                <p>iOS 어플 다운로드</p>
                <div className="mobile_btn">
                  <a href="https://itunes.apple.com/app/id1470599409?mt=8" target="_blank">
                    <img src={downImg2} alt />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </DownCenterContainer>
        <CrapeBox main />

        <Section6Container>
          <SectionTitle>
            <p className="title">
              고객과 함께 하는 <span>엠에스벤터의 소식</span>
            </p>
            <p className="sub_title">엠에스벤터의 최신 정보를 확인하세요!</p>
          </SectionTitle>
          <div className="content_box">
            <ul>
              {noticeList.map(v => (
                <li key={v.id}>
                  <button type="button" onClick={() => onClickNoticeModalOpen(v.id)}>
                    <div className="img_box">
                      <img src={v.thumbnail} alt="" />
                    </div>
                    <div className="title_box">
                      <p>{v.title}</p>
                    </div>
                  </button>
                </li>
              ))}
            </ul>
            <NoticeModal isActive={noticeActive} closeEvent={noticeClose} title={noticeTitle} content={noticeContent} />
          </div>
        </Section6Container>

        <Section7Container>
          <div className="content">
            <div className="text_box">
              <div className="top">
                <p>궁금하신 내용은 언제든지 문의해주세요.</p>
                <p>입력하신 정보를 토대로 담당자와의</p>
                <p> 1:1 맞춤 상담을 제공합니다.</p>
              </div>
              <div className="btm">
                <p>
                  <img src={contact_call} alt="" /> 1544-7120
                </p>
                <p>
                  <img src={contact_mail} alt="" /> msb@benter.co.kr
                </p>
              </div>
            </div>
            <ContactUsForm />
          </div>
        </Section7Container>
        <Footer />
      </MainContainer>
    </>
  )
}

export default MainPage
