import styled, { css } from 'styled-components'
import transitions from '../../assets/css/transitions'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const VideoBox = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 40;
  video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    object-fit: cover;
  }

  .mouse_box {
    position: absolute;
    z-index: 20;
    left: 50%;
    bottom: 100px;
    transform: translateX(-50%);

    button {
      padding-top: 60px;
      position: relative;
      color: #fff;
    }
    button span {
      position: absolute;
      top: 0;
      left: 50%;
      width: 30px;
      height: 50px;
      margin-left: -15px;
      border: 2px solid #fff;
      border-radius: 50px;
      box-sizing: border-box;
    }
    button span::before {
      position: absolute;
      top: 10px;
      left: 50%;
      content: '';
      width: 6px;
      height: 6px;
      margin-left: -3px;
      background-color: #fff;
      border-radius: 100%;
      ${css`
        animation: ${transitions.mouseScroll} 2s infinite;
      `}

      box-sizing: border-box;
    }
  }
`

export const ParticlesBox = styled.div`
  position: relative;
  z-index: 30;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  #tsparticles {
    position: relative;
  }
`

export const TypewriterBox = styled.div`
  position: relative;
  z-index: 100;
  color: #fff;
  line-height: 1.5;
  text-align: center;
  //font-family: 'Noto Sans KR', sans-serif;
  /* font-size: 80px; */
  font-size: 5vw;
  font-weight: 600;
  span {
    font-family: 'Playfair Display', 'Noto Sans KR', serif;
  }
  @media screen and (max-width: 600px) {
    font-size: 7vw;
  }
`

export const ObjectBox = styled.div`
  position: relative;
  width: 100%;

  //height: 100%;
  z-index: 40;
  //background-color: #000;
  .object_container {
    max-width: 1750px;
    position: relative;
    width: 100%;
    //max-width: 1300px;
    margin: 0 auto;
    box-sizing: border-box;
    background-color: #000;
  }

  box-sizing: border-box;
  .white_line {
    position: absolute;
    background: white;
    z-index: 10;
  }
  .top_line {
    width: 100%;
    height: 3px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .center_line {
    width: 3px;
    height: 100%;
    top: 0;
    left: calc(50% - 1.5px);
  }
  .bottom_line {
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
  }
  .left_line {
    width: 3px;
    height: 100%;
    left: 0;
    top: 0;
  }
  .right_line {
    width: 3px;
    height: 100%;
    right: 0;
    top: 0;
  }

  .top_active {
    width: 0;
    transition: 1s ease-out;
  }
  &.active .top_active {
    width: 100%;
  }

  .center_active {
    height: 0;
    transition-delay: 2s;
    transition: 3s ease-out;
  }
  &.active .center_active {
    height: 100%;
  }

  .left_active,
  .right_active {
    height: 0;
    transition: 3s ease-out;
    transition-delay: 1s;
  }

  &.active .left_active,
  &.active .right_active {
    height: 100%;
  }

  .item_container {
    position: relative;
    padding-top: 4px;
    box-sizing: border-box;
  }

  .item_box {
    width: 100%;
    color: #fff;
    padding-top: 50%;
    position: relative;
  }

  .item_content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .item {
    width: 50%;
  }

  .object_image_box {
    overflow: hidden;
    img {
      display: block;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
    }
    video {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .mail_text a {
    color: #fff;
    font-size: 5vw;
    font-family: 'Playfair Display', serif;
    font-weight: 600;
    background: #000;
  }

  .text_box {
    padding: 0 40px;
    box-sizing: border-box;
  }
  .text_box.active {
  }

  .text_content {
    padding: 0;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    text-align: center;
    overflow: hidden;
    max-height: 6px;

    transition: 1s ease-out;

    font-weight: 500;
    position: relative;
    .title {
      font-size: 40px;
      font-family: 'Playfair Display', serif;
      span {
        font-size: 70px;
        font-family: 'Playfair Display', serif;
        font-weight: 600;
      }
    }
    .sub_title {
      font-size: 40px;
      font-family: 'Playfair Display', serif;
      padding: 40px 0;
      box-sizing: border-box;
    }
    p {
      font-size: 30px;
      line-height: 1.5;
      font-weight: 200;
    }
  }

  .text_content.active {
    max-height: 500px;
    padding: 40px 0 60px;
  }

  .text_line {
    padding: 3px 0;
    width: 100%;
    margin: 0 auto;
    background-color: #000;
  }

  .move_text_box {
    padding: 50px 0;
    box-sizing: border-box;
    color: #fff;
    max-width: 100%;
    overflow: hidden;
    .marquee {
      white-space: nowrap;
      overflow: hidden;
      display: inline-block;
      ${css`
        animation: ${transitions.InfiniteLineMove} 15s linear infinite;
      `}
    }

    p {
      display: inline-block;
      text-align: center;
      font-size: 70px;
      font-weight: bold;
      white-space: nowrap;
      font-family: 'Playfair Display', serif;
    }
    p + p {
      padding-left: 40px;
    }
  }

  .progress_title {
    background-color: #000;
    color: #fff;
    font-size: 70px;
    font-family: 'Playfair Display', serif;
    padding: 50px 0;
    box-sizing: border-box;
    text-align: center;
    font-weight: 600;
  }

  .progress_container {
    width: 100%;
    margin: 0 auto;
    background-color: #000;
    padding: 70px 0;
  }

  .progress_box {
    width: 100%;
    max-width: 1000px;
    padding: 0 20px;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 80px;
  }

  .progress_item {
    //transform: rotate(-90deg);
    width: 33.33%;
    .progress_txt {
      color: #fff;
      font-size: 80px;
      font-family: 'Playfair Display', serif;
      span {
        font-family: 'Playfair Display', serif;
      }
    }
    .txt {
      color: #fff;
      text-align: center;
      font-size: 40px;
      font-weight: 500;
      margin-top: 40px;
    }
  }

  .contact_title {
    background-color: #000;
    color: #fff;
    font-size: 70px;
    font-family: 'Playfair Display', serif;
    padding: 50px 0;
    box-sizing: border-box;
    text-align: center;
    font-weight: 600;
  }

  .from_box {
    background-color: #000;
    overflow: hidden;
    .circle {
      position: absolute;

      height: auto;
      width: 100%;
    }

    .circle.left {
      max-width: 900px;
      top: 0;
      left: 0;
      ${css`
        animation: ${transitions.infiniteLeftRotate} 60s linear infinite;
      `}
    }

    .circle.right {
      max-width: 1300px;
      bottom: 0;
      right: 0;
      ${css`
        animation: ${transitions.infiniteRightRotate} 60s linear infinite;
      `}
    }
  }
  .form_content {
    /* max-width: 1750px; */
    margin: 0 auto;
    width: 100%;
    position: relative;
    z-index: 10;
  }

  .mail_text {
    text-align: center;
  }

  .form_item {
    box-sizing: border-box;
    background: #000;
    padding: 10px;
    .input_box {
      border-bottom: 1px solid #fff;
    }
    .input_box + div {
      margin-top: 20px;
    }
    input {
      color: #fff;
      background-color: transparent;
      display: block;
      padding: 10px 12px;
      box-sizing: border-box;
      font-size: 30px;
      border: none;
      font-family: 'Playfair Display', 'Noto Sans KR', serif;

      &:focus {
        outline: none;
      }
    }
    textarea {
      width: 100%;
      color: #fff;
      background-color: transparent;
      display: block;
      padding: 10px 12px;
      box-sizing: border-box;
      font-size: 30px;
      border: none;
      resize: none;
      height: 60px;
      transition: height 0.3s ease-out;
      font-family: 'Playfair Display', 'Noto Sans KR', serif;
      &:focus {
        outline: none;
        height: 120px;
      }
    }
    .button_box {
      text-align: right;
      button {
        border: 1px solid #fff;
        color: #fff;
        padding: 5px 10px;
        box-sizing: border-box;
        width: 120px;
        font-size: 24px;
        background-color: #000;
        font-family: 'Playfair Display', 'Noto Sans KR', serif;
        transition: 0.3s;
      }
      button:hover {
        background: #fff;
        color: #000;
      }
    }
  }

  @media screen and (max-width: 1170px) {
    .text_content.active {
      padding: 20px 0 40px;
    }
    .text_content .sub_title {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 1000px) {
    .object_container {
      padding-bottom: 200px;
    }
    .mobile_remove {
      display: none;
    }

    .item_box {
      padding-top: 0;
    }
    .item_content {
      position: relative;
      flex-wrap: wrap;
    }
    .item {
      width: 100%;
    }
    .text_box {
      order: 2;

      .text_content .sub_title {
        font-size: 40px;
      }
    }
    .object {
      order: 1;
      position: relative;
      padding-top: 100%;
    }
    .object_image_box {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      height: auto;
    }

    .move_text_box {
      padding: 25px 0;
    }

    .form_content .item_content .object {
      padding-top: 0;
    }
    .mail_text {
      margin: 70px 0;
      a {
        font-size: 10vw;
      }
    }
    .form_content .item_content .text_box {
      margin-bottom: 50px;
    }
  }
  @media screen and (max-width: 750px) {
    .progress_title {
      padding: 25px 0;
      font-size: 50px;
    }
    .contact_title {
      padding: 25px 0;
      font-size: 50px;
    }

    .progress_box {
      gap: 50px;
    }
    .progress_item .progress_txt {
      font-size: 50px;
    }
    .progress_item .txt {
      font-size: 25px;
    }
  }
  @media screen and (max-width: 600px) {
    .text_box {
      padding: 0px;
      .text_content .title {
        font-size: 30px;
      }
      .text_content .sub_title {
        padding: 20px 0;
        font-size: 30px;
      }
      .text_content p {
        font-size: 25px;
      }
    }

    .move_text_box p {
      font-size: 50px;
    }

    .progress_title {
      font-size: 35px;
    }
    .contact_title {
      font-size: 35px;
    }

    .progress_box {
      gap: 20px;
    }
    .progress_item .progress_txt {
      font-size: 40px;
    }
  }
  @media screen and (max-width: 450px) {
    .text_box .text_content {
      .title {
        font-size: 23px;
        span {
          font-size: 50px;
        }
      }
      .sub_title {
        padding: 0 0 22px;
        font-size: 24px;
      }
      p {
        font-size: 18px;
      }
    }

    .progress_item .progress_txt {
      font-size: 30px;
    }
    .progress_item .txt {
      font-size: 18px;
    }

    .form_item input {
      font-size: 22px;
    }
    .form_item textarea {
      font-size: 22px;
    }
  }
`

export const ParallaxBox = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 20;

  color: #fff;
  line-height: 1.5;
  text-align: center;
  .playfair_display {
    font-family: 'Playfair Display', serif;
    font-size: 120px;
  }

  .noto_sans {
    font-family: 'Noto Sans KR', sans-serif;
    font-size: 60px;
    font-weight: 600;
  }

  .content {
    width: 100%;
    transition: transform 1s ease-out;
    //transform: translateY(-50%);
  }
  .content2 {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: transform 1s ease-out;
  }

  @media screen and (max-width: 1000px) {
    .playfair_display {
      font-size: 40px;
    }

    .noto_sans {
      font-size: 30px;
    }
  }
`

export const TextParallaxBox = styled.div`
  width: 100%;
  //height: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    object-fit: cover;
  }
`

export const ImageParallaxBox = styled.div`
  width: 100%;
  height: 100%;

  position: absolute;
  left: 0;
  top: 0;
  transition: transform 1s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  p {
    position: relative;
    z-index: 10;
    color: #fff;
    //font-size: 80px;
    //line-height: 1.5;
    //font-weight: bold;
  }

  button {
    color: #fff;
    font-size: 24px;
    display: flex;
    align-items: center;
    position: relative;
    margin-top: auto;
    margin-bottom: 100px;
    z-index: 10;
    span {
      font-size: 35px;
      animation: ${transitions.moveOpacity} 3s forwards ease-in-out infinite;
    }
  }
  //transform: translateY(100%);
`

export const FooterBox = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;

  .link_move_box {
    padding: 40px 0;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #000;
    .text_box {
      font-size: 30px;
      margin-bottom: 20px;
    }
    button {
      color: #fff;
      font-size: 24px;
      display: flex;
      align-items: center;
      span {
        font-size: 35px;
        animation: ${transitions.moveOpacity} 3s forwards ease-in-out infinite;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    .link_move_box {
      .text_box {
        display: none;
      }
    }
  }
  //transform: translateY(344px);
`
