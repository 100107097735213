import React, { useEffect, useState } from 'react'
import { ModalBox } from './styles'
import { Viewer } from '@toast-ui/react-editor'
import '@toast-ui/editor/dist/toastui-editor-viewer.css'

const NoticeModal = ({ isActive, closeEvent, title, content }) => {
  // const [isActive, setIsActive] = useState(false)
  const [closed, setClosed] = useState(true)

  useEffect(() => {
    document.body.style.overflowY = isActive ? 'hidden' : 'initial'

    let timeoutId

    if (isActive) {
      timeoutId = setTimeout(() => {
        setClosed(false)
      }, 200)
    } else {
      timeoutId = setTimeout(() => {
        setClosed(true)
      }, 200)
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [isActive])

  return (
    <>
      {!closed && (
        <>
          <ModalBox isActive={isActive}>
            <div className="content">
              <div>{title}</div>
              <Viewer initialValue={content} />
              <button onClick={closeEvent}>홈페이지로 나가기</button>
            </div>
            <div className="modal_back" onClick={closeEvent} />
          </ModalBox>
        </>
      )}
    </>
  )
}

export default NoticeModal
