import React from 'react'
import { QuickMenuContent } from './styles'

import icon1 from '../../assets/images/fix2.png'
// import icon4 from '../../assets/images/fix4.png'
import icon4 from '../../assets/images/fix4_small.png'
import icon5 from '../../assets/images/fms_order_icon.png'
import androidIcon from '../../assets/images/fix4-1.png'
import iosIcon from '../../assets/images/fix4-2.png'
import TopIcon from '../../assets/images/top_icon.png'

import baljuOs from '../../assets/images/balju-setup-1.0.5.exe'
import fms_order_setup from '../../assets/images/fms_order_setup.exe'

const QuickMenuBox = () => {
  const onClickTopBtn = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
      left: 0,
    })
  }

  return (
    <>
      <QuickMenuContent>
        <div className="btn_box">
          <button type="button" className="quick_btn">
            <a href={baljuOs} download="balju1.0.1.exe">
              <div className="icon_btn blue">
                <img src={icon4} alt="icon1" className="icon1" />
              </div>
            </a>

            {/*<div className="side_text">*/}
            {/*  <a href="https://play.google.com/store/apps/details?id=com.msb.fms_order" target="_blank">*/}
            {/*    <img src={androidIcon} alt="android" />*/}
            {/*  </a>*/}
            {/*  <a href="https://itunes.apple.com/app/id1470599409?mt=8" target="_blank">*/}
            {/*    <img src={iosIcon} alt="ios" />*/}
            {/*  </a>*/}
            {/*</div>*/}
          </button>
        </div>
        <div className="btn_box">
          <button type="button" className="quick_btn">
            <a href={fms_order_setup} download="fms_order_setup.exe">
              <div className="icon_btn">
                <img src={icon5} alt="icon1" className="icon1" />
              </div>
            </a>
          </button>
        </div>
        <div className="btn_box">
          <button type="button" className="quick_btn" onClick={onClickTopBtn}>
            <div className="icon_btn">
              <img src={TopIcon} alt="top" className="top" />
              <span className="text">TOP</span>
            </div>
          </button>
        </div>
      </QuickMenuContent>
    </>
  )
}

export default QuickMenuBox
