import React, { useEffect, useRef, useState } from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import CrapeBox from '../../components/CrapeBox'
import {
  ContactUsBox,
  Container,
  HistoryBox,
  HistoryScrollBox,
  IconBox,
  LineBox,
  TextBox,
  TopBannerBox,
} from './styles'

import banner from '../../assets/images/sub_c_bg.png'
import KakaoMap from '../../components/KakaoMap'
import QuickMenuBox from '../../components/QuickMenuBox'
import QuickSideMenu from '../../components/QuickSideMenu'

const AboutPage = () => {
  const historyRef = useRef(null)
  const [historyStart, setHistoryStart] = useState(false)
  const [scrollState, setScrollState] = useState(0)
  const [winW, setWinW] = useState(document.body.clientWidth)

  const iconBoxRef = useRef(null)
  const historyBoxRef = useRef(null)
  const timeLineBoxRef = useRef(null)

  const timeLine1Ref = useRef(null)
  const [timeLine1Active, setTimeLine1Active] = useState(false)

  const timeLine2Ref = useRef(null)
  const [timeLine2Active, setTimeLine2Active] = useState(false)

  const timeLine3Ref = useRef(null)
  const [timeLine3Active, setTimeLine3Active] = useState(false)

  const timeLine4Ref = useRef(null)
  const [timeLine4Active, setTimeLine4Active] = useState(false)

  const timeLine5Ref = useRef(null)
  const [timeLine5Active, setTimeLine5Active] = useState(false)

  const timeLine6Ref = useRef(null)
  const [timeLine6Active, setTimeLine6Active] = useState(false)

  const timeLine7Ref = useRef(null)
  const [timeLine7Active, setTimeLine7Active] = useState(false)

  const timeLine8Ref = useRef(null)
  const [timeLine8Active, setTimeLine8Active] = useState(false)

  const timeLine9Ref = useRef(null)
  const [timeLine9Active, setTimeLine9Active] = useState(false)

  const timeLine10Ref = useRef(null)
  const [timeLine10Active, setTimeLine10Active] = useState(false)

  const timeLine11Ref = useRef(null)
  const [timeLine11Active, setTimeLine11Active] = useState(false)

  const timeLine12Ref = useRef(null)
  const [timeLine12Active, setTimeLine12Active] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    function onResize() {
      setWinW(document.body.clientWidth)
    }
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  useEffect(() => {
    function onScroll(e) {
      if (winW > 1000) {
        scrollStartEvent(e)
      }
    }
    window.addEventListener('mousewheel', onScroll)
    return () => {
      window.removeEventListener('mousewheel', onScroll)
    }
  }, [scrollState])

  const scrollStartEvent = e => {
    if (timeLine1Ref.current && timeLine1Ref.current.getBoundingClientRect().top < document.body.clientHeight * 0.7) {
      setTimeLine1Active(true)
    } else {
      setTimeLine1Active(false)
    }

    if (timeLine2Ref.current && timeLine2Ref.current.getBoundingClientRect().top < document.body.clientHeight * 0.7) {
      setTimeLine2Active(true)
    } else {
      setTimeLine2Active(false)
    }

    if (timeLine3Ref.current && timeLine3Ref.current.getBoundingClientRect().top < document.body.clientHeight * 0.7) {
      setTimeLine3Active(true)
    } else {
      setTimeLine3Active(false)
    }

    if (timeLine4Ref.current && timeLine4Ref.current.getBoundingClientRect().top < document.body.clientHeight * 0.7) {
      setTimeLine4Active(true)
    } else {
      setTimeLine4Active(false)
    }

    if (timeLine5Ref.current && timeLine5Ref.current.getBoundingClientRect().top < document.body.clientHeight * 0.7) {
      setTimeLine5Active(true)
    } else {
      setTimeLine5Active(false)
    }

    if (timeLine6Ref.current && timeLine6Ref.current.getBoundingClientRect().top < document.body.clientHeight * 0.7) {
      setTimeLine6Active(true)
    } else {
      setTimeLine6Active(false)
    }

    if (timeLine7Ref.current && timeLine7Ref.current.getBoundingClientRect().top < document.body.clientHeight * 0.7) {
      setTimeLine7Active(true)
    } else {
      setTimeLine7Active(false)
    }

    if (timeLine8Ref.current && timeLine8Ref.current.getBoundingClientRect().top < document.body.clientHeight * 0.7) {
      setTimeLine8Active(true)
    } else {
      setTimeLine8Active(false)
    }

    if (timeLine9Ref.current && timeLine9Ref.current.getBoundingClientRect().top < document.body.clientHeight * 0.7) {
      setTimeLine9Active(true)
    } else {
      setTimeLine9Active(false)
    }

    if (timeLine10Ref.current && timeLine10Ref.current.getBoundingClientRect().top < document.body.clientHeight * 0.7) {
      setTimeLine10Active(true)
    } else {
      setTimeLine10Active(false)
    }

    if (timeLine11Ref.current && timeLine11Ref.current.getBoundingClientRect().top < document.body.clientHeight * 0.7) {
      setTimeLine11Active(true)
    } else {
      setTimeLine11Active(false)
    }

    if (timeLine12Ref.current && timeLine12Ref.current.getBoundingClientRect().top < document.body.clientHeight * 0.7) {
      setTimeLine12Active(true)
    } else {
      setTimeLine12Active(false)
    }

    if (
      historyRef.current.offsetTop - window.scrollY < document.documentElement.clientHeight * 0.2 &&
      window.scrollY <
        historyRef.current.offsetTop + historyRef.current.clientHeight - document.documentElement.clientHeight
    ) {
      setHistoryStart(true)
      if (e.deltaY > 0) {
        scrollEvent(-1)
      } else {
        scrollEvent(1)
      }
    } else if (historyRef.current.offsetTop - window.scrollY > document.documentElement.clientHeight * 0.2) {
      setHistoryStart(false)
      setScrollState(0)
    } else if (
      window.scrollY >
      historyRef.current.offsetTop + historyRef.current.clientHeight - document.documentElement.clientHeight
    ) {
      setScrollState(-timeLineBoxRef.current.clientHeight + historyBoxRef.current.clientHeight)
    }
  }

  const scrollEvent = e => {
    if (e === -1 && scrollState > -timeLineBoxRef.current.clientHeight + historyBoxRef.current.clientHeight) {
      setScrollState(scrollState + -timeLineBoxRef.current.clientHeight * 0.03)
    }

    if (e === 1 && scrollState <= 0) {
      setScrollState(scrollState + timeLineBoxRef.current.clientHeight * 0.03)
    }

    // console.log(timeLine1Ref.current.getBoundingClientRect().top)
  }

  return (
    <>
      <Container>
        <Header />
        <QuickSideMenu />

        <TopBannerBox>
          <div className="img_box">
            <img src={banner} alt="" />
          </div>
          <div className="text_box">
            <p>이론과시장을 연결하여 가치를 창조하고</p>
            <p>한국 프랜차이즈 시스템의 선진화를 추구합니다</p>
          </div>
        </TopBannerBox>

        <TextBox>
          <div className="max_box">
            <div className="text_content right">
              <h3>프랜차이즈관리를 위한 최고의 솔루션을 제공합니다</h3>
              <p>
                업계 최초 프랜차이즈 전문관리 시스템 개발을 20년간 매진해온 엠에스벤터는
                <br />
                고객의 문제를 성장의 기회로 삼아 끊임없이 개선과 혁신을 추구하며 <br />
                고객의 문제를 해결하는 데서 행복을 추구하는 기업입니다.
              </p>
            </div>
            <div className="text_content">
              <h3>고객에게 특화된 맞춤 솔루션 서비스를 제공합니다 </h3>
              <p>
                엠에스벤터의 프랜차이즈 관리프로그램은 타 업체에서는 쉽사리 하지않는 <br />
                차별화된 기능까지도 서비스가 가능합니다. <br />
                판매하고 모르는 척 하지 않습니다, 개발사가 만든 솔루션에 고객이 맞추어주기를 원하지 않습니다. <br />
                변화하는 유통생태계에 유일무이한 고객 맞춤서비스로 함께 성장하기를 바랍니다. <br />
                우리 회사의 일처럼 고객의 요구사항에 귀 기울이며 개선하기 위해 노력하겠습니다.
              </p>
            </div>
          </div>
        </TextBox>

        <HistoryScrollBox ref={historyRef}>
          <div className="sticky_box">
            <div className="sticky_content">
              <HistoryBox
                // style={{
                //   top: `${document.documentElement.clientHeight / 2 - 300}px`,
                // }}
                ref={historyBoxRef}
              >
                <div className="content_box">
                  <div className="title_box">
                    <h3>History</h3>
                    <h4>우리가 걸어온 길 입니다</h4>
                  </div>
                  <div
                    className="time_line_box"
                    style={{ transform: `translateY(${scrollState}px)` }}
                    ref={timeLineBoxRef}
                  >
                    <LineBox className={`${timeLine2Active ? 'active' : ''} line_box left`} ref={timeLine2Ref}>
                      <div className="year">2022</div>
                      <ul>
                        <li>
                          <span className="num">02</span>
                          <span className="txt">엠에스벤터 X 성진에이에스 MOU 체결</span>
                        </li>
                      </ul>
                    </LineBox>
                    <LineBox className={`${timeLine1Active ? 'active' : ''} line_box`} ref={timeLine1Ref}>
                      <div className="year">2021</div>
                      <ul>
                        <li>
                          <span className="num">09</span>
                          <span className="txt">차량 순번 호출 시스템 개발 완료</span>
                        </li>
                        <li>
                          <span className="num">05</span>
                          <span className="txt">CRM 고객 관리 시스템 개발 완료</span>
                        </li>
                        <li>
                          <span className="num">01</span>
                          <span className="txt">CPM 시세 관리 시스템 개발 완료</span>
                        </li>
                      </ul>
                    </LineBox>
                    <LineBox className={`${timeLine2Active ? 'active' : ''} line_box left`} ref={timeLine2Ref}>
                      <div className="year">2020</div>
                      <ul>
                        <li>
                          <span className="num">10</span>
                          <span className="txt">(계)육가공 MES 생산 관리 시스템 개발 완료</span>
                        </li>
                        <li>
                          <span className="num">08</span>
                          <span className="txt">OMS 순번 대기 주문 시스템 개발 완료</span>
                        </li>
                        <li>
                          <span className="num">04</span>
                          <span className="txt">OMS 대리 주문 시스템 개발 완료</span>
                        </li>
                      </ul>
                    </LineBox>
                    <LineBox className={`${timeLine3Active ? 'active' : ''} line_box`} ref={timeLine3Ref}>
                      <div className="year">2019</div>
                      <ul>
                        <li>
                          <span className="num">07</span>
                          <span className="txt">발주닷컴 출시</span>
                        </li>
                        <li>
                          <span className="num">05</span>
                          <span className="txt">주문현황판 개발완료</span>
                        </li>
                      </ul>
                    </LineBox>
                    <LineBox className={`${timeLine4Active ? 'active' : ''} line_box left`} ref={timeLine4Ref}>
                      <div className="year">2018</div>
                      <ul>
                        <li>
                          <span className="num">12</span>
                          <span className="txt">축산물 이력관리 솔루션 개발완료</span>
                        </li>
                        <li>
                          <span className="num">07</span>
                          <span className="txt">설문조사 프로그램 개발완료</span>
                        </li>
                        <li>
                          <span className="num">02</span>
                          <span className="txt">프랜차이즈 START-UP 기업 솔루션 출시</span>
                        </li>
                      </ul>
                    </LineBox>
                    <LineBox className={`${timeLine5Active ? 'active' : ''} line_box`} ref={timeLine5Ref}>
                      <div className="year">2017</div>
                      <ul>
                        <li>
                          <span className="num">12</span>
                          <span className="txt">POS 시스템의 사용 요금에 대한 과금 시스템 및 과금 방법 특허 등록</span>
                        </li>
                        <li>
                          <span className="num">07</span>
                          <span className="txt">ENTERPRISE - F.M.S 물류 디자인 솔루션 개발완료</span>
                        </li>
                        <li>
                          <span className="num">06</span>
                          <span className="txt">매장홍보영물 통합 솔루션 개발완료</span>
                        </li>
                        <li>
                          <span className="num">03</span>
                          <span className="txt">KIOSK 무인 판매 시스템 개발완료</span>
                        </li>
                      </ul>
                    </LineBox>
                    <LineBox className={`${timeLine6Active ? 'active' : ''} line_box left`} ref={timeLine6Ref}>
                      <div className="year">2016</div>
                      <ul>
                        <li>
                          <span className="num">04</span>
                          <span className="txt">NFC 암호화 인증 특허 출원</span>
                        </li>
                        <li>
                          <span className="num">02</span>
                          <span className="txt">이어달리기POS, 이어달리기 주문어플TTA GS(Good Software)인증 2건</span>
                        </li>
                        <li>
                          <span className="num">01</span>
                          <span className="txt">이어달리기주문 어플, 스마트POS 저작권위원회 프로그램 등록</span>
                        </li>
                      </ul>
                    </LineBox>
                    <LineBox className={`${timeLine7Active ? 'active' : ''} line_box`} ref={timeLine7Ref}>
                      <div className="year">2015</div>
                      <ul>
                        <li>
                          <span className="num">12</span>
                          <span className="txt">이어달리기POS 저작권 위원회 프로그램 등록</span>
                        </li>
                        <li>
                          <span className="num">07</span>
                          <span className="txt">이어달리기, 요이땅 특허청 상표등록</span>
                        </li>
                        <li>
                          <span className="num">06</span>
                          <span className="txt">독일 Cebit 2015박람회 참관</span>
                        </li>
                      </ul>
                    </LineBox>
                    <LineBox className={`${timeLine8Active ? 'active' : ''} line_box left`} ref={timeLine8Ref}>
                      <div className="year">2014</div>
                      <ul>
                        <li>
                          <span className="num">10</span>
                          <span className="txt">NFC를 이용한 결제 알고리즘 특허 출원</span>
                        </li>
                        <li>
                          <span className="num">09</span>
                          <span className="txt">빅데이터3개년 사업 계획 수립</span>
                        </li>
                        <li>
                          <span className="num">07</span>
                          <span className="txt">계수 분석 알고리즘 개발완료</span>
                        </li>
                        <li>
                          <span className="num">06</span>
                          <span className="txt">인천정보산업진흥원IDC 서버 확충 시스템 구축</span>
                        </li>
                      </ul>
                    </LineBox>
                    <LineBox className={`${timeLine9Active ? 'active' : ''} line_box`} ref={timeLine9Ref}>
                      <div className="year">2013</div>
                      <ul>
                        <li>
                          <span className="num">11</span>
                          <span className="txt">연세대학교 미래교육원 물류 / 계수관리 강의 진행</span>
                        </li>
                        <li>
                          <span className="num">03</span>
                          <span className="txt">데이콤IDC 서버 확충 설립</span>
                        </li>
                      </ul>
                    </LineBox>
                    <LineBox className={`${timeLine10Active ? 'active' : ''} line_box left`} ref={timeLine10Ref}>
                      <div className="year">2012</div>
                      <ul>
                        <li>
                          <span className="num">10</span>
                          <span className="txt">프렌차이즈 영업 관리 시스템 GS인증 획득</span>
                        </li>
                        <li>
                          <span className="num">09</span>
                          <span className="txt">한국 저작원 위원회에 FMS와 ORDER P/G 저작권 등록</span>
                        </li>
                      </ul>
                    </LineBox>
                    <LineBox className={`${timeLine11Active ? 'active' : ''} line_box`} ref={timeLine11Ref}>
                      <div className="year">2011</div>
                      <ul>
                        <li>
                          <span className="num">04</span>
                          <span className="txt">한국형 프렌차이즈 관리 시스템 개발 기회 착수</span>
                        </li>
                        <li>
                          <span className="num">03</span>
                          <span className="txt">연세대학교 경영학과 명예강사 등록</span>
                        </li>
                      </ul>
                    </LineBox>
                    <LineBox className={`${timeLine12Active ? 'active' : ''} line_box left`} ref={timeLine12Ref}>
                      <div className="year">2010</div>
                      <ul>
                        <li>
                          <span className="num">05</span>
                          <span className="txt">연세대학교 ADMP 경영자 과정 강의 진행</span>
                        </li>
                        <li>
                          <span className="num">04</span>
                          <span className="txt">사단법인 한국유통물류 정책학위 상임 이사</span>
                        </li>
                        <li>
                          <span className="num">03</span>
                          <span className="txt">경희대학교경영 대학원 프렌차이즈 최고 전문가 과정 강의 진행</span>
                        </li>
                      </ul>
                    </LineBox>
                    <LineBox className={`${timeLine12Active ? 'active' : ''} line_box`} ref={timeLine12Ref}>
                      <div className="year">00-09</div>
                      <ul>
                        <li>
                          <span className="num min">09년 09</span>
                          <span className="txt txt_min">거래명세서 디자인 특허청 디자인 등록</span>
                        </li>
                        <li>
                          <span className="num min">09년 04</span>
                          <span className="txt txt_min">중앙대학교 외식경영전문대학원 계수관리 강의 진행</span>
                        </li>
                        <li>
                          <span className="num min">08년 03</span>
                          <span className="txt txt_min">
                            성균관대학교 프랜차이즈 최고경영자 과정 프렌차이즈 물류 강의 진행
                          </span>
                        </li>
                        <li>
                          <span className="num min">07년 10</span>
                          <span className="txt txt_min">NICE 정보 통신과 카드 단말기 주문 입력 시스템 개발 완료</span>
                        </li>
                        <li>
                          <span className="num min">06년 08</span>
                          <span className="txt txt_min">M&A 기업 인수합병(Sorftware 개발회사)</span>
                        </li>
                        <li>
                          <span className="num min">05년 01</span>
                          <span className="txt txt_min">산학연 공동연구과제 완료(프렌차이즈 유통 전산시스템)</span>
                        </li>
                        <li>
                          <span className="num min">04년 07</span>
                          <span className="txt txt_min">내일신문 부동산 경매 칼럼 연재(총 192회)</span>
                        </li>
                        <li>
                          <span className="num min">03년 05</span>
                          <span className="txt txt_min">
                            연세대학교 상남경영원 FCEO 경영자 과정 프랜차이즈 물류 관리
                          </span>
                        </li>
                        <li>
                          <span className="num min">02년 11</span>
                          <span className="txt txt_min">한국인터넷정보센터 공인 IPS 경영 컨설팅</span>
                        </li>
                        <li>
                          <span className="num min">01년 09</span>
                          <span className="txt txt_min">국내 최초 프랜차이즈 경영 물류관리 시스템 개발 완료</span>
                        </li>
                        <li>
                          <span className="num min">01년 07</span>
                          <span className="txt txt_min">가맹점 ARS 주문 집계 시스템 개발 완료</span>
                        </li>
                        <li>
                          <span className="num min">00년 03</span>
                          <span className="txt txt_min">프랜차이즈 경영 물류관리 시스템 개발 시작</span>
                        </li>
                      </ul>
                    </LineBox>
                  </div>
                </div>
              </HistoryBox>
            </div>
          </div>
        </HistoryScrollBox>

        <IconBox ref={iconBoxRef}>
          <div className="title_box">MSBENTER’S STRENGTH</div>
          <div className="item_box">
            <div className="icon_content">
              <div className="line_box">
                <span className="number_box">01</span>
                <h3>전문성</h3>
                <p>업계 최초 프랜차이즈 업무</p>
                <p>전산화 도입과</p>
                <p>전문성 확보</p>
              </div>
            </div>
            <div className="icon_content">
              <div className="line_box">
                <span className="number_box">02</span>
                <h3>편리성</h3>
                <p>사용성이 편리한</p>
                <p>실무형 인터페이스 환경</p>
                <p>구축</p>
              </div>
            </div>
            <div className="icon_content">
              <div className="line_box">
                <span className="number_box">03</span>
                <h3>완성도</h3>
                <p>완성도를 설명하다 :</p>
                <p>KOREA No1</p>
                <p>Franchise Program</p>
              </div>
            </div>
          </div>
        </IconBox>

        <CrapeBox />

        <KakaoMap />

        <ContactUsBox>
          <div className="title_box">contact us</div>
          <div className="text_box">
            <div className="left">
              <p>인천광역시 연수구 벚꽃로 130-3 연수푸르지오</p>
              <p>1단지 101동 1003호 </p>
            </div>
            <div className="right">
              <p>
                <strong>tel</strong>
              </p>
              <p>1544 - 7120</p>
            </div>
          </div>
          <div className="text_box">
            <div className="left">
              <p>MSBENTER, 11F,</p>
              <p>130-3, Beotkkot-ro, Yeonsu-gu,</p>
              <p>Incheon, Republic of Korea</p>
            </div>
            <div className="right">
              <p>
                <strong>email</strong>
              </p>
              <p>msb@benter.co.kr</p>
            </div>
          </div>
        </ContactUsBox>

        <Footer />
      </Container>
    </>
  )
}

export default AboutPage
