import React, { useState } from 'react'
import { FormBox } from './styles'

import { db } from '../../firebase'
import { collection, addDoc, setDoc, doc } from 'firebase/firestore'
import { ErrorMessageOpen, SuccessMessageOpen } from '../../hooks/toast'
import axios from 'axios'

const ContactUsForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    content: '',
  })

  const onChangeName = e => {
    setFormData({
      ...formData,
      name: e.target.value,
    })
  }

  const onChangePhone = e => {
    setFormData({
      ...formData,
      phone: e.target.value,
    })
  }

  const onChangeContent = e => {
    setFormData({
      ...formData,
      content: e.target.value,
    })
  }

  const onSubmit = async e => {
    e.preventDefault()

    if (formData.name.trim().length === 0) {
      ErrorMessageOpen('이름을 입력해주세요.')
      return
    }

    if (formData.phone.trim().length === 0) {
      ErrorMessageOpen('연락처를 입력해주세요.')
      return
    }

    const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/
    if (!regPhone.test(formData.phone.trim())) {
      ErrorMessageOpen('올바른 연락처를 입력해주세요.')
      return
    }

    if (formData.content.trim().length === 0) {
      ErrorMessageOpen('내용을 입력해주세요.')
      return
    }

    try {
      const today = new Date()

      let dd = today.getDate()
      let mm = today.getMonth() + 1 //January is 0!
      const yyyy = today.getFullYear()
      let hh = today.getHours()
      let mi = today.getMinutes()

      if (mm < 10) {
        mm = '0' + mm
      }
      if (dd < 10) {
        dd = '0' + dd
      }
      if (hh < 10) {
        hh = '0' + hh
      }
      if (mi < 10) {
        mi = '0' + mi
      }

      const createdAt = `${yyyy}-${mm}-${dd} ${hh}:${mi}`
      const _name = yyyy + mm + dd
      const RandomNumber = Math.random().toString(36).substr(2, 11)
      const id = `notice${RandomNumber}${_name}`
      // console.log('createdAt', createdAt)
      // return
      await axios.post('/api/customer/homepage', {
        customer_name: formData.name,
        customer_phone: formData.phone,
        inquiry_desc: formData.content,
        inquiry_type: 26,
        received_date: createdAt,
      })
      await axios.post('https://socket.relayfms.com/contact')

      SuccessMessageOpen('문의사항 접수가 완료 되었습니다.')
      setFormData({
        name: '',
        phone: '',
        content: '',
      })
    } catch (e) {
      console.error('Error adding document: ', e)
    }
  }

  return (
    <>
      <FormBox>
        <form onSubmit={onSubmit}>
          <div className="top">
            <div className="input_box">
              <label htmlFor="">이름</label>
              <input type="text" value={formData.name} onChange={onChangeName} maxLength="20" />
            </div>
            <div className="input_box">
              <label htmlFor="">연락처</label>
              <input type="text" value={formData.phone} onChange={onChangePhone} maxLength="20" />
            </div>
          </div>
          <div className="center">
            <div className="input_box">
              <label htmlFor="">문의내용</label>
              <textarea id="" value={formData.content} onChange={onChangeContent} maxLength="1000" />
            </div>
          </div>
          <div className="bottom">
            <button type="submit">전송하기</button>
          </div>
        </form>
      </FormBox>
    </>
  )
}

export default ContactUsForm
