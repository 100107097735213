import styled from 'styled-components'

export const CardModalContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 2rem;

  h3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
  }
  .msg {
    line-height: 1.5;
    font-size: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    white-space: pre-wrap;
    color: rgba(0, 0, 0, 0.75);
  }
  .action_box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      margin-left: 0.5rem;
    }
  }
`
