const leftPad = value => {
  if (value >= 10) {
    return value
  }
  return `0${value}`
}

export const calcDate = prevDate => {
  const date = new Date(prevDate)
  const year = date.getFullYear()
  const month = leftPad(date.getMonth() + 1)
  const day = leftPad(date.getDate())
  const hour = leftPad(date.getHours())
  const minute = leftPad(date.getMinutes())
  return `${year}-${month}-${day} ${hour}:${minute}`
}
